<template>
    <div id="ui-options" v-on:mouseenter="onMouseEnter" v-on:mouseleave="onMouseLeave">
      <div ref="selected" class="selected">
        {{selected_option}}
      </div>
      <ul>
        <li v-on:click="onClick" v-for="option in options" :data-index="option.index" v-bind:class="{ visible: option.index !== defaultOption }">
          {{ option.label }}
        </li>
      </ul>
    </div>
</template>

<script>
 
import { mapState } from 'vuex'; 
 
export default {

  name: 'ui-options',

  props: {
    defaultOption: Number,
    options: Array 
  },

  data () {
    return {
      selected_option: ''
    }
  },

  computed: {},

  components: {},

  mounted () {
    this.selected_option = this.getLabelByIndex(this.defaultOption);
  },

  methods: {

    getLabelByIndex(i) {
      return this.options[i].label;
    },

    onClick(e) {
 
      let prev_el = this.$el.querySelector("li:not(.visible)");
      prev_el.classList.add('visible');

      let el = e.target;
      el.classList.remove('visible');

      let index = Number(el.dataset.index);
      this.$emit('change', index);

      this.$refs.selected.innerText = this.getLabelByIndex(index); 
      this.$el.classList.remove('hover');
    },

    onMouseEnter(e) { 
      this.$el.classList.add('hover');
    },

    onMouseLeave(e) {
      this.$el.classList.remove('hover');
    } 
    
  },

  watch: {} 
} 
</script>


<style  lang="sass" scoped>

  #ui-options
    height: 3rem;
    background: #b5287b;
    color: #f6f3ee;
    border-radius: 5px; 
    display: flex;
    min-width: 60%;
    align-items: center;
    justify-content: center;
    text-transform: initial;
    font-size: 1.6rem;
    font-family: 'melbournebold';
    letter-spacing: 1px;
    cursor: pointer;
    position: relative;

    &.hover
      background-color: #601b43; //#b5287b;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;

      ul
        display: flex;
 

    ul
      position: absolute;
      top: 3rem;
      width: 100%;
      display: none;
      flex-direction: column;
      z-index: 999;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
      overflow: hidden;
      max-height: 19.5rem;
      overflow: scroll;

      li
        min-height: 3rem;
        display: none;
        width: 100%;
        justify-content: center;
        align-items: center;
        background-color: #601b43; //#b5287b;
        cursor: pointer;
        transition: background-color 0.2s ease-out;

        &:hover
          background-color: #f6f3ee;
          color: #b5287b;

        &.visible
          display: flex;
 



</style>