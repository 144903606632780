<template>  
  <div id="ui-color-swatches" class="form__field"> 
    <div class="form__input">
      <swatches
        v-model="color"
        show-fallback
        shapes="circles"
        popover-to="left"
        max-height="400"
        v-on:input="onClick"
        row-length="5"
        :colors="colors"
      >
        <div ref="trigger" slot="trigger" class="vue-swatches__trigger">
          default
        </div>
      </swatches>
    </div>
  </div>
</template>

<script>
 
import { mapState } from 'vuex'; 
import Swatches from 'vue-swatches'
import "vue-swatches/dist/vue-swatches.min.css"

export default {

    name: 'ui-color-swatches',

    data () {
      return {
        color: '#222F3D',
        colors: [
          [
            '#1FBC9C',
            '#1CA085',
            '#2ECC70',
            '#27AF60',
            '#3398DB',
            '#2980B9',
            '#A463BF',
            '#8E43AD',
            '#3D556E',
            '#222F3D',
            '#F2C511',
            '#F39C19',
            '#E84B3C',
            '#C0382B',
            '#BDC3C8',
            ''
            ]
        ]
      }
    },

    computed: {},

    components: {
      Swatches
    },

    mounted () {
    },

    methods: {

      onClick(event) {

        let text = this.color === '' ? 'default' : this.color;
        let color = this.color === '' ? '#b5287b' : this.color;

        this.$refs.trigger.style.backgroundColor = color;
        this.$refs.trigger.innerHTML = text;
        this.$emit('change', this.color);
      }
      
    },

    watch: {} 
} 
</script>


<style lang="sass">

  #ui-color-swatches
    height: 4rem
    display: flex
    align-items: flex-end
    justify-content: space-between
    margin-top: -1rem

    .swatch
      width: 1.6rem
      height: 1.6rem
      border-radius: 50%
      cursor: pointer
      transition: transform 0.2s ease-out

      &:hover
          transform: scale(1.3, 1.3) 

    .vue-swatches__trigger
      height: 3rem !important
      width: 84px !important
      color: #f6f3ee
      border-radius: 5px !important
      display: flex
      width: 60%
      align-items: center
      justify-content: center
      text-transform: lowercase
      font-size: 1.5rem
      font-family: 'melbournebold'
      letter-spacing: 1px
      cursor: pointer
      background-color: #b5287b
      transition: background-color 0.2s ease-out
     



</style>