<template>
    <transition  :leave="leave" :enter="enter" :css="false" > 
      <div id="home">
        <a class="github" href="https://github.com/camoconnell/lazy-line-painter"></a>
        <intro />
        <getting-started />
        <blurb />
        <composer />
        <how-it-works /> 

       <!--  <Docs /> -->  
        <Footer /> 
      </div>
    </transition>
</template>

<script>

import { mapState, mapMutations } from 'vuex'

import Raf from '@/helpers/Raf' 
import MathUtils from '@/helpers/MathUtils' 
import ResizeService from '@/services/ResizeService'

import Intro from '@/components/intro' 
import GettingStarted from '@/components/GettingStarted' 
import Blurb from '@/components/Blurb' 
import HowItWorks from '@/components/HowItWorks'  
import Composer from '@/components/composer'   
import Docs from '@/components/Docs'  
import Footer from '@/components/Footer' 


export default {

  data () {
    return {}
  },

  mounted () { 
    this.setup();
    document.dispatchEvent(new Event("render-event"))
  },

  components: {
    Intro,
    GettingStarted,
    Blurb,
    HowItWorks,
    Composer,
    Docs,
    Footer
  },

  computed: { 
    ...mapState('browser', [
      'isMobile'
    ]) 
  },

  methods:{

    setup () {}, 

    onNext () {},

    enter: function (el, done) {
        done()
    },

    leave: function (el, done) {
        done()
    },

    beforeDestroy: function () { }
  } 
} 
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass">

    #home
      position: absolute;
      height: 100%;
      width: 100%; 

      .github
        height: 180px;
        width: 180px;
        top: 0;
        right: 0;
        fill: black;
        background-size: contain;
        position: fixed;
        background-repeat: no-repeat;
        background-image: url(~assets/img/github-octocat.png);
        z-index: 99;

        @media only screen and (max-width : 400px) 
          height: 100px;
          width: 100px;



</style>
