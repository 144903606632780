<template>
   <svg viewBox="0 0 290 420" xmlns="http://www.w3.org/2000/svg" id="chair" class="lazy-line-painter"  data-llp-composed="true">  

    <path d="M56.25,51  c2.75-0.75,14.25,3,20,0.75" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"  class="chair-0" data-llp-id="chair-0" data-llp-duration="4000" data-llp-delay="0"></path>

    <path d="M89.75,28.5  c0,0,5,2.75,7.5,1.5c7.5-2,8.25-15.75,12.25-15.75" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"  class="chair-1" data-llp-id="chair-1" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M86.5,21.75c0,0,4.25,4,11.5,0c2.5-1-3.25,10-13.5,3.5c-3.25-2,0-6.75,1-10.5s1-8.25,1-8.25s3.25,8.75,16.5,6.75" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"  class="chair-2" data-llp-id="chair-2" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M99.75,10.5  c0,0-12.5-2.25-15.5-5.5s-15-1.25-19,1s-14.75,13.25-18,13.75" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="chair-3" data-llp-id="chair-3" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M83.75,8  c0,0-2,1.25-2.25,3.5s2.75,28-9,29c-16.5,0.75-14-24-26.25-21.25" stroke="#3d3d3d" fill="none" data-llp-id="chair-4" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M60.5,35.75  c-7.25-0.25-10.75-15-16.75-15.75" stroke="#3d3d3d" fill="none" data-llp-id="chair-5" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M56.25,34  c0,0-6.25-0.5-9.75-5.75S41,21.5,38.75,21.5" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-llp-id="chair-6" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M52,33.5  c0,0-5.25,9.75-12,1.5s-3-11-3-11c3-0.25,6,8.75,9.25,10.5s7.25-1.25,7.25-1.25" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-llp-id="chair-7" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M55.75,61c0,0,2-1.75,6-0.75s8.25,4.25,11.5,3.5c-1.5,0.75-9.5,2-15,0C64.5,66,71,65,71,65s-1.25,3.75-4.75,3.5s7.5-1,7.5-4.5  c-1,2.75-1.5,8,0,9c-1,1-4-0.5-4,3.5c0,0,3.75,6,7.5,5.25S81,78.5,83.5,78s3.75-3.5,2.5-4.75c1.25,0.75,2.5,4.25,5,5" stroke="#3d3d3d" fill="none"  data-llp-id="chair-8" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M88.25,68.75c0,0-6.5-1.75-7.75-5.5c3.25,0.75,3.5,1,5.75-0.25S88,61.75,93,61.5c-8,1.75-8.75-1.75-15.75,0" stroke="#3d3d3d" fill="none"  data-llp-id="chair-9" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M74.333,27.667  c-2.667,1.667-6.667,3.333-12,3.333" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 13, 13; stroke-dashoffset: 0; display: block;" class="chair-10" data-llp-id="chair-10" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M100.333,30  c2,1.333,7.333,3,10,1.333" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 11, 11; stroke-dashoffset: 0; display: block;" class="chair-11" data-llp-id="chair-11" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M107.333,15.667  c0,0,2,2.333,6,4.667s7.667,4.333,7.667,8s-5,9-5.333,10" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 31, 31; stroke-dashoffset: 0; display: block;" class="chair-12" data-llp-id="chair-12" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M66.333,83.667c0,0,2,6,7,6  s6.333-5,6.333-5s1.334,6,6.667,6S97.333,85,95.667,82S91,80,91,82" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 50, 50; stroke-dashoffset: 0; display: block;" class="chair-13" data-llp-id="chair-13" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M80.667,82  c0,0,2.333,0,5.333,0.667s4.333,4,8,2.667" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 15, 15; stroke-dashoffset: 0; display: block;" class="chair-14" data-llp-id="chair-14" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M76.667,89.333  c0,0,0.333,4.333,8,5.333s18-4.333,22-11.333S115,73,116.333,61.667s1.333-23,1.333-23" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 84, 84; stroke-dashoffset: 0; display: block;" class="chair-15" data-llp-id="chair-15" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M143.333,52.667  c0,0-6-8-14-11S117,47.333,117,47.333s1.999,25.334-1.001,37.001s-10.667,38-10.667,38" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 110, 110; stroke-dashoffset: 0; display: block;" class="chair-16" data-llp-id="chair-16" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M134.666,41.667  c0,0,29.667-5.333,34-3.333s9.333,8.333,12,8.333s23,11.334,30,15.334s21,17,30.333,20s16.333,11,21.333,14.667  c0,0,12.666,5.333,12.999,12.667s-4,21-5.333,28.667s-12.667,51.667-20.667,58.667c-2,2-6.333,3-6.333,3" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 264, 264; stroke-dashoffset: 0; display: block;" class="chair-17" data-llp-id="chair-17" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M243.332,193.334  c0,0-0.333,8-3.667,11.333c-4-3-29-15-29-15s-9.333,6.333-13.666,11s-4.333,14-8.333,17c0,2.667,3.333,3,8.667-2.333" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 93, 93; stroke-dashoffset: 0; display: block;" class="chair-18" data-llp-id="chair-18" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M164.999,52.667  c0,0,3.333,14.333,7.667,22s3.667,10.667,5.333,12s11,14.334,14,16.667s17.666,11.667,21.333,13c1.667,3,6.333,10.333,10.333,10.333  c2.333-2.333,1.667-7,5.333-9.333c0,0,21.333-16,24.667-15.333" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 139, 139; stroke-dashoffset: 0; display: block;" class="chair-19" data-llp-id="chair-19" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M236.665,107.667  c0,0,11.333-9.333,15.333-9.333s7.333,1.333,7.333,1.333" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 26, 26; stroke-dashoffset: 0; display: block;" class="chair-20" data-llp-id="chair-20" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M247.665,109.667  c0,0,5,0,8-2s7.667-1,7.667-1" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 17, 17; stroke-dashoffset: 0; display: block;" class="chair-21" data-llp-id="chair-21" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M225.665,124.334  c0,0,6.333-2,11.667-5.333s10.667-5.667,10.667-5.667" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 25, 25; stroke-dashoffset: 0; display: block;" class="chair-22" data-llp-id="chair-22" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M230.665,123.667  c0,0-1.667,12-1.667,17s-6.333,17.334-9,24.667s-5.333,15.333-7.333,18.667s-2,3.333-2,3.333s6.667-2.667,12.667-1.333  s23,9.333,23,9.333" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 106, 106; stroke-dashoffset: 0; display: block;" class="chair-23" data-llp-id="chair-23" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M125.999,86.001  c0,0,8.333,1.667,9.667,0s-3.667-13-4-16.333s-4.667-18-8.667-20.667" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 50, 50; stroke-dashoffset: 0; display: block;" class="chair-24" data-llp-id="chair-24" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M132.666,56.667  c0,0,4.667,24.333,5.667,26s4.333,8,4.333,8" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 36, 36; stroke-dashoffset: 0; display: block;" class="chair-25" data-llp-id="chair-25" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M54.333,69.667  c0,0,0.333,9.333,3,12.667s6.333,8.333,6.333,8.333" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 24, 24; stroke-dashoffset: 0; display: block;" class="chair-26" data-llp-id="chair-26" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M53.667,79.667  c0,0,2,7.667,5.333,10s4.667,4.333,8,9s14,9.333,15.333,15" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 46, 46; stroke-dashoffset: 0; display: block;" class="chair-27" data-llp-id="chair-27" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M96.667,93.333  c0,0,4.333,10.333,4.333,16s-0.667,12-0.667,12" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 29, 29; stroke-dashoffset: 0; display: block;" class="chair-28" data-llp-id="chair-28" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M80.667,129.667  c0,0,3.333-5,3.333-9.333s-4.333-7-4.333-7s-2.866,4.439-5,7" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 28, 28; stroke-dashoffset: 0; display: block;" class="chair-29" data-llp-id="chair-29" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M82.667,116  c0,0,10.666,0.334,13.333,2.667s12.333,5,11,7.333s-3.666,9.667-5.333,11.667s-6.667,5.666-10,3.333s-19-17.333-18-19  s-13,15-15.333,20.333S54,157,52.667,160.333" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 123, 123; stroke-dashoffset: 0; display: block;" class="chair-30" data-llp-id="chair-30" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M44,157  c0,0,4.333-6.333,8-8.667s9.667-9.333,10-18s-9-18-10-24.333" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 60, 60; stroke-dashoffset: 0; display: block;" class="chair-31" data-llp-id="chair-31" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M52.667,92.667  c0,0,0.667,10,3,13.667s5.667,15.667,5.667,19" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 35, 35; stroke-dashoffset: 0; display: block;" class="chair-32" data-llp-id="chair-32" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M47,87.333  c0,0,2-9.333,3.333-12S53,71.667,53,66.667c-1.333,4-7.333,10-13,12.667s-7,10-7.667,11.333s2.333,7.333,3,8.667  c-1.667-0.333-4,0.333-4,6.333s1.333,30.666,1.333,35.333s-3,35-2.667,39.333s11.334,14,14.667,17s7,5.333,13.667,4.333  S76,195.999,81.667,183.333s8.666-11.667,10.333-24" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 243, 243; stroke-dashoffset: 0; display: block;" class="chair-33" data-llp-id="chair-33" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M256.666,171.667  c0,0-7.333-8.333-16.333-9.333s-16-0.333-16-0.333" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 36, 36; stroke-dashoffset: 0; display: block;" class="chair-34" data-llp-id="chair-34" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M197.666,204.667c0,0-1.667,10.333,2.333,13s6-0.333,6-0.333s0.667,4,5,4s6.333-1.667,6.333-1.667s-2,2.667,2,3s10,2,13.333-4  s2-12,3.333-15" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 70, 70; stroke-dashoffset: 0; display: block;" class="chair-35" data-llp-id="chair-35" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M118.333,77.667  c0,0,5.333,7,5.333,13c4,0.667,10.334,2.667,16.667,1.333" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 32, 32; stroke-dashoffset: 0; display: block;" class="chair-36" data-llp-id="chair-36" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M128,88.333  c0,0,9,3,17.333,1.667c4.667-1.333,4,3.333,1.333,6c-12,14.333-38,36.333-38.667,41.333c0,0-0.667,1.333,0.333,4.333  s12.667,20,12.667,27.667s0.667,8.666,3.333,13.333s3.333,15.667,3.667,19.667s10.667,17.666,11.667,22.333s2.667,7,2.667,7  s0.667,3.667,2.667,3s6.667-2.334,8,2.333s-1,13.667,4.667,15.333s12.667-0.333,17-5.333s8.667-22,14-26" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 266, 266; stroke-dashoffset: 0; display: block;" class="chair-37" data-llp-id="chair-37" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M215,177.333  c0,0,0.667-10-1.667-14.333s-5.667-11.667-9.667-12c-1.667-5-2.667-10.333-6-13.667c0.333-9.667-2.667-25-4.667-33" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 79, 79; stroke-dashoffset: 0; display: block;" class="chair-38" data-llp-id="chair-38" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M176.666,53.333  c0,0,1,12,1.667,16.333s-1.333,14,1.667,19.333" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 37, 37; stroke-dashoffset: 0; display: block;" class="chair-39" data-llp-id="chair-39" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M62.334,200.665  c0,0,13.667-5.333,24,2.667s18.333,23.666,24,27.333s15.333,11.333,30,5" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 94, 94; stroke-dashoffset: 0; display: block;" class="chair-40" data-llp-id="chair-40" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M117,212.665  c0,0-9.667-15.333-20-21.333s-15.667-6-15.667-6s15,2,23.333,10.333s16,14,21,12.333" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 98, 98; stroke-dashoffset: 0; display: block;" class="chair-41" data-llp-id="chair-41" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M151.334,223.999c0,0,9-9.667,22.333-14.667S203,186.665,205,181.665c-8.667,12.333-16.333,20.667-24.333,27.333  s-15.667,19.667-17,26" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 138, 138; stroke-dashoffset: 0; display: block;" class="chair-42" data-llp-id="chair-42" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M177,198.332c0,0-20.333,8.667-29.333,8.667s-17.667-4-17.667-4" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 49, 49; stroke-dashoffset: 0; display: block;" class="chair-43" data-llp-id="chair-43" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M129.667,139.333c0,0,11.333,1.667,19.333-1.667s20.333-10.333,20.333-10.333" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 43, 43; stroke-dashoffset: 0; display: block;" class="chair-44" data-llp-id="chair-44" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M131,152.999c0,0,10-7.333,20.667-6.333S165,143.999,165,143.999" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 37, 37; stroke-dashoffset: 0; display: block;" class="chair-45" data-llp-id="chair-45" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M130,170.332c0,0,14.667-1.667,24-3.667s16.667-1.667,16.667-1.667" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 42, 42; stroke-dashoffset: 0; display: block;" class="chair-46" data-llp-id="chair-46" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M155.667,190.332c0,0-1-5.333,2.333-5.667s21-9,22.667-15.667" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 36, 36; stroke-dashoffset: 0; display: block;" class="chair-47" data-llp-id="chair-47" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M98.833,158c0,0,4.833,3,10.167,3s4,0,4,0" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 16, 16; stroke-dashoffset: 0; display: block;" class="chair-48" data-llp-id="chair-48" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M99.334,174.332c0,0,5.667,6,10.667,8.333s7.667,0.333,7.667,0.333" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 22, 22; stroke-dashoffset: 0; display: block;" class="chair-49" data-llp-id="chair-49" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M47,202.5c0,0-9.5,9.5-10.5,16.5s0.5,9.5,0.5,13s-0.5,12-1,17.5s5.5,38.5,6,42s5,26.5,5,35.5s2,38,4.5,38s6.5-3,12-2.5s22,2,26.5-2  s3.5-12,5-22.5s-3.5-13.5-3.5-13.5s3-7.5-0.5-12.5s-10-14-10-14" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 276, 276; stroke-dashoffset: 0; display: block;" class="chair-50" data-llp-id="chair-50" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M74.5,293c0,0-8-13-10-19.5s-8-12-8-12" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 37, 37; stroke-dashoffset: 0; display: block;" class="chair-51" data-llp-id="chair-51" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M82.25,288.75c0,0,2.375,2.875,3.875,8.875S88,309,88,309v-32c0,0-2.5-17.5-2.5-22s0.5-5-3.5-9.5c0,0-12.5-11-12.5-16.5" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 108, 108; stroke-dashoffset: 0; display: block;" class="chair-52" data-llp-id="chair-52" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M77,225.5c0,0,0,3,3,7.5s5.5,15,5.5,15s2.5-4.5,2.5-11.5s3,10.5,0,13c6.5-1.5,12-6,18.5-6.5s20.5-3,28.5-2.5s13,7.5,18,8" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 122, 122; stroke-dashoffset: 0; display: block;" class="chair-53" data-llp-id="chair-53" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M171,253.5c0,0-1,6.5,6,1.5s17-18,17-18l-17,22.5c0,0,7.5,4,16,0s-16,3.5-16,3.5s-1.5,8.5-1.5,11.5s-1,14-5.5,19c1,2.5,8,7.5,8,7.5  s-8.5-3.5-8.5-1.5s4,5.5,4,5.5s-5-2.5-6.5,2.5s-6.5,26-10,37s-5.5,11.5-4,13.5s31.5,18.5,36,16s0-5,0-5s18-40,21.5-52.5  c-3,2-7-2.5-7-2.5s5,4,11-4.5s15.5-28.5,19-44.5s7.5-11,6-22.5s-5-26.5-5-26.5" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 443, 443; stroke-dashoffset: 0; display: block;" class="chair-54" data-llp-id="chair-54" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M56.5,365c0,0-7,8-9.5,12.5s-6,10.5-8.5,13s-8.5,9-8.5,14s10,12,20,8s20.5-7.5,22.5-21C79,389,90.5,386,92,379s-2-18.5-2-18.5" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 148, 148; stroke-dashoffset: 0; display: block;" class="chair-55" data-llp-id="chair-55" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M51.5,378c0,0,6.5,7,13.5,3.5s14-15,14-15" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 36, 36; stroke-dashoffset: 0; display: block;" class="chair-56" data-llp-id="chair-56" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M58.5,375c0,0,4-11,10-11" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 16, 16; stroke-dashoffset: 0; display: block;" class="chair-57" data-llp-id="chair-57" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M44.5,385c0,0,7.5,1,9,3.5" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 10, 10; stroke-dashoffset: 0; display: block;" class="chair-58" data-llp-id="chair-58" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M153,358c0,0-4,9-1.5,16s9,10,14,19s5.5,18.5,22,16.5s15.5-5.5,15-11.5s-16-23.5-16-23.5" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 122, 122; stroke-dashoffset: 0; display: block;" class="chair-59" data-llp-id="chair-59" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M163.5,368.5c0,0,7,16.5,16.5,15" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 24, 24; stroke-dashoffset: 0; display: block;" class="chair-60" data-llp-id="chair-60" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M184.5,395c0,0,7-5,13-3.5" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 14, 14; stroke-dashoffset: 0; display: block;" class="chair-61" data-llp-id="chair-61" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M88.5,150  c-1.657,0-3-0.343-3-2s1.343-3,3-3s3,1.343,3,3s-1.343,3-3,3" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 19, 19; stroke-dashoffset: 0; display: block;" class="chair-62" data-llp-id="chair-62" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M83.5,160  c-1.657,0-3-1.343-3-3s1.343-2,3-2s3,0.343,3,2S85.157,160,83.5,160" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 18, 18; stroke-dashoffset: 0; display: block;" class="chair-63" data-llp-id="chair-63" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M120.5,159  c-1.657,0-3-1.343-3-3s1.343-2,3-2s3,0.343,3,2S122.157,159,120.5,159" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 18, 18; stroke-dashoffset: 0; display: block;" class="chair-64" data-llp-id="chair-64" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M147.5,152  c-1.657,0-3-1.343-3-3s1.343-2,3-2s3,0.343,3,2S149.157,152,147.5,152" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 18, 18; stroke-dashoffset: 0; display: block;" class="chair-65" data-llp-id="chair-65" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M150.432,171.095  c0.931-1.371,2.796-1.727,4.167-0.796c1.371,0.931,0.9,2.235-0.031,3.605c-0.931,1.371-1.969,2.289-3.34,1.358  S149.501,172.466,150.432,171.095" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 18, 18; stroke-dashoffset: 0; display: block;" class="chair-66" data-llp-id="chair-66" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M152.432,198.095  c0.931-1.371,2.796-1.727,4.167-0.796c1.371,0.931,1.9,2.235,0.969,3.605c-0.931,1.371-2.969,2.289-4.34,1.358  S151.501,199.466,152.432,198.095" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 19, 19; stroke-dashoffset: 0; display: block;" class="chair-67" data-llp-id="chair-67" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M125.432,177.095  c0.931-1.371,2.796-1.727,4.167-0.796c1.371,0.931,0.9,2.235-0.031,3.605c-0.931,1.371-1.969,2.289-3.34,1.358  S124.501,178.466,125.432,177.095" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 18, 18; stroke-dashoffset: 0; display: block;" class="chair-68" data-llp-id="chair-68" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M133.432,124.095  c0.931-1.371,2.796-1.727,4.167-0.796c1.371,0.931,1.9,3.235,0.969,4.605c-0.931,1.371-2.969,1.289-4.34,0.358  S132.501,125.466,133.432,124.095" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 20, 20; stroke-dashoffset: 0; display: block;" class="chair-69" data-llp-id="chair-69" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M129.432,199.095  c0.931-1.371,2.796-1.727,4.167-0.796c1.371,0.931,1.9,2.235,0.969,3.605c-0.931,1.371-2.969,2.289-4.34,1.358  S128.501,200.466,129.432,199.095" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 19, 19; stroke-dashoffset: 0; display: block;" class="chair-70" data-llp-id="chair-70" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M113.302,138.232  c-1.148,1.194-3.047,1.232-4.242,0.083c-1.194-1.148-0.511-2.354,0.637-3.548c1.148-1.194,2.327-1.925,3.521-0.777  C114.413,135.139,114.45,137.039,113.302,138.232" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 18, 18; stroke-dashoffset: 0; display: block;" class="chair-71" data-llp-id="chair-71" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M60.333,26.5  c1.833,0,6.5-0.5,7.667-2.667" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 9, 9; stroke-dashoffset: 0; display: block;" class="chair-72" data-llp-id="chair-72" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M65.5,21.667  c0,0-4.167,1.167-8.833-0.667c-0.5-1.667,4.333-1,7.833-3.667c0,0,2.833-0.667,3.5-2" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 23, 23; stroke-dashoffset: 0; display: block;" class="chair-73" data-llp-id="chair-73" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M72,12.5  c0,0,3.5-0.833,4.833-1.833" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 6, 6; stroke-dashoffset: 0; display: block;" class="chair-74" data-llp-id="chair-74" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M64,350.667  C75,349.334,79.667,359,84,359" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 23, 23; stroke-dashoffset: 0; display: block;" class="chair-75" data-llp-id="chair-75" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M156.667,355.334c0,0,5.333,1,9.333-2s10.333,0,10.333,0" stroke="#3d3d3d" fill="none" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 21, 21; stroke-dashoffset: 0; display: block;" class="chair-76" data-llp-id="chair-76" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M228.333,143c0,0-27.333-10-29.333-12" fill="none" data-llp-stroke-color="#b5287b" data-llp-id="chair-77" data-llp-duration="4000" data-llp-delay="0" ></path>  

    <path d="M257.75,358.5c0,0-1.5,5.75-6.75,5.5s-6.5-6.75-6.5-6.75s2.25,1,5.25,1.25s8.75,0.25,8.25-4s-1.75-3.5-1.75-3.5   s-8.25,3.25-12.75-6.25c4.25,0.75,4.75,1.5,8.5,1.5s8.25,0.5,8.5-2.5s-0.25-5.5-3-6.25c-2.25,3-7.75,1.5-9.75-1   c0,0-3.75,1.75-3.75,6" fill="none" data-llp-stroke-color="#b5287b" data-llp-id="chair-78" data-llp-duration="4000" data-llp-delay="0" ></path>  

    <path d="M247.75,336.5c0,0-2-18-1.75-22.5s-1-6.25,0.75-8s1.75-1.75,1.75-1.75s2.5,2,6.5,2s6-2.75,6-2.75s4.75,3,2.5,5s-4.75,3.25-8.5,3.25   s-7.25-2.5-7.25-2.5" fill="none"  data-llp-stroke-color="#b5287b" data-llp-id="chair-79" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M257.5,337.5c0,0,1.25-7.25,2.25-13s3.25-11.25,3.75-16" stroke="#3d3d3d" fill="none" data-llp-stroke-color="#b5287b" data-llp-id="chair-80" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M226.5,295.75c0,0,9.75-2,14-2"  fill="none" data-llp-stroke-color="#b5287b" data-llp-id="chair-81" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M123.5,374c0,0,1,6,6.5,6   s5.5-6,5.5-6"  fill="none" data-llp-stroke-color="#b5287b" data-llp-id="chair-82" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M126,366c0,0-5,6,0,6   s6,0.5,7.5,0.5s5.5-0.5,3-5.5" stroke="#3d3d3d" fill="none" data-llp-stroke-color="#b5287b" data-llp-id="chair-83" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M122,357.5c0,0-1,9,7.5,9   S140,358,140,358s-5,2-10.5,2c0,0-12,2-6-7.5c5,4,12.5,2.5,14.5-0.5c2,3,2,3,2,3" fill="none" data-llp-stroke-color="#b5287b" data-llp-id="chair-84" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M123.5,352.5l-2.5-32   c0,0,0-3.5,4-4" fill="none" data-llp-stroke-color="#b5287b" data-llp-id="chair-85" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M126.5,324   c0,0,11,2.5,15.5-3.5c-2-2-3.5-4-3.5-4" fill="none" data-llp-stroke-color="#b5287b" data-llp-id="chair-86" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M98.5,285.5c0,0,2-6.5,6-4   s6,5,6,5" fill="none" data-llp-stroke-color="#b5287b" data-llp-id="chair-87" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M113.5,297.5   c0,0,1.5-4.5,4.5-3c1-3-3.5-8-6.5-6s-6.5,9.5-3,11.5s6,1.5,6,1.5l6.5,3.5" fill="none" data-llp-stroke-color="#b5287b" data-llp-id="chair-88" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M125.5,294.75   c0,0-1,4.25-3.5,8.25c0,4,0,12,0,12s6,3.5,10,3.5s8.5-3.5,8.5-3.5l0.5-11.5l-11,1.5" fill="none" data-llp-stroke-color="#b5287b" data-llp-id="chair-89" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M124,297.5   c0,0,4.5,4.5,13,1.5c3.5,1.5,4,4.5,4,4.5"  fill="none" data-llp-stroke-color="#b5287b" data-llp-id="chair-90" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M137,299c0,0,4-7,7-7" fill="none" data-llp-stroke-color="#b5287b" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M144.5,289   c0,0,18.5,0,28-2.5"  fill="none" data-llp-stroke-color="#b5287b" data-llp-id="chair-92" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M143,303.5   c0,0,7-1.5,14.5-1.5" fill="none" data-llp-stroke-color="#b5287b" data-llp-id="chair-93" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M141.5,308.5   c0,0,6.5-3,16-2s10-1,10-1" fill="none" data-llp-stroke-color="#b5287b" data-llp-id="chair-94" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M243,296.5   c-4,0.5-19,3-19,3" fill="none" data-llp-stroke-color="#b5287b" data-llp-id="chair-95" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M262.375,302.75l0.688-10.375c0,0-6.031,1.438-8.031,1.438s-7.516-2.531-7.516-2.531s-2.508-4.516,4.242-8.266   c2.5,2.5,4.246,2.992,7.496,0.492s6.373-8.004,6.373-8.004L252,277.25V252c0,0-0.5-4.5,1.25-4.5s14.25-1.25,14.25-1.25   s-0.25-5.5-4.75-7.25" fill="none" data-llp-stroke-color="#b5287b"data-llp-id="chair-96" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M260.75,283c0,0,6.5,5.5,3,9" fill="none" data-llp-stroke-color="#b5287b" data-llp-id="chair-97" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M247.5,293.75l-17.5-12.5   c0,0,0.5-2.5,5.5-3.25s14-1,14-1" fill="none" data-llp-stroke-color="#b5287b" data-llp-id="chair-98" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M267.5,246.25   c0,0-0.25,27-1.25,28.5" fill="none" data-llp-stroke-color="#b5287b"  data-llp-id="chair-99" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M248.25,277.75   c0,0,1.5,5,4.75,6" fill="none" data-llp-stroke-color="#b5287b" data-llp-id="chair-100" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M248.25,223.75c0,0,3.25,9.5,4,12.5s7,5,9.25,1s4.75-9.75,5.25-13.5c-1,0.75-5.5,0.75-10.25,0.75c0,0-8.5-0.5-7.75-6   c4.5,2,5,3.25,12.25,2.5s5-1,5-1s3.25-4.25-1.25-3.75S254.25,217,251,216" fill="none" data-llp-stroke-color="#b5287b" data-llp-id="chair-101" data-llp-duration="4000" data-llp-delay="0"></path>  
s
    <path d="M198,133.75c0,0,20.75,11,29,11.75"  fill="none" data-llp-stroke-color="#b5287b" data-llp-id="chair-102" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M201.5,143.25c0,0,4.25,1,5-1.75s0.75-2.75,0.75-2.75"  fill="none" data-llp-stroke-color="#b5287b"data-llp-id="chair-103" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M217.5,144c0,0-1,6,7,6.5" fill="none" data-llp-stroke-color="#b5287b"  data-llp-id="chair-104" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M220,150c0,0-0.753,9.918-0.001,15.334" fill="none" data-llp-stroke-color="#b5287b" data-llp-id="chair-105" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M264.5,162.5c0,0,1.25,18.25,1.25,28s-1,13-1,13s-8.25,3.25-11.5,0.75S253.5,192,253.5,192" fill="none" data-llp-stroke-color="#b5287b" data-llp-id="chair-106" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M249.75,203.25c0,0-3.5,2-2.25,5.5s5.75,6,5.75,6s-0.25-4.25,1.5-4.25s2,3.25,4,3.25s3.5-3,3.5-3s-1,3.75,1.25,3.75s5.5-3,5-7.25   s-3.25-5-3.25-5" fill="none" data-llp-stroke-color="#b5287b" data-llp-id="chair-107" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M246.75,254.75c0,0,1-6-1.75-8s-5.5-4.25-5.5-4.25l-0.25-6.75c0,0,6.25,8.5,10.75,9.5c0-3,1.25-6.25,3-7.75" fill="none" data-llp-stroke-color="#b5287b" data-llp-id="chair-108" data-llp-duration="4000" data-llp-delay="0"></path>  

    <path d="M106,294l-7.5-5.5c0,0-7.5,1-5.5-6.5c0,0,0.5-5.5,6-6s5.5,5.5,5.5,5.5" fill="none" data-llp-stroke-color="#b5287b" data-llp-id="chair-109" data-llp-duration="4000" data-llp-delay="0"></path>
  </svg>
</template>

<script> 

import { mapState } from 'vuex';
import ResizeService from '@/services/ResizeService'; 
import LazyLinePainter from 'lazy-line-painter';

export default {

    name: 'svg-chair',

    data () {
      return {
        lazyline: null
      }
    },

    computed: {},

    components: {},

    mounted () {
      this.setup();
    },

    beforeDestroy () { 
      this.lazyline = null;
      console.log('destroy')
    },

    methods:{

      setup() {

        this.lazyline = new LazyLinePainter(this.$el, {  
          strokeWidth: 2, 
          strokeCap :'round',
          strokeColor: '#f7f3ed', 
          ease: 'easeInOutQuad'
        });
        
        this.lazyline.paint();
      }
    
    },


    watch: {} 
} 
</script>


<style  lang="sass">
 
 
</style>
