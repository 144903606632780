<template> 
  <div id="ui-btn" 
    v-on:click="onClick"
    v-bind:class="{ active: value }">{{value}}</div>
</template>

<script>
 
import { mapState } from 'vuex'; 

export default {

    name: 'ui-btn',

    props: {
      initialValue: Boolean 
    },

    data () {
      return {
        value: false
      }
    },

    computed: {},

    components: {},

    mounted () {
      this.value = this.initialValue; 
    },

    methods: {

      onClick() {
        this.value = !this.value;
        this.$emit('change', this.value);
      }
      
    },

    watch: {} 
} 
</script>


<style  lang="sass" scoped>

  #ui-btn
    height: 3rem;
    background-color: #b5287b;
    color: #f6f3ee;
    border-radius: 5px; 
    display: flex;
    width: 60%;
    align-items: center;
    justify-content: center;
    text-transform: initial;
    font-size: 1.6rem;
    font-family: 'melbournebold';
    letter-spacing: 1px;
    cursor: pointer;
    transition: background-color 0.2s ease-out;

    &:hover
      background-color: #601b43;

    &.active
      background-color: #f7f3ed;
      color: #b5287b;

      &:hover
        background-color: #e9e1d9;

</style>