import { render, staticRenderFns } from "./GettingStarted.vue?vue&type=template&id=028c9354&scoped=true&"
import script from "./GettingStarted.vue?vue&type=script&lang=js&"
export * from "./GettingStarted.vue?vue&type=script&lang=js&"
import style0 from "./GettingStarted.vue?vue&type=style&index=0&id=028c9354&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "028c9354",
  null
  
)

component.options.__file = "GettingStarted.vue"
export default component.exports