<template>
  <input 
    id="ui-number-input"
    type="number" 
    min="-1" 
    max="999" 
    :value="value"
    v-on:input="onInputChange"
    >
</template>

<script>
 
import { mapState } from 'vuex'; 

export default {

    name: 'ui-number-input',

    props: {
      initialValue: Number 
    },

    data () {
      return {
        value: false
      }
    },

    computed: {},

    components: {},

    mounted () {
      this.value = this.initialValue; 
    },

    methods: {

      onInputChange() {
        this.$emit('change', this.$el.value);
      }
      
    },

    watch: {} 
} 
</script>


<style lang="sass" scoped>

  #ui-number-input
    height: 3rem
    background-color: #b5287b
    color: #f6f3ee
    border-radius: 5px
    display: flex
    width: 60%
    align-items: center
    justify-content: center
    text-transform: initial
    font-size: 1.6rem
    font-family: 'melbournebold'
    letter-spacing: 1px
    cursor: pointer
    transition: background-color 0.2s ease-out
    text-align: center
    padding-left: 1rem

    &:hover
      background-color: #601b43

    &:focus
      background-color: #f7f3ed
      color: #b5287b


</style>