<template>

  <div id="timeline-bars"> 
    <timeline-bar 
      v-for="path in timeline.paths" 
      :path="path"
      :id="path.dataset.llpId"
      :key="path.dataset.llpId"
      :height="getHeight"
      />
  </div>

</template>

<script>

import Raf from '@/helpers/Raf'
import { clamp } from '@/helpers/MathUtils'
import { mapState } from 'vuex';
import ResizeService from '@/services/ResizeService';
import TimelineBar from '@/components/composer/timeline/bar';

export default {

    name: 'timeline-bars',

    data () {
      return {
        value: 0,
        height: 0
      }
    },

    computed: {
      ...mapState('composer', [
        'timeline'
      ]),
      getHeight(){
        
        let len = this.timeline.paths.length;
        let height;

        if( len < 5){
          height = '1.5rem'
        } else {
          height = clamp(100 / len, 2, 100) + 'px';
        }
        return height
      }
    },

    components: {
      TimelineBar
    },

    mounted () { 
    },

    methods:{

      onClick() {} 
    
    },

    watch: {} 
} 
</script>


<style scoped lang="sass">

  #timeline-bars
    width: 100%
    max-height: 200px
    overflow: scroll
    position: relative

</style> 