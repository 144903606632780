    import EventEmitter from './EventEmitter'
import MathUtils from '@/helpers/MathUtils'

const Events = {
    START: 'touchstart',
    MOVE: 'touchmove',
    END: 'touchend'
}

class Touch {

    constructor(el) {
        Object.assign(this, EventEmitter)
        this.isMobile = /(iPhone|iPad|iPod|Android)/i.test(navigator.userAgent);
        this.isDesktop = !this.isMobile;
        this.el = el || window;
        this.x = null
        this.y = null
        this.distance = {
            x:0,
            y:0
        }
        this.delta = {
            x:0,
            y:0
        }
        this.direction = {
            up: false,
            down: false,
            left: false,
            right: false
        };
        this.axis = {
            x: false,
            y: false
        };
        this.touching = false
        this.listening = false
        this.listen()
    }

    _onTouchStart = (evt) => {
        this.el.addEventListener('touchend', this._onTouchEnd)
        window.addEventListener('mouseup', this._onTouchEnd)
        window.addEventListener('touchmove', this._onTouchMove)
        window.addEventListener('mousemove', this._onTouchMove)
        let ev = evt.touches && evt.touches[0] ? evt.touches[0] : evt
        this.x = ev.pageX /*/ window.innerWidth - 0.5*/
        this.y = ev.pageY /*/ window.innerHeight - 0.5*/
        this.touching = true
        this.emit(Events.START, {
            x: this.x,
            y: this.y
        })
    }

    _onTouchMove = (evt) => {
        let ev = evt.touches && evt.touches[0] ? evt.touches[0] : evt

        this.delta.x = (this.x - ev.pageX);
        this.delta.y = (this.y - ev.pageY); 
        this.distance.x += this.delta.x;
        this.distance.y += this.delta.y;
        this.x = ev.pageX;
        this.y = ev.pageY;

        this.setAxis();
        this.setDirection();

        this.emit(Events.MOVE, {
            x: this.x,
            y: this.y 
        })
    }

    _onTouchEnd = evt => {
        //this.el.removeEventListener('touchmove', this._onTouchMove)
        this.el.removeEventListener('touchend', this._onTouchEnd)
        window.removeEventListener('mouseup', this._onTouchEnd) 
        window.removeEventListener('touchmove', this._onTouchMove)
        window.removeEventListener('mousemove', this._onTouchMove)
        this.emit(Events.END, {
            x: this.x,
            y: this.y 
        })
        this.reset();
    }

    reset() {
        this.touching = false
        this.distance.x = this.distance.y = 0;
        this.x = this.y = 0;
        this.direction.up = false;  
        this.direction.down = false;
        this.direction.left = false; 
        this.direction.right = false;
        this.axis.x = false; 
        this.axis.y = false; 
        this.delta.x = 0;
        this.delta.y = 0;
    }

    listen() {
        if (this.listening) return
        this.listening = true
        this.el.addEventListener('touchstart', this._onTouchStart)
        this.el.addEventListener('mousedown', this._onTouchStart)
    }

    unlisten() {
        if (!this.listening) return
        this.listening = false
        this.el.removeEventListener('touchstart', this._onTouchStart)
        this.el.removeEventListener('touchend', this._onTouchEnd)
        this.el.removeEventListener('mousedown', this._onTouchStart)
        this.el.removeEventListener('mouseup', this._onTouchEnd)
    }

    setAxis() {  
        this.axis.x = (Math.abs(this.distance.x) > 0); 
        this.axis.y = (Math.abs(this.distance.y) > 0); 
    }

    setDirection() {
        this.direction.up = (this.delta.y > 0); 
        this.direction.down = (this.delta.y < 0);
        this.direction.left = (this.delta.x > 0); 
        this.direction.right = (this.delta.x < 0); 
    }

}

Touch.Events = Events

export default Touch