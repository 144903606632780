<template>

  <section id="composer">

    <div class="content">

      <header>
        <h1 class='mask'>LAZY LINE COMPOSER <span class="beta">beta</span></h1>
      </header>

      <p class="sub-title center-col">
        The Lazy Line Composer is an online tool that helps prepare your SVG for animation. Processing your SVG will only take a few seconds. 
        Download your composition when complete! Your zip file will contain the lazy-line-painter lib and your production ready animated svg.   
      </p>

      <p class="mobile-msg">Please visit desktop to use the LAZY LINE COMPOSER</p>

      <div ref="uploader" class='uploader'>

        <div ref="uploadArea" class="upload-area">
          
          <feedback-btn :y="feedback.y" :enabled="feedback.enabled" />
 
          <drop-zone v-on:upload="onUpload" />
          <div ref="container" class="lazyline-container"></div>
          <timeline />

          <div class="api-functions" v-if="composer_state === COMPOSER_STATES.EDIT">
            <h2 ref="paint" class="button" v-on:click="onPaintClick">Paint</h2>
            <h2 ref="pause" class="button" v-on:click="onPauseClick">Pause</h2>
            <h2 ref="erase" class="button" v-on:click="onEraseClick">Erase</h2>
            <h2 ref="destroy" class="button" v-on:click="onDestroyClick">Destroy</h2>
          </div>

          <div ref="panelwrap" class='edit-panel-wrap group'> 
            <div id='copy-to-clip-wrap'>
              <div id='copy-to-clip' v-on:click="onDownloadClick">Download Zip</div>
            </div>
          </div>

        </div>

        <options-panel />

      </div>
 

    </div>
  </section> 

</template>

<script>

import Raf from '@/helpers/Raf'
import Easing from '@/helpers/Easing'
import { mapState, mapMutations } from 'vuex';
import { map  } from '@/helpers/MathUtils';
import ResizeService from '@/services/ResizeService';
import AnalyticsService from '@/services/AnalyticsService';
import JSZip from 'jszip';  
import JSZipUtils from 'jszip-utils' 
import saveAs from 'file-saver'; 
import LazyLinePainter from 'lazy-line-painter';
import Template from '@/templates/download';

import OptionsPanel from '@/components/composer/panel';
import Timeline from '@/components/composer/timeline';
import DropZone from '@/components/composer/dropzone';
import FeedbackBtn from '@/components/composer/feedback-btn';

export default {

  name: 'converter',

  data () {

    return {

      filename: '',
      lazylineEl: null,
      initialUpload: true,
      destroyed: false,

      rect: {
        offset: {
          top: 0,
          left: 0
        },
        height: 0,
        width: 0
      },

      scroll: {
        ratio: 0,
        top: 0,
        center: 0
      },

      feedback: {
        y: 0,
        enabled: false
      },

      uploader: {
        height: 0
      },

      template: {
        svghtml :'',
        version: '1.9.6',
        id: '',
        config: {}
      }
    }
  },

  computed: {

    ...mapState('composer', [
      'composer_state',
      'COMPOSER_STATES',
      'style',
      'animation',
      'download_countdown',
      'countdown_duration'
    ]),

    ...mapState('app', [
      'modal_state',
      'MODAL_STATES'
    ])
  },

  components: {
    OptionsPanel,
    Timeline,
    DropZone,
    FeedbackBtn
  },

  mounted () {
    let lib_path = this.get_lib_path();
    this.urlToPromise(lib_path).
      then(data => { 
        this.lib_binary = data; 
      }).
      catch(err => {
        console.log(err)
      });

    window.addEventListener('scroll', this.onScroll, false)
    ResizeService.on(ResizeService.Events.RESIZE, this.onResize) 
    this.onResize()
  },

  methods: {

    ...mapMutations('composer', [
      'setState', 
      'setScale',
      'setTimelineProgress',
      'setTimelineComplete',
      'setTimelinePaths',
      'setTimelineDuration',
      'setTimelineEase',
      'setTl',
      'setModalState'
    ]),

    ...mapMutations('app', [
      'setModalState'
    ]),

    idle() {
      this.lazyline.destroy();
      this.lazyline = null; 
      this.$refs.panelwrap.style.display = 'none';
      this.$refs.uploadArea.style.width = '600px';  
      this.$refs.container.style.display = 'none'; 
      this.$refs.uploader.style.marginLeft = '0px';
    },

    edit() {
      this.$refs.uploadArea.style.width = '50vw';
      this.$refs.uploader.style.marginLeft = '15rem';
      this.onResize()
    },

    onResize(){
      let rect = this.$el.getBoundingClientRect()
      this.rect.offset.left = rect.left;
      this.rect.offset.top = this.$el.offsetTop;
      this.rect.width = rect.width;
      this.rect.height = rect.height; 
      this.uploader.height = this.$refs.uploader.offsetHeight 
    },

    onScroll(){
      this.scroll.top = document.body.scrollTop || document.documentElement.scrollTop
      this.scroll.center = this.scroll.top + (window.innerHeight * 0.5)
      let combinedHeight = (this.rect.offset.top + this.rect.height)
      let inRange = (this.scroll.center > this.rect.offset.top && this.scroll.center < combinedHeight)
      this.feedback.enabled = inRange
      if( inRange ){
        this.scroll.ratio = Math.abs(this.scroll.center - this.rect.offset.top) / this.rect.height
        this.feedback.y = this.scroll.ratio * this.uploader.height
      } 
    },

    onPaintClick(){
      this.paint();
      this.$refs.pause.classList.remove('inactive');
      this.$refs.erase.classList.remove('inactive'); 
      this.$refs.pause.innerText = 'pause';
      AnalyticsService.sendEvent('COMPOSER', 'click', 'paint');
    },

    onEraseClick(){
      this.lazyline.erase();
      this.$refs.pause.classList.add('inactive'); 
      this.$refs.erase.classList.add('inactive'); 
      AnalyticsService.sendEvent('COMPOSER', 'click', 'erase');
    },

    onPauseClick(){
      var data = this.lazyline.get();
      if( data.paused ){
        this.lazyline.resume();
        this.$refs.pause.innerText = 'pause';
        AnalyticsService.sendEvent('COMPOSER', 'click', 'pause');
      } else {
        this.lazyline.pause();
        this.$refs.pause.innerText = 'resume';
        AnalyticsService.sendEvent('COMPOSER', 'click', 'resume');
      }
    },

    onDestroyClick(){
      AnalyticsService.sendEvent('COMPOSER', 'click', 'destroy');
      if (confirm('Are you sure you want to destroy your lazyline')) {
        this.setState(this.COMPOSER_STATES.IDLE);
        AnalyticsService.sendEvent('COMPOSER', 'click', 'destroyed');
      }
    }, 


    /**
     * Fetch the content and return the associated promise.
     * @param {String} url the url of the content to fetch.
     * @return {Promise} the promise containing the data.
     */
    urlToPromise(url) {
      return new Promise((resolve, reject) => {
        JSZipUtils.getBinaryContent(url, function (err, data) {
          if(err) {
            reject(err);
          } else {
            resolve(data);
          }
        });
      });
    },

    onUpload(result) {
      this.process(result); 
    },

    process(result){ 

      this.filename = this.getFilename(result.file);
      this.lazylineEl = this.getLazylineEl(result.innerHTML);
      // this.setDefaults();
      this.$refs.container.appendChild(this.lazylineEl); 
      this.template.id = this.filename;

      setTimeout(this.paintUsersLazyline, 500);
    },

    getFilename(file){
      let name = file.name.replace('.svg',''); 
      name = name.replace(/ /g,''); 
      name = name.replace('.','');
      name = name.replace('-','');
      let length = 10;
      let trimmedString = name.substring(0, length);
      return name;
    },

    getLazylineEl(innerHTML) { 

      let el = document.createElement("div");
      el.innerHTML = innerHTML;

      let svg = el.querySelector('svg');  
      svg.setAttribute("data-llp-composed", true);

      this.paths = el.querySelectorAll('path, polygon, circle, ellipse, polyline, line, rect');  
      for (let i = 0; i < this.paths.length; i++) { 
        this.paths[i].setAttribute("data-llp-id", (this.filename + '-' + i));
        this.paths[i].setAttribute("data-llp-duration",this.animation.duration.current);
        this.paths[i].setAttribute("data-llp-delay", (this.animation.duration.current * i));
      }

      return svg
    },

    setDefaults(){
      for (let i = 0; i < this.paths.length; i++) {
        let el = this.paths[i]
        let defaults = {
          stroke: {
            color: el.getAttribute("data-llp-stroke-color") || el.getAttribute("stroke") || el.style.strokeColor,
            linecap: el.getAttribute("data-llp-stroke-cap") || el.getAttribute("stroke-color") || el.style.strokeColor,
          },
          fill: {
            color: el.getAttribute("data-llp-fill-color") || el.getAttribute("fill") || el.style.fill,
            opacity: el.getAttribute("data-llp-fill-opacity") || el.getAttribute("fill-opacity") || el.style.opacity
          } 
        }

        console.log(defaults)
      }
    },

    get_lib_filename() {
      return 'lazy-line-painter-' + this.template.version + '.min.js';
    },

    get_lib_path() {
      return 'lib/' + this.get_lib_filename()
    },

    paintUsersLazyline() {

      // hide fill
      if(this.style.fill){    
        for (let i = 0; i < this.paths.length; i++) { 
          this.paths[i].setAttribute("fill-opacity", 0);
        }  
      }; 

      this.$refs.panelwrap.style.display = 'block'; 
      this.$refs.container.style.display = 'flex';
      this.lazylineEl.id = this.filename;

      let bBox = this.lazylineEl.getBBox(); 
      let scale = ( bBox.width / 600 ) 
      this.setScale( scale );


      this.lazyline = new LazyLinePainter(this.lazylineEl, {
        delay: this.animation.delay.current,
        log: true
      }); 

      this.lazyline.on('start', this.onTimelineStart);
      this.lazyline.on('update', this.onTimelineUpdate);
      this.lazyline.on('complete', this.onTimelineComplete);
      this.lazyline.on('pause', this.onTimelinePause);

      this.updateTimeline(); 

      let delay = this.initialUpload ? 1500 : 500;
      
      setTimeout( this.paint , delay); 
      this.initialUpload = false;

      this.setState(this.COMPOSER_STATES.EDIT);
    },

    updateTimeline(){

      this.setTimelinePaths(this.paths);
      this.setTimelineDuration(this.lazyline.config.totalDuration);
 
      let paths = [];

      for (var i = 0; i < this.lazyline.__paths.length; i++) {
        let path = this.lazyline.__paths[i];
        paths.push({
          progress: 0,
          id: path.id,
          ease: path.ease,
          onStrokeCompleteDone: false,
          onStrokeStartDone: false,
          startProgress: path.startProgress,
          durationProgress: path.durationProgress
        })
      }


      let tl = {};
      let ease = this.getEase();
      for (var i = 0; i < this.lazyline.config.totalDuration; i++) {

        let progress = i / this.lazyline.config.totalDuration;
        let tlProgress = this.getProgress(progress, ease);

        for (let j = 0; j < paths.length; j++) {

          let path = paths[j];
          let pathProgress = this.getElapsedProgress(path, tlProgress);
          path.progress = this.getProgress(pathProgress, path.ease);
          
          if (path.progress === 1) {

            if (!path.onStrokeCompleteDone) {
              path.onStrokeCompleteDone = true; 
              tl[path.id].complete = i;
            }

          } else if (path.progress > 0.00001) {

            if (!path.onStrokeStartDone) { 
              tl[path.id] = {start: i, complete: this.lazyline.config.totalDuration} 
              path.onStrokeStartDone = true;
            } 
          }
        } 
      } 
 
 
      this.setTl(tl);
    },


    getElapsedProgress(path, tlProgress) {

      let elapsedProgress;

      if (
        tlProgress >= path.startProgress &&
        tlProgress <= (path.startProgress + path.durationProgress)
      ) {
        elapsedProgress = (tlProgress - path.startProgress) / path.durationProgress;
      } else if (tlProgress >= (path.startProgress + path.durationProgress)) {
        elapsedProgress = 1;
      } else if (tlProgress <= path.startProgress) {
        elapsedProgress = 0;
      }

      return elapsedProgress;
    },

    getProgress(linearProgress, ease) {

      let progress = linearProgress;

      if (ease) {
        progress = Easing[ease](linearProgress);
      }
      return progress;
    },
 

    onTimelineStart(){
      this.startTime = new Date();
      this.setTimelineComplete(false);
    },

    onTimelineUpdate(){
      let progress = (this.lazyline.config.elapsedTime / this.lazyline.config.totalDuration);
      this.setTimelineProgress(progress);
    },

    onTimelineComplete(){
      this.setTimelineComplete(true);
    },

    onTimelinePause(){
        this.$refs.pause.innerText = 'resume';
    },

    onDownloadClick() { 

      const blob = new Blob([this.lazylineEl], {type: 'image/svg+xml'});
      var s = new XMLSerializer(); 

      let svg = this.lazylineEl.cloneNode(true)
      this.clean(svg);
      this.template.config = this.getConfig();
      this.template.svghtml = s.serializeToString(svg);
  
      AnalyticsService.sendEvent('COMPOSER', 'click', 'download');

      if(this.download_countdown){

        this.setModalState(this.MODAL_STATES.DOWNLOAD);

        this.downloadInterval = setTimeout(()=>{
          this.download();
        }, this.countdown_duration);

        this.modalInterval = setTimeout(()=>{
          this.setModalState(null); 
        }, this.countdown_duration + 500);

      } else {

        this.download();

      }
    },

    download() {
      let zip = new JSZip();
      zip.file("index.html",  Template(this.template));
      zip.file(this.get_lib_filename(), this.lib_binary, {binary:true});
      zip.generateAsync({type:"blob"})
        .then(function (blob) {
          saveAs(blob, "lazy-line-painter.zip");
        });
    },

    paint(){
      let ease = this.getEase();
      this.lazyline.paint({
        reverse: this.animation.reverse, 
        ease: ease 
      }); 
    },

    clean(el) {
      // clean up svg
      let paths = el.querySelectorAll('path, polygon, circle, ellipse, polyline, line, rect');
      for (let i = 0; i < paths.length; i++) {  
        let el = paths[i];
        el.removeAttribute("stroke-width"); 
        el.style.strokeWidth = '';
        el.removeAttribute("stroke-opacity"); 
        el.style.strokeOpacity = '';
        el.removeAttribute("stroke");
        el.style.stroke = '';
        el.removeAttribute("stroke-linecap");
        el.style.strokeLineCap = '';
        el.removeAttribute("stroke-linejoin");
        el.style.strokeLineJoin = '';

        el.style.strokeDasharray = ''; 
        el.style.strokeDashoffset = '';  
      }
    },

    getConfig(){
 
      let config = {
        ease: this.getEase(),
        strokeWidth: this.style.stroke.width.current,
        strokeOpacity: this.style.stroke.opacity.current
      }

      if(this.style.stroke.color !== ''){
        config.strokeColor = this.style.stroke.color;
      }

      if(this.style.stroke.cap.current !== 0){
        config.strokeCap = this.style.stroke.cap.options[this.style.stroke.cap.current].label;
      }

      if(this.style.stroke.dash.current !== 0){
        config.strokeDash = this.style.stroke.dash.options[this.style.stroke.dash.current].label;
      }

      if(this.animation.delay.current !== 0){
        config.delay = this.animation.delay.current;
      }

      if(this.animation.reverse){
        config.reverse = this.animation.reverse;
      }

      return JSON.stringify(config);
    },

    getEase(){
      return 'ease' + this.animation.ease.options[this.animation.ease.current].label;
    },

    _updateTiming(){
      
      let duration = this.animation.duration.current;
      for (let i = 0; i < this.paths.length; i++) {
        this.paths[i].setAttribute("data-llp-duration", duration);
        if(this.animation.draw_seq){
          this.paths[i].setAttribute("data-llp-delay", (duration * i));
        } else {
          this.paths[i].setAttribute("data-llp-delay", 0);
        }
      }

      this.lazyline._parseDataAttrs();
      this.lazyline._updateDuration();

      this.setTimelineDuration(this.lazyline.config.totalDuration);
    }
  },

  watch: {

    'composer_state'(val){
      if(val === this.COMPOSER_STATES.IDLE){
        this.idle();
      } else if(val === this.COMPOSER_STATES.EDIT){
        this.edit();
      } 
    },

    'download_countdown'(val){
      if(!val){
        window.clearInterval(this.downloadInterval)
        window.clearInterval(this.modalInterval)
        this.setModalState(null); 
        this.download();
      }
    },

    // styling

    'style.fill'(val){   
      let value = val ? 0 : 1;
      for (let i = 0; i < this.paths.length; i++) { 
        this.paths[i].setAttribute("fill-opacity", value);
      }  
    },

    'style.stroke.width.current'(val){   
      for (let i = 0; i < this.paths.length; i++) {  
        this.paths[i].style.strokeWidth = val; 
      } 
    },

    'style.stroke.opacity.current'(val){   
      for (let i = 0; i < this.paths.length; i++) {
        this.paths[i].style.strokeOpacity = val; 
      }  
    },

    'style.stroke.color'(val){   
      for (let i = 0; i < this.paths.length; i++) {
        this.paths[i].style.stroke = val;  
      }  
    },

    'style.stroke.dash.current'(val){
      this.lazyline.config.strokeDash = val === 0 ? null : this.style.stroke.dash.options[val].label; 
      this.lazyline._parseDataAttrs(); 
      this.lazyline._updateDuration();
      this.lazyline._setupPaths();
      this.paint();
    },

    'style.stroke.cap.current'(val){
      for (let i = 0; i < this.paths.length; i++) {
        let cap = val === 0 ? '' : this.style.stroke.cap.options[val].label;
        this.paths[i].setAttribute("data-llp-stroke-cap", cap); 
        this.paths[i].style.strokeLinecap = cap;  
      }   
    },

    'style.stroke.join.current'(val){
      for (let i = 0; i < this.paths.length; i++) {
        let join = val === 0 ? '' : this.style.stroke.join.options[val].label;
        this.paths[i].setAttribute("data-llp-stroke-join", join); 
        this.paths[i].style.strokeLinejoin = join;  
      }   
    },



    // animation

    'animation.delay.current'(val){
      this.lazyline.set('delay', val);
      this._updateTiming();
      this.updateTimeline()
    },

    'animation.reverse'(val){
      this.lazyline.set('reverse', val);
      this.updateTimeline();
    },
 
    'animation.draw_seq'(val){
      this._updateTiming();
      this.updateTimeline()
      //this.paint();
    },
 
    'animation.duration.current'(val){
      this._updateTiming();
    },
 
    'animation.progress'(val){
      this.lazyline.set('progress', val);
    },
 
    'animation.ease.current'(val){
      let ease = this.getEase();
      this.lazyline.set('ease', ease);
      this.setTimelineEase(ease);
      this.updateTimeline();
    },
 
    'animation.repeat.current'(val){
      this.lazyline.set('repeat', val);
      this.updateTimeline();
    } 

  } 
} 
</script>


<style lang="sass">

  #composer
    margin-top: 0px

    .center-col
      max-width: 600px

    .upload-area
      position: relative
      min-height: 35rem
      min-width: 600px
      width: 600px

    .desc
      margin-top: 5rem

    p
      font-family: 'melbournereg'
      font-size: 22px
      color: #f7f3ed
      text-transform: initial
      letter-spacing: 0.1rem
 
    .content
      margin-top: 0px
      background: #cc2d8a

    .lazyline-container
      max-height: calc(100vh - 35rem)
      overflow: hidden
      background-color: #f7f3ed /*#845a5a*/
      min-height: 200px
      width: 100%
      height: 100%
      display: none
      align-items: center
      justify-content: center
      border-radius: 2rem
      transition: border-radius 1s ease-in-out, background-color 1s ease-in-out

      border-top-left-radius: 2rem
      border-top-right-radius: 2rem
      border-bottom-left-radius: 0
      border-bottom-right-radius: 0
      background-color: #f7f3ed

      .lazy-line-painter
        position: relative
        width: 100%
        height: 100%
        padding: 3rem
        overflow: visible
        max-height: calc(100vh - 30rem)

        path, polygon, circle, ellipse, polyline, line, rect
          transition: stroke 0.5s ease-in-out, stroke-width 0.5s ease-out, stroke-opacity 0.5s ease-out

    h1
      font-size: 9.3rem
      margin: 0px
      color: #f7f3ed

      @media only screen and (max-width : 600px)
        font-size: 7rem

      .beta
        position: absolute
        font-size: 2rem
        text-transform: initial

    .sub-title
      padding: 2rem 0 4rem
      font-size: 2rem
      letter-spacing: 1px
      margin-top: 4rem

    // .lazylinepainter path
    //   transition: stroke-width 0.5s linear, stroke 0.5s linear

    .mobile-msg
      display: none

      .mobile &
        display: block

    .uploader
      margin: 0 auto
      position: relative
      height: 100%
      min-height: 35rem
      transition: height 1s ease-in-out
      justify-content: center
      display: flex 

      .mobile &
        display: none

      .api-functions
        display: flex
        justify-content: space-evenly
        background-color: #f7f3ed

        .button
          background-color: #e9e1d9
          padding: 1.5rem 0.8rem
          text-transform: uppercase
          font-family: 'melbournebold'
          font-size: 14px
          letter-spacing: 2px
          color: #3d3d3d
          cursor: pointer
          flex-grow: 1
          transition: background-color 0.3s ease-out
          margin: 1rem
          border-radius: 5px

          &:hover
            background-color: #f7f2ec

          &.inactive
            pointer-events: none
            color: #9d9d9d
            cursor: default


    .edit-panel-wrap
      display: none
      width: 100%
      position: relative
      border-radius: 2rem
 
      .lazy-line
        padding: 40px
        margin: 0 auto
  
      .edit-panel
        margin: 0 3%
        width: 94%
        height: 0px
        text-align: left
        border-radius: 8px


      #svg-output 
        font-size: 12px
        color: #333
        text-transform: none
        width: 70%
        padding: 5px
        border: none
        height: 100px
        outline: none
        resize: none
        border-top-left-radius: 10px
        border-bottom-left-radius: 10px

      #copy-to-clip-wrap
        cursor: pointer
        width: 100%
        position: relative

      #copy-to-clip
        text-transform: none
        font-size: 2.4rem
        letter-spacing: 2px
        font-family: 'melbournereg'
        position: relative
        margin: 0
        height: 100px
        color: #fff2f2
        display: flex
        align-items: center
        justify-content: center
        border-radius: 1rem
        background: #de6565
        border-top-left-radius: 0
        border-top-right-radius: 0
        transition: background-color 0.3s ease-out

        &:hover
          background-color: #e95739

</style> 