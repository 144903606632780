import { render, staticRenderFns } from "./btn.vue?vue&type=template&id=8bd16a8c&scoped=true&"
import script from "./btn.vue?vue&type=script&lang=js&"
export * from "./btn.vue?vue&type=script&lang=js&"
import style0 from "./btn.vue?vue&type=style&index=0&id=8bd16a8c&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8bd16a8c",
  null
  
)

component.options.__file = "btn.vue"
export default component.exports