<template>

  <div id="dropzone">

    <div ref="drop" class="drop">
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 105.3 112.6" enable-background="new 0 0 105.3 112.6" xml:space="preserve">
        <g>
          <path opacity="0.1" enable-background="new" d="M78,39.9c0,4.6,0,10.2,0,10.2h26.7l-52,60.3l-52-60.3h27.7
            c0,0-0.1-4.8-0.1-10.8C28.2,8.7,78,9.8,78,39.9z"/>
          <path fill="#FFDBD7" d="M78,23.4c0,4.6,0,10.3,0,10.3h26.7L52.7,94l-52-60.3h27.7c0,0-0.1-4.8-0.1-10.8C28.2-7.8,78-6.7,78,23.4z"
            />
          <g>
            <path fill="none" stroke="#5B4646" stroke-width="1.4173" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="435,435" d="
            M35,19.4c0,0,1.1,2.6,3.7,2.6c2.5,0,3.7-2.6,3.7-2.6s1,2.6,3.7,2.6s3.7-2.6,3.7-2.6S51,22,53.5,22s3.7-2.6,3.7-2.6
            s1.1,2.6,3.7,2.6c2.6,0,3.7-2.6,3.7-2.6s1.5,2.6,3.7,2.6s3.7-2.6,3.7-2.6"/>
            <path fill="none" stroke="#5B4646" stroke-width="1.4173" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="117,117" d="
            M47.3,34c0,0-1-3.8-4.7-3.8c-3.1,0-4.7,3.8-4.7,3.8"/>
            <path fill="none" stroke="#5B4646" stroke-width="1.4173" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="109,109" d="
            M68.5,33.8c0,0-1.3-3.5-4.4-3.5c-3,0-4.4,3.5-4.4,3.5"/>
            <path fill="none" stroke="#5B4646" stroke-width="1.4173" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="257,257" d="
            M42.9,40c0,0,2.3,8.2,10.2,8.2c8.3,0,10.2-8.2,10.2-8.2"/>
          </g>
        </g>
      </svg>
    </div>

    <div ref="frame" class="frame">

      <form class="box"

        ref="form" 
        method="post" 
        action="" 
        enctype="multipart/form-data"

        v-on:click="onFormClick"
        v-on:drag="onFormDrag"
        v-on:drop="onFormDrop"
        v-on:dragstart="onFormDragstart"
        v-on:dragend="onFormDragend"
        v-on:dragover="onFormDragover"
        v-on:dragenter="onFormDragenter"
        v-on:dragleave="onFormDragleave"
        >

        <div class="box__input">

          <input class="box__file" 

            ref="input" 
            type="file" 
            name="files[]" 
            id="file" 
            data-multiple-caption="{count} files selected" 
            multiple
            v-on:change="onInputChange"
            />

          <p>{{message}}</p>

        </div> 

      </form>
    </div>

  </div> 

</template>

<script>
 
import { mapState, mapMutations } from 'vuex';
import { map  } from '@/helpers/MathUtils';
import ResizeService from '@/services/ResizeService';
import AnalyticsService from '@/services/AnalyticsService'; 
 

export default {

  name: 'converter-dropzone',

  data () {
    return {

      file: null, 
      reader: null,
      format: '',
      message: '',

      MESSAGE: {
        UPLOAD: 'Click or Drop your SVG file here' 
      }
    }
  },

  computed: {
    ...mapState('composer', [
      'composer_state',
      'COMPOSER_STATES' 
    ]) 
  },

  components: {},

  mounted () {
    this.message = this.MESSAGE.UPLOAD;
    this.reader = new FileReader();
    this.$form = this.$refs.form;
    this.$input = this.$refs.input; 
  },

  methods: {

    ...mapMutations('composer', [
      'setState'
    ]),

    onFormDrag(e){
      this.preventDefault(e);
    },

    onFormDrop(e){

      this.preventDefault(e);

      AnalyticsService.sendEvent('COMPOSER', 'dragdrop', 'upload');
      this.$form.classList.remove('is-dragover');
      
      let file;

      if (e.dataTransfer.items) {

        // Use DataTransferItemList interface to access the file(s) 
        // If dropped items aren't files, reject them
        if (e.dataTransfer.items[0].kind === 'file') {
          file = e.dataTransfer.items[0].getAsFile();  
        }

      } else { 
        // Use DataTransfer interface to access the file(s) 
        file = e.dataTransfer.files[0]; 
      } 
 
      this.showFile(file);
    },

    onFormDragstart(e){
      this.preventDefault(e);
    },

    onFormDragover(e){
      this.$form.classList.add('is-dragover');
      this.preventDefault(e);
    },

    onFormDragenter(e){
      this.$form.classList.add('is-dragover');
      this.preventDefault(e);
    },

    onFormDragend(e){
      this.$form.classList.remove('is-dragover');
      this.preventDefault(e);
    },

    onFormDragleave(e){
      this.$form.classList.remove('is-dragover');
      this.preventDefault(e);
    },

    onFormClick() {
      AnalyticsService.sendEvent('COMPOSER', 'click', 'upload');
    },

    onFormSubmit(e) {

      if (this.$form.classList.contains('is-uploading')){
        return false;
      }

      this.$form.classList.add('is-uploading');
      this.$form.classList.remove('is-error');

      this.preventDefault(e);
    },

    onInputChange(e) {
      let file = e.target.files[0];
      this.showFile(file); 
    },

    preventDefault(e){
      e.preventDefault();
      e.stopPropagation();
    },

    getInvalidMsg(format){
      return `Ah Oh! ${format} is an Invalid file format yo`;
    },
 
    showFile(file) {

      this.file = file;

      this.setState(this.COMPOSER_STATES.LOADING);
      let validExt = this.validateExt(file); 
      if(validExt) {
        this.readFile(file);
      } else { 
        let ext = file.name.split('.')[1];
        this.message = this.getInvalidMsg(ext);
      }
    },

    validateExt(file) {
      return(/(svg)/g.test(file.name));
    },

    readFile(file){
      this.reader.onloadend = this.onFileRead;
      if (file && file.type=="image/svg+xml") { 
        this.reader.readAsText(file);
      } else {
        alert("wrong file type or no file provided");
      } 
    },

    onFileRead() {
      this.$emit('upload', {
        file: this.file, 
        innerHTML: this.reader.result
      });
    },


    // states

    idle() {
      this.$el.style.display = 'block';
      this.$input.value = "";
    },

    edit() {
      this.$el.style.display = 'none';   
    }

  },

  watch: { 
    'composer_state'(val){   
      if(val === this.COMPOSER_STATES.IDLE){
        this.idle();
      } else if(val === this.COMPOSER_STATES.EDIT){
        this.edit();
      } 
    }
  } 
}

</script>


<style lang="sass">
  
  #dropzone


    h1
      font-size: 9.3rem
      margin: 0px
      color: #f7f3ed

    .drop
      left: 50%
      margin-left: -50px
      width: 101px
      height: 105px
      top: 4rem
      z-index: 99
      position: absolute
      pointer-events: none
      animation: DROP 3s ease-in-out infinite

      @keyframes DROP
        0%  
          transform: translate3d(0, 0, 0)
        50% 
          transform: translate3d(0, 20px, 0)
        100%  
          transform: translate3d(0, 0, 0)

    .frame
      max-height: calc(100vh - 30rem)
      overflow: hidden
      background-color: #845a5a
      min-height: 200px
      width: 100%
      height: 100%
      display: flex
      align-items: center
      justify-content: center
      position: absolute
      border-radius: 2rem
      transition: border-radius 1s ease-in-out, background-color 1s ease-in-out


    .box
      padding: 2rem 2rem 8rem
      height: 100%
      width: 100%
      position: absolute

    .box__input
      background-color: #de6565
      border-radius: 0.3rem
      width: 100%
      height: 100%
      position: relative
      transition: background-color 0.3s ease-out, border-radius 0.3s ease-out

      &:hover
        background-color: #e95739
        border-radius: 1rem

      > p 
        position: absolute
        top: 0
        width: 100%
        height: 100%
        display: flex
        align-items: center
        justify-content: center
        flex-direction: column
        pointer-events: none
        margin: 6rem 0 0

    .desc
      line-height: 3rem
      margin-top: 4rem

    .box__file
      width: 100%
      height: 100%
      opacity: 0
      cursor: pointer
 
    .box.has-advanced-upload 

    .box.has-advanced-upload .box__dragndrop
      display: inline

    .box.is-dragover
      background-color: grey

    .box.is-uploading .box__input
      visibility: none

    .box.is-uploading .box__uploading
      display: block

</style> 