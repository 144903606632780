<template>
  <section id="intro">

    <div ref="showcase" class='showcase'> 

      <transition name="fade" mode="out-in"> 
        <svg-logo v-if="index === 0" />
        <svg-chair v-if="index === 1" />
        <svg-face v-if="index === 2" /> 
      </transition>

    </div>

    <h2 class='mask sub-title'>
      <span>A Modern JS library for SVG path animation</span>
      <div class="reload-wrap btn tooltip" v-on:click="onClick">
        <div class="reload rotate" ></div>
      </div>
    </h2 >

    <div class="sub-heading">
      <a href="#getting-started">Quick Start</a> | <a href="#how-it-works">How it Works</a> | <a href="#composer">Composer</a><!--  | <span>Docs</span> -->
    </div>

    <div class="github-share"> 
      <iframe src="https://ghbtns.com/github-btn.html?user=camoconnell&repo=lazy-line-painter&type=star&count=true&size=large" frameborder="0" scrolling="0" width="160px" height="30px"></iframe>

      <iframe src="https://ghbtns.com/github-btn.html?user=camoconnell&repo=lazy-line-painter&type=watch&count=true&size=large&v=2" frameborder="0" scrolling="0" width="137px" height="30px"></iframe>
    </div>
  </section> 
</template>

<script>

import Raf from '@/helpers/Raf'
import { mapState, mapMutations } from 'vuex';
import ResizeService from '@/services/ResizeService'; 
import LazyLinePainter from 'lazy-line-painter';
import SvgLogo from '@/components/intro/svg-logo';
import SvgFace from '@/components/intro/svg-face';
import SvgChair from '@/components/intro/svg-chair'; 

export default {

    name: 'intro',

    data () {
      return {
        index: 0, 
        $showcase: null 
      }
    },

    computed: { },

    components: {
      SvgChair,
      SvgFace,
      SvgLogo 
    },

    mounted () {
      this.setup();
    },

    methods:{

     // ...mapMutations('app', ['setIndex']),

      setup() { },

      enter(){ },
      
      leave(){ },

      onClick() {

        let i = this.index;

        if(i === 2){
          i = 0;
        }else{
          i++;
        } 

        console.log(i)
        this.index = i;
      }
    
    },

    watch: {} 
} 
</script>


<style  lang="sass">

  #intro
    background: #cc2d8a; 
    padding-top: 2rem; 
    padding-bottom: 10rem;

    .a
      fill: none;

    svg
      height: 100%; 
      width: 100%; 
      position: relative;

    h1
      font-size: 6.4em;  
      margin: 0px; 
      color: #de8f8f;

    .github-share
      background: #b5287b;
      padding: 1rem 1.3rem;
      display: inline-block;
      border-radius: 10px;

    .sub-title
      font-size: 4.5rem; 
      margin: 0px; 
      color: #f7f3ed; 
      display: inline-block; 
      position: relative;

    .sub-heading
      font-family: 'melbournereg';
      color: #861a59;
      margin: 2rem 0 3rem;
      position: relative;
      font-size: 1.7rem;

      a
        color: #f7f3ed;
        margin: 0 20px;

        &:hover
          text-decoration: underline;

    .download
      background: url(~assets/img/btn-bg.jpg); 
      height: 130px;

      h2
        line-height: 1.3; 
        font-size: 4.4em; 
        color: #fff;

    .showcase
      padding: 4rem 0;
      width: 100%;
      position: relative;
      height: 60vh;

      @media only screen and (max-width : 600px) 
        height: auto;

      .lazylinepainter
        height: 100%; 
        width: 100%; 
        position: relative;
      

    .reload-wrap
      bottom: 10px;
      right: 0; 
      top: -8rem;
      overflow: hidden;
      background: #b5287b;



      #ll-chair, 
      #ll-guy
        width: 290px; 
        height: 420px;
        position: absolute;
        left: 50%;
        margin-left: -145px;

      #ll-face 
        width: 349px; 
        height: 277px;
        position: absolute;
        left: 50%;
        margin: 60px 0 0 -175px;

      #ll-vertical-grid,
      #ll-horizontal-grid,
      #ll-shape
        width: 582px; 
        height: 448px;
        position: absolute;
        left: 50%;
        margin-left: -290px;

      #ll-shape
        z-index: 3
 
</style>
