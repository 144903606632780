import postscribe from 'postscribe'
import 'url-search-params-polyfill'

class AnalyticsService {
  constructor() {
    window.ga('create', 'UA-37813630-1', 'auto')
    this.setSource(
      new window.URLSearchParams(window.location.search).get('_source')
    )
    this.injectScript()
  }

  injectScript() {
    return new Promise((resolve) => {
      const head = document.head || document.getElementsByTagName('head')[0]
      const src = '//www.google-analytics.com/analytics.js'
      postscribe(head, `<script src='${src}'></script>`, {
        afterAsync: () => {
          resolve()
        },
      })
    })
  }

  sendPageview = (url) => {
    // console.log('sendPageview', url)
    window.ga('send', 'pageview', url)
  }

  sendEvent = (category, action, label, value) => {
    // console.log('sendEvent', category, action, label, value)
    window.ga('send', 'event', category, action, label, value)
  }

  setDimension = (dimension, value) => {
    // console.log('setDimension', dimension, value)
    window.ga('set', dimension, value)
  }

  setSource = (source) => {
    // console.log('setSource', source)
    if (!source) return
    window.ga('set', 'dimension1', source)
    this.sendEvent('source', 'view', source)
  }
}

export default new AnalyticsService()
