<template> 
  <div id="feedback-btn" 
    v-on:click="onClick"
    >

    <svg ref="svg" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 296.6 254.5" enable-background="new 0 0 296.6 254.5" xml:space="preserve">
      <path fill="none" stroke-width="5.6693" stroke-miterlimit="10" d="M197.5,100.5c0,53.6-43.4,97-97,97
      c-19.9,0-38.4-6-53.8-16.3C31.5,184.5,12,189,12,189s5.5-13,10.7-30.5c-12.1-16.2-19.2-36.2-19.2-58c0-53.6,43.4-97,97-97
      S197.5,46.9,197.5,100.5z"/>
      <g class="hand" >
        <path fill="none" stroke-width="5.6693" stroke-linecap="round" stroke-miterlimit="10" d="M55.5,78.5H81
        c0,0,8-7,10.5-17.5s3-21,10.5-21s10.5,3,10.5,9.5s-1,18.5-7,27.5c13,0,22,0,22,0s12.5-0.5,12.5,9s-11,9-11,9h-7.5l8,0.5
        c0,0,13.5-1,13.5,10s-13.5,8.5-13.5,8.5H122l7.5,0.5c0,0,10.5-0.5,10.5,9s-10.5,7.5-10.5,7.5h-8c0,0,13-0.5,13,9s-7.5,8.5-12.5,8.5
        s-14,0-22.5,0s-13.5-6-19.5-6s-30,0-30,0"/>
        <line fill="none" stroke-width="5.6693" stroke-miterlimit="10" x1="63.5" y1="128" x2="83.5" y2="128"/>
      </g>
      <line fill="none" stroke-width="5.6693" stroke-linecap="round" stroke-miterlimit="10" x1="246.5" y1="147.5" x2="185.5" y2="147.5"/>
      <line fill="none" stroke-width="5.6693" stroke-linecap="round" stroke-miterlimit="10" x1="259.5" y1="176" x2="162.5" y2="176"/>
      <line fill="none" stroke-width="5.6693" stroke-linecap="round" stroke-miterlimit="10" x1="245" y1="203.5" x2="175.5" y2="203.5"/>
      <path fill="none" stroke-width="5.6693" stroke-miterlimit="10" d="M144,188c5.9,35.7,37.1,62.9,74.8,62.9
      c15.5,0,30-4.6,42-12.6c11.9,2.5,27.1,6,27.1,6s-4.3-10.1-8.4-23.6c9.4-12.5,15-28.1,15-44.9c0-41.5-33.9-75.1-75.8-75.1
      c-6.9,0-13.5,0.9-19.8,2.6"/>
    </svg>

    <div class="feedback-cta">
      feedback
    </div>
  </div>
</template>

<script>

import ResizeService from '@/services/ResizeService';
import Raf from '@/helpers/Raf';
import {clamp} from '@/helpers/MathUtils';
import { mapState, mapMutations } from 'vuex';
import LazyLinePainter from 'lazy-line-painter';

export default {

    name: 'feedback-btn',

    props: {
      y: Number,
      enabled: Boolean 
    },

    data () {
      return {
        tgy: 0,
        cy: 0,
        height: 0,
        value: false
      }
    },

    computed: {
      ...mapState('app', [
        'modal_state',
        'MODAL_STATES'
      ])
    },

    components: {},

    mounted () {

      this.myAnimation = new LazyLinePainter(this.$refs.svg, {
        duration: 100
      })

      ResizeService.on(ResizeService.Events.RESIZE, this.onResize)
      Raf.add(this.onTick) 
      this.onResize()
    },

    methods: {

      ...mapMutations('app', [
        'setModalState'
      ]),

      onClick() {
        this.setModalState(this.MODAL_STATES.FEEDBACK);
      },

      onResize() {
        this.height = this.$el.offsetHeight;
      },

      onTick() {
        this.cy += (this.tgy - this.cy) * 0.2
        this.$el.style.transform = 'translate3d(0, ' + this.cy + 'px, 0)'
      }
      
    },

    watch: {
      
      y(val){
        this.tgy = clamp((val - this.height), 0 , (val - this.height))
      },

      enabled(val){
        if(val){
          this.$el.style.opacity = 1
          this.myAnimation.paint()
        } else {
          this.$el.style.opacity = 0
        }
      }
    } 
} 
</script>


<style lang="sass" scoped>

  #feedback-btn
    width: 10rem
    position: absolute
    left: -15rem
    top: 0
    z-index: 2
    cursor: pointer
    transition: opacity 0.5s ease-out

    &:hover
      .hand
        stroke: #f6f3ee

      .feedback-cta
        background-color: #601b43

    svg
      stroke: #b5287b

      .hand
        transition: stroke 0.2s ease-out

    .feedback-cta
      margin-top: 1rem
      height: 3rem
      background-color: #b5287b
      color: #f6f3ee
      border-radius: 5px
      display: flex
      width: 100%
      align-items: center
      justify-content: center
      text-transform: initial
      font-size: 1.6rem
      font-family: 'melbournebold'
      letter-spacing: 1px
      cursor: pointer
      transition: background-color 0.2s ease-out

      &:hover
        background-color: #601b43 

</style>