import Vue from 'vue'
import Vuex from 'vuex'

import browser from './browser'
import app from './app' 
import composer from './composer' 

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        browser: browser,
        app: app ,
        composer: composer 
    },
    strict: process.env.NODE_ENV !== 'production'
})

if (module.hot) {
    module.hot.accept(['./browser', './app', './composer' ], () => {
        store.hotUpdate({
            modules: {
                browser: require('./browser'),
                app: require('./app') ,
                composer: require('./composer') 
            }
        })
    })
}

export default store