import * as mutations from './mutations'
import * as actions from './actions'
import * as getters from './getters'
import 'url-search-params-polyfill'
let debug = process.env.NODE_ENV !== 'production'
    // debug = false // DEBUG

let urlParams = new window.URLSearchParams(window.location.search)
if (urlParams.get('_debug') !== null && urlParams.get('_debug') !== false) debug = true

const MODAL_STATES = {
  DOWNLOAD: 'MODEL_STATE:DOWNLOAD',
  FEEDBACK: 'MODEL_STATE:FEEDBACK' 
}
    
const MODAL_TRANSITION_STATES = {
  OPEN: 'MODAL_TRANSITION_STATE:OPEN',
  CLOSED: 'MODAL_TRANSITION_STATE:CLOSED'
}
 
const state = {

    modal_state: null,
    MODAL_STATES: MODAL_STATES,
    modal_transition_state: null,
    MODAL_TRANSITION_STATES: MODAL_TRANSITION_STATES,

    debug: false, //debug, 
 
    signedIn: false,
    accountType: 'FREE',
    loginPanelOpen: false,

    menuOpen: false,

    // LOADING
    loading: {
        progress: 0,
        completed: false
    },

    index: 0,

    menu: false,

    recaptcha_result: '',

    // start on home by default
    initialRoute: 'home',
    prevRoute: ''
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}