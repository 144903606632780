<template>
    <transition 
        v-on:leave="leave"
        v-on:enter="enter"
        v-bind:css="false"
    > 
        <div id="loading"> </div>
    </transition>
</template>

<script>

import { mapState } from 'vuex' 

import Raf from '@/helpers/Raf'
import Touch from '@/helpers/Touch' 
import MathUtils from '@/helpers/MathUtils'
import ResizeService from '@/services/ResizeService' 

export default {

  data () {
    return {
        assets: {
            progress: 0
        }
    }
  },

  computed: {
    ...mapState('app', [
      'loading',
      'initialRoute'
    ])
  },

  mounted () {},

  methods:{
 
    leave (el, done) {
      done();
    },
 
    enter (el, done) {
      done();
    }

  },

  watch: {
    'loading.completed' (val) {
        // if (val){
        //   this.$router.replace({name: this.initialRoute})
        // }
    }
  } 
} 
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
    @import "src/styles/config/variables.sass";
</style>