// **************************************************
//
// LOADING
//
// **************************************************
export const setLoadProgress = (state, progress) => {
    state.loading.progress = Math.min(progress, 1)
}

export const setLoadComplete = state => {
    state.loading.progress = 1
    state.loading.completed = true
}

export const setInitialRoute = (state, route) => {
    state.initialRoute = route
}

export const setLangSwitcher = (state, val) => {
    state.langSwitcher = val
}

export const setCustomCursor = (state, val) => {
    state.customCursor = val
}

export const setPrevRoute = (state, val) => {
    state.prevRoute = val
}

export const setSignIn = (state, val) => {
    state.signedIn = val

    if(state.signedIn && state.loginPanelOpen){
    	state.loginPanelOpen = false
    }
}

export const setMenuOpen = (state, val) => {
    state.menuOpen = val
}

export const setLoginPanelOpen = (state, val) => {
    state.loginPanelOpen = val
}

export const setAccountType = (state, val) => {
    state.accountType = val
}

export const setIndex = (state, value) => { 
    state.index = value
}

export const setModalState = (state, value) => { 
    state.modal_state = value
}

export const setRecaptchaResult = (state, value) => { 
    state.recaptcha_result = value
}

