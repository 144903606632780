<template>

  <div id="options-panel" scoped>
    <div class="">
      <p class="title">Styling options</p>
      
      <div class="option full-width ">
        <ui-slider
          v-on:change="onStrokeWidthChange"
          v-bind:value="style.stroke.width.current"
          v-bind:min="width_min"
          v-bind:max="width_max"
          v-bind:step="width_step"
          >
          stroke width
        </ui-slider>
      </div>
      <div class="option full-width">
        <ui-slider 
          v-on:change="onStrokeOpacityChange"
          v-bind:value="style.stroke.opacity.current"
          v-bind:min="style.stroke.opacity.min"
          v-bind:max="style.stroke.opacity.max"
          v-bind:step="style.stroke.opacity.step"
          >
          stroke opacity
        </ui-slider>
      </div>
      <div class="option">
        <p class="label">stroke color</p>
        <div class="input">
          <ui-color-swatches v-on:change="onStrokeColorChange" />
        </div>
      </div>
      <div class="option">
        <p class="label">stroke dash</p>
        <div class="input">
          <ui-options
            v-bind:options="style.stroke.dash.options"
            v-bind:defaultOption="style.stroke.dash.default"
            v-on:change="onStrokeDashChange"
            />
        </div>
      </div>
      <div class="option">
        <p class="label">stroke join</p>
        <div class="input">
          <ui-options
            v-bind:options="style.stroke.join.options"
            v-bind:defaultOption="style.stroke.join.default"
            v-on:change="onStrokeJoinChange"
            />
        </div>
      </div>
      <div class="option">
        <p class="label">stroke cap</p>
        <div class="input">
          <ui-options
            v-bind:options="style.stroke.cap.options" 
            v-bind:defaultOption="style.stroke.cap.default"
            v-on:change="onStrokeCapChange"
            />
        </div>
      </div>
      <div class="option">
        <p class="label">hide fill</p>
        <div class="input">
          <ui-btn
            v-bind:initialValue="style.fill"
            v-on:change="onFillChange"
            />
        </div>
      </div>
    </div>

    <div class="section">
      <p class="title">Animation options</p>
      <div class="option full-width"> 
        <ui-slider
          v-on:change="onDelayChange"
          v-bind:value="animation.delay.current"
          v-bind:min="animation.delay.min"
          v-bind:max="animation.delay.max"
          v-bind:step="animation.delay.step"
          >
          delay <span class="small">(ms)</span>
        </ui-slider>
      </div>
      <div class="option full-width">
        <ui-slider
          v-on:change="onDurationChange"
          v-bind:value="animation.duration.current"
          v-bind:min="animation.duration.min"
          v-bind:max="animation.duration.max"
          v-bind:step="animation.duration.step"
          >
          duration <span class="small">(ms)</span>
        </ui-slider>
      </div>
      <div class="option">
        <p class="label">ease</p>
        <div class="input easing">
          <ui-options 
            v-on:change="onEaseChange" 
            v-bind:options="animation.ease.options"
            v-bind:defaultOption="animation.ease.default"
            />
        </div> 
      </div>
      <div class="option">
        <p class="label">draw sequentially</p>
        <div class="input">
          <ui-btn 
            v-bind:initialValue="animation.draw_seq"
            v-on:change="onDrawSeqChange"
            />
        </div>  
      </div>
      <div class="option">
        <p class="label">reverse</p>
        <div class="input">
          <ui-btn
            v-bind:initialValue="animation.reverse"
            v-on:change="onReverseChange"
            />
        </div> 
      </div>
      <div class="option">
        <p class="label">repeat</p>
        <div class="input">
          <ui-number
            v-bind:initialValue="animation.repeat.default"
            v-on:change="onRepeatChange"
            />
        </div> 
      </div>
    </div>
  </div>

</template>

<script>

import Raf from '@/helpers/Raf';
import { mapState, mapMutations } from 'vuex';
import { map  } from '@/helpers/MathUtils';
import ResizeService from '@/services/ResizeService';
import AnalyticsService from '@/services/AnalyticsService';

import UiBtn from '@/components/ui/btn';
import UiSlider from '@/components/ui/slider';
import UiColorSwatches from '@/components/ui/color-swatches';
import UiOptions from '@/components/ui/options';
import UiNumber from '@/components/ui/number-input';

export default {

    name: 'converter-panel',

    data () {
      return {
        width_min: 0,
        width_max: 0,
        width_step: 1
      }
    },

    computed: {

      ...mapState('composer', [
        'composer_state', 
        'COMPOSER_STATES',
        'style',
        'scale',
        'animation'
        ]) 
    },

    components: {
      UiBtn,
      UiSlider,
      UiColorSwatches,
      UiOptions,
      UiNumber
    },

    mounted () {
      this.setupGA();
    },

    methods: {

      ...mapMutations('composer', [

        // styling
        'setFillOpacity',
        'setStrokeWidth',
        'setStrokeOpacity',
        'setStrokeColor',
        'setStrokeDash',
        'setStrokeCap',
        'setStrokeJoin',

        // animation
        'setDelay',
        'setReverse',
        'setEase',
        'setDrawSeq',
        'setDuration',
        'setRepeat'

      ]),

      setupGA() {

        let debounce = (callback, wait, context = this) => {

          let timeout = null 
          let callbackArgs = null
          const later = () => callback.apply(context, callbackArgs)
          
          return function() {
            callbackArgs = arguments
            clearTimeout(timeout)
            timeout = setTimeout(later, wait)
          }
        } 

        this.debouncedStrokeOpacity = debounce((value) => {
          AnalyticsService.sendEvent('COMPOSER', 'change', 'stroke-opacity', value);
        }, 200) 
        this.debouncedStrokeWidth = debounce((value)=>{
          AnalyticsService.sendEvent('COMPOSER', 'change', 'stroke-width', value);
        }, 200)

        this.debouncedDelay = debounce((value)=>{
          AnalyticsService.sendEvent('COMPOSER', 'change', 'delay', value);
        }, 200)
        this.debouncedDuration = debounce((value) => {
          AnalyticsService.sendEvent('COMPOSER', 'change', 'duration', value);
        }, 200) 
      },

      updateWidth(val){

        this.width_min = (this.style.stroke.width.min * val) 
        this.width_max = (this.style.stroke.width.max * val) 
        this.width_step = (this.width_max * 0.01)
       
        console.log('step - - - - - - - ' , this.width_step)
        console.log('min - - - - - - - ' , this.width_min)
        console.log('max - - - - - - - ' , this.width_max)
      },
      
      idle(){
        this.$el.style.display = 'none';
      },

      loading(){},

      edit(){ 
        this.updateWidth(this.scale)
        this.$el.style.display = 'block';
      },

      onFillChange(value){  
        this.setFillOpacity(value);
        AnalyticsService.sendEvent('COMPOSER', 'change', 'fill-opacity', value);
      },

      onStrokeWidthChange(value){ 
        this.setStrokeWidth(value);
        this.debouncedStrokeWidth(value);
      },

      onStrokeOpacityChange(value){
        this.setStrokeOpacity(value);
        this.debouncedStrokeOpacity(value);
      },

      onStrokeColorChange(value){
        this.setStrokeColor(value);
        AnalyticsService.sendEvent('COMPOSER', 'change', 'stroke-color', value);
      },

      onStrokeDashChange(value){
        this.setStrokeDash(value);
        AnalyticsService.sendEvent('COMPOSER', 'change', 'stroke-dash', value);
      },

      onStrokeCapChange(value){
        this.setStrokeCap(value);
        AnalyticsService.sendEvent('COMPOSER', 'change', 'stroke-cap', value);
      },

      onStrokeJoinChange(value){
        this.setStrokeJoin(value);
        AnalyticsService.sendEvent('COMPOSER', 'change', 'stroke-join', value);
      },


      onDelayChange(value){
        this.setDelay(value);
        this.debouncedDelay(value);
      },

      onReverseChange(value){
        this.setReverse(value);
        AnalyticsService.sendEvent('COMPOSER', 'change', 'reverse', value);
      },

      onEaseChange(value){
        this.setEase(value);
        AnalyticsService.sendEvent('COMPOSER', 'change', 'ease', value);
      },

      onDrawSeqChange(value){
        this.setDrawSeq(value);
        AnalyticsService.sendEvent('COMPOSER', 'change', 'drawSeq', value);
      },

      onDurationChange(value){
        this.setDuration(value);
        this.debouncedDuration(value);
      },

      onRepeatChange(value){
        this.setRepeat(value);
      }

    },

    watch: {

      scale(val){
        this.updateWidth(val)
      },

      composer_state(val){
        switch(val) {
          case this.COMPOSER_STATES.IDLE:
            this.idle();
            break;
          case this.COMPOSER_STATES.LOADING:
            this.loading();
            break; 
          case this.COMPOSER_STATES.EDIT:
            this.edit();
            break; 
        }
      }
    } 
} 
</script>


<style lang="sass">

  #options-panel

    position: relative
    width: 340px
    text-align: left
    display: none
    padding: 0 3rem

    p

      &.title
        font-size: 2.2rem
        color: #601b43
        font-family: 'melbournebold';
        margin-bottom: 1rem

    .section
      margin-top: 3rem

    .option
      display: flex
      margin-bottom: 0.5rem
      font-style: italic

      &.full-width
        display: block
        margin-bottom: 0.5rem

      &.color-swatches
        margin-bottom: 3rem

        .label
          width: 80%

    .easing > div
      width: 80%


    .label
      font-size: 1.8rem
      width: 50%
      display: flex
      align-items: center

    .input
      width: 50%
      display: flex
      justify-content: flex-end


</style> 