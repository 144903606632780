export const next = ({
    commit,
    state
}, value) => {
    commit('setLayer', value)
}


export const prev = ({
    commit,
    state
}, value) => {
    commit('setLayer', value)
}