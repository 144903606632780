<template>

  <div id="timeline-playhead">
    <div class="triange"></div>
    <div class="needle"></div>
    <div class="head">{{getDuration}}</div>
  </div>

</template>

<script>

import Raf from '@/helpers/Raf'
import Touch from '@/helpers/Touch'
import {clamp} from '@/helpers/MathUtils'
import { mapState, mapMutations } from 'vuex';
import ResizeService from '@/services/ResizeService';

export default {

    name: 'timeline',

    data () {
      return {
        value: 0,
        touch: null,
        deltaX: 0
      }
    },

    computed: {
      ...mapState('composer', [
        'timeline'
      ]),

      getDuration(){
        return +((this.timeline.duration * this.timeline.progress) * 0.001).toFixed(2);
      }
    },

    components: {},

    mounted () {
      Raf.add(this.onTick)
      this.touch = new Touch(this.$el);
      this.touch.on(Touch.Events.MOVE, this.onMove);
    },

    methods:{

      ...mapMutations('composer', [
        'setLazylineProgress'
      ]),

      setup() { },

      enter(){ },
      
      leave(){},

      drag(){
 
        if(this.touch.touching && this.touch.axis.x){
          if(
            this.touch.direction.left && this.value > 0 || 
            this.touch.direction.right && this.value < (this.timeline.width) 
            ){
            this.value -= this.deltaX;

            this.value = clamp(this.value, 0, this.timeline.width)

            this.setLazylineProgress(this.value / this.timeline.width)
          }
        }
      },

      updatePosition(){
        if(!this.touch.touching){
          let value = ((this.timeline.width - 1) * this.timeline.progress);
          //this.value += (value - this.value) * 0.1;
          this.value = value;
          this.value = clamp(this.value, 0, this.timeline.width)
        }
      },
 
      onTick(){
        this.drag()
        this.updatePosition()
        this.$el.style.transform = 'translate3d(' + this.value + 'px,0,0)' 
        this.deltaX = 0
      },

      onMove(){
        this.deltaX = this.touch.delta.x;
      }
    
    },

    watch: {
      'timeline.width'(){
        this.updatePosition();
      }
    } 
} 
</script>


<style scoped lang="sass">

  #timeline-playhead
    position: absolute
    width: 1px 
    background-color: #ff0902
    top: 2rem
    height: calc(100% - 2rem)
    cursor: pointer

    &:hover
      top: 2.5rem
      height: calc(100% - 2.5rem)
      background-color: #cc2d8a

      .head
        top: -3.5rem
        position: absolute
        color: rgba(256,256,256, 1)
        padding: 0.7rem 0.75rem
        background-color: #cc2d8a
        height: auto
        border-radius: 5px

      .triange
        border-top: 9px solid #cc2d8a
        top: -5px

    .triange
      width: 0
      height: 0
      left: -8px
      top: 0px
      position: absolute
      border-left: 8px solid transparent
      border-right: 8px solid transparent
      border-top: 9px solid #f00



    .needle
      position: absolute
      width: 3rem
      height: 100%
      transform: translateX(-50%)


    .head
      height: 0
      transform: translateX(-50%)
      top: -2.5rem
      position: absolute
      color: rgba(256,256,256, 0)
      letter-spacing: 1px
      font-size: 1.6rem
      transition: color 0.3s ease-out




</style> 