import { map  } from '@/helpers/MathUtils';

export const setState = (state, value) => {
  state.composer_state = value
}

export const setScale = (state, value) => {
  state.scale = value
}

export const setLazylineProgress = (state, value) => {
  state.animation.progress = value;
  setTimelineProgress(state, value)
}

export const setDownloadCountdown = (state, value) => {
  state.download_countdown = value; 
}


export const setTimelineProgress = (state, value) => {
  state.timeline.progress = value;
}

export const setTimelineComplete = (state, value) => {
  state.timeline.progress = 1;
  state.timeline.complete = value; 
}

export const setTimelineWidth = (state, value) => {
  state.timeline.width = value; 
}

export const setTimelinePaths = (state, value) => {
  state.timeline.paths = value; 
}

export const setTimelineDuration = (state, value) => {
  state.timeline.duration = value; 
}

export const setTimelineEase = (state, value) => {
  state.timeline.ease = value; 
}

export const setTl = (state, value) => {
  state.timeline.timings = value; 
}



// styling

export const setFillOpacity = (state, value) => {
  state.style.fill = value; 
}

export const setStrokeOpacity = (state, value) => {
  let roundedVal = Math.round(value * 10) / 10; 
  state.style.stroke.opacity.current = roundedVal; 
}

export const setStrokeWidth = (state, value) => {
  let min = state.style.stroke.width.min;
  let max = state.style.stroke.width.max; 
  let roundedVal = Math.round(value * 10) / 10; 
  state.style.stroke.width.current = roundedVal; 
}

export const setStrokeColor = (state, value) => {
  state.style.stroke.color = value; 
}

export const setStrokeDash = (state, value) => {
  state.style.stroke.dash.current = value; 
}

export const setStrokeCap = (state, value) => {
  state.style.stroke.cap.current = value; 
}

export const setStrokeJoin = (state, value) => {
  state.style.stroke.join.current = value; 
}


// animation

export const setDelay = (state, value) => {
  let roundedVal = Math.round(value); 
  state.animation.delay.current = roundedVal; 
}

export const setReverse = (state, value) => {
  state.animation.reverse = value; 
}

export const setEase = (state, value) => {
  state.animation.ease.current = value; 
}

export const setDrawSeq = (state, value) => {
  state.animation.draw_seq = value; 
}

export const setDuration = (state, value) => {
  state.animation.duration.current = value; 
}

export const setRepeat = (state, value) => {
  state.animation.repeat.current = value; 
}

