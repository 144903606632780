import { clamp } from '@/helpers/MathUtils'
import EventEmitter from '@/helpers/EventEmitter'

const Events = {
    RESIZE: 'resize',
}

let defaultConfig = {
    scale: 1,
    baseFontSize: 10,
    breakpoints: [
        ['mobile', 580],
        ['tablet', 768],
        ['desktop', 1280]
    ],
}

class ResizeService {
    constructor(config) {
        Object.assign(this, EventEmitter)

        this.config = Object.assign(defaultConfig, config)
        this.orientation = 'portrait'
        this.device = this.deviceType()
        this.html = document.querySelector('html')
        this.timers = [null, null, null]

        this.alwaysTrigger = true // always trigger resize event even if dimensions haven't changed
        this.onlyWidth = false // only monitor width changes

        this.isFacebook = /(fban|fbav)/i.test(navigator.userAgent.toLowerCase())
        this.isInstagram = /instagram/.test(navigator.userAgent.toLowerCase())
        this.isIOS = /(iOS|iPod|iPad|iPhone)/i.test(navigator.userAgent)
        this.isAndroid = /Android/.test(navigator.userAgent)
        this.isWechat = /micromessenger/i.test(navigator.userAgent)
        this.isWeibo = /weibo/.test(navigator.userAgent)
        this.isUCBrowser = /UCBrowser/.test(navigator.userAgent)

        this._onResize()
        this._setDeviceClasses()

        window.addEventListener('orientationchange', this._update, false)
        window.addEventListener('resize', this._onResize)
    }

    destroy() {
        window.removeEventListener('resize', this._onResize)
    }

    setBreakpoints = (config) => {
        if (
            config.length > 0 &&
            typeof config[0][0] === 'string' &&
            typeof config[0][1] === 'number'
        ) {
            this.config.breakpoints = config
            this._onResize()
        } else console.log('Cannot add breakpoints, invalid format.')
    }

    setBaseFontSize = (val) => {
        this.config.baseFontSize = val
        this._onResize()
    }

    coverScale = (options) => {
        let config = Object.assign({
                width: 0,
                height: 0,
                type: '',
                innerWidth: this.width,
                innerHeight: this.height,
            },
            options
        )

        let scaleW = config.innerWidth / config.width
        let scaleH = config.innerHeight / config.height
        let scale = Math.max(scaleW, scaleH)
        if (config.type === 'width') scale = scaleW
        if (config.type === 'height') scale = scaleH
        let width = config.width * scale
        let height = config.height * scale
        let x =
            Math.floor(width) === Math.floor(config.width) ?
            0 :
            (width - config.width) / 2
        let y =
            Math.floor(height) === Math.floor(config.height) ?
            0 :
            (height - config.height) / 2

        return {
            width: width,
            height: height,
            x: x,
            y: y,
            scale: scale,
        }
    }

    containScale = (options) => {
        let config = Object.assign({
                width: 0,
                height: 0,
                innerWidth: this.width,
                innerHeight: this.height,
            },
            options
        )

        let scaleW = config.innerWidth / config.width
        let scaleH = config.innerHeight / config.height
        let scale = Math.min(scaleW, scaleH)
        let width = config.width * scale
        let height = config.height * scale

        return {
            width: width,
            height: height,
            scale: scale,
        }
    }

    remScaled = (options) => {
        let config = Object.assign({
                width: 0,
                height: 0,
            },
            options
        )

        return {
            width: config.width * this.scale,
            height: config.height * this.scale,
        }
    } 

    _resetTimer = (id, time) => {
        clearTimeout(this.timers[id])
        this.timers[id] = setTimeout(this._resize, time)
    }

    _onResize = () => {
        this._resize()

        if (this.device === 'mobile') {
            this._resetTimer(0, 200)
            this._resetTimer(1, 500)
            this._resetTimer(2, 1000)
        }
    }

    _resize = () => {
        let wW = window.innerWidth
        let wH = window.innerHeight

        // only continue if things changed
        if (wW === this.width && (wH === this.height || this.onlyWidth)) {
            if (this.alwaysTrigger) {
                this.emit(Events.RESIZE, {
                    width: this.width,
                    height: this.height,
                    device: this.device,
                    orientation: this.orientation,
                })
            }
            return
        }

        this.width = wW
        this.height = wH
        this.device = this.deviceType()

        this._updateFontSize()
        this._setScaleClass()
        this._setScreenOrientationClass()

        this.emit(Events.RESIZE, {
            width: this.width,
            height: this.height,
            device: this.device,
            orientation: this.orientation,
        })
    }

    deviceType = (detail) => {
        let device = {
            type: this.config.breakpoints[0][0],
            width: this.config.breakpoints[0][1],
        }
        for (let b of this.config.breakpoints) {
            let type = b[0]
            let width = b[1]
            if (this.width > width) {
                device = {
                    type: type,
                    width: width,
                }
            }
        }
        if (detail) return device
        else return device.type
    }

    _updateFontSize = () => {
        const device = this.deviceType(true)
        const dW = device.width
        let maxScale = 1; //device.type === 'desktop' ? 1 : 2
        this.scale = clamp(this.width / dW, 0.55, maxScale)
        this.fontSize = this.config.baseFontSize * this.scale
        this.html.style.fontSize = `${this.fontSize}px`
    }

    _setScaleClass = () => {
        let device = this.deviceType()
        for (let b of this.config.breakpoints) {
            let type = b[0]
            if (type === device) this.html.classList.add(type)
            else this.html.classList.remove(type)
        }
    }

    _setScreenOrientationClass = () => {
        if (this.width > this.height) {
            this.html.classList.add('landscape')
            this.html.classList.remove('portrait')
            this.orientation = 'landscape'
        } else {
            this.html.classList.add('portrait')
            this.html.classList.remove('landscape')
            this.orientation = 'portrait'
        }
    }

    _setDeviceClasses = () => {
        if (this.isFacebook) this.html.classList.add('facebook')
        if (this.isInstagram) this.html.classList.add('instagram')
        if (this.isIOS) this.html.classList.add('ios')
        if (this.isAndroid) this.html.classList.add('android')
        if (this.isWechat) this.html.classList.add('wechat')
        if (this.isWeibo) this.html.classList.add('weibo')
        if (this.isUCBrowser) this.html.classList.add('ucbrowser')
    }
}

let service = new ResizeService()
service.Events = Events
window.ResizeService = service
export default service