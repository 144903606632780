<template>

  <section id="blurb">

    <p class="intro">
      Lazy Line Painter can be setup with minimal effort as per these Quick Start instructions.
      And with the initialisation config and playback options anything is possible.
      <br><br>
      <span class="bold">
        However, to achieve the most interesting results the library is best used in conjunction with the Lazy Line Composer below.
        The Lazy Line Composer is a GUI specifically developed for SVG path animation.
      </span>
    </p>
  
  </section> 

</template>

<script>

import Raf from '@/helpers/Raf'
import { mapState } from 'vuex';
import ResizeService from '@/services/ResizeService';  


export default {

    name: 'blurb',

    data () {
      return { }
    },

    computed: {},

    components: {},

    mounted () { 
    },

    methods:{

      setup() { 
      },

      enter(){ 
      },
      
      leave(){},

      onClick() {}
    
    },

    watch: {} 
} 
</script>


<style scoped lang="sass">

  #blurb
    background: #b5287b;
    padding: 45rem 3rem 10rem;
    color: #601b43;

    .bold
      font-weight: 800;
      letter-spacing: 0.1rem;
      color: #f7f3ed;

    .intro
      max-width: 72rem;
      margin: 0 auto;
      line-height: 1.6;
      font-size: 2.2rem;
      text-transform: initial;

      @media only screen and (max-width : 600px)
        font-size: 2.8rem;

</style> 