<template>

  <section id="timeline" v-if="canShowTimeline"> 
    <div ref="inner" class="inner"> 
      <time-scale />
      <timeline-bars 
        v-on:mouseenter="onMouseEnter"
        v-on:mouseleave="onMouseLeave"
        />
      <playhead />
    </div>
  </section> 

</template>

<script>

import Raf from '@/helpers/Raf'
import { mapState, mapMutations } from 'vuex';
import ResizeService from '@/services/ResizeService';
import TimeScale from '@/components/composer/timeline/timescale';
import TimelineBars from '@/components/composer/timeline/bars';
import Playhead from '@/components/composer/timeline/playhead';

export default {

    name: 'timeline',

    data () {
      return { }
    },

    computed: {

      ...mapState('composer', [
        'timeline',
        'composer_state',
        'COMPOSER_STATES'
      ]),

      canShowTimeline() {
        return (this.composer_state === this.COMPOSER_STATES.EDIT)
      }
    },

    components: {
      TimeScale,
      TimelineBars,
      Playhead
    },

    mounted () {
      this.setup();
    },

    methods:{

      ...mapMutations('composer', [
        'setTimelineWidth'
      ]),

      setup() {
      },

      onResize() {
        this.setTimelineWidth(this.$refs.inner.offsetWidth);
      },

      onMouseEnter(id){
        console.log(id)
      },
      
      onMouseLeave(id){
        console.log(id)
      }
    
    },

    watch: {
      composer_state(val){
        if(val === this.COMPOSER_STATES.EDIT){ 
          setTimeout(this.onResize, 500)
          window.addEventListener('resize', this.onResize, false)
        } else { 
          window.removeEventListener('resize', this.onResize, false)
        }
      }
    } 
} 
</script>


<style scoped lang="sass">

  #timeline
    color: #601b43
    background-color: #f7f3ed
    display: flex
    padding: 0
    justify-content: center

    .inner
      width: calc(100% - 2rem)
      position: relative
      padding: 0
      margin: 0
      display: block
 

</style> 