<template>

  <section id="how-it-works">
 
    <header>
        <h1 class='mask'>HOW IT WORKS</h1>
    </header>
 
    <p>
        Follow these three easy steps to start animating svg paths.
    </p>

    <div class="steps">

      <div class="inner">

        <div class="step-one fixedWidth">
            <div class="how-to-img">
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                     viewBox="0 0 266 184" enable-background="new 0 0 266 184" xml:space="preserve">
                <g>
                    <defs>
                        <path id="SVGID_1_" d="M258.2,183.4H7.8c-3.9,0-7.1-3.2-7.1-7.1V7.6c0-3.9,3.2-7.1,7.1-7.1h250.5c3.9,0,7.1,3.2,7.1,7.1v168.7
                            C265.3,180.2,262.2,183.4,258.2,183.4z"/>
                    </defs>
                    <clipPath id="SVGID_2_">
                        <use xlink:href="#SVGID_1_"  overflow="visible"/>
                    </clipPath>
                    <rect x="-13.1" y="-4" clip-path="url(#SVGID_2_)" fill="#FFFFFF" width="301.7" height="195.3"/>
                    <rect x="-15.8" y="-4" clip-path="url(#SVGID_2_)" fill="#FF9D27" width="288.2" height="33"/>
                </g>
                <rect x="78.4" y="60.3" opacity="0.15" width="117.2" height="97.7"/>
                <rect x="72.7" y="53.5" fill="#FFFFFF" stroke="#B7B7B7" stroke-miterlimit="10" width="117.2" height="97.7"/>
                <path fill="none" stroke="#42303C" stroke-width="3.685" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="435,435" d="
                    M78.3,62c0,0,3.2,7.5,10.4,7.5c7.2,0,10.5-7.5,10.5-7.5s2.9,7.5,10.4,7.5c7.6,0,10.4-7.5,10.4-7.5s3.4,7.5,10.4,7.5
                    c7.1,0,10.4-7.5,10.4-7.5s3,7.5,10.5,7.5c7.4,0,10.4-7.5,10.4-7.5s4.3,7.5,10.5,7.5c6.1,0,10.5-7.5,10.5-7.5"/>
                <path fill="none" stroke="#42303C" stroke-width="3.685" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="117,117" d="
                    M113.4,103.7c0,0-2.7-10.7-13.2-10.7c-9,0-13.2,10.7-13.2,10.7"/>
                <path fill="none" stroke="#42303C" stroke-width="3.685" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="109,109" d="
                    M173.7,103.1c0,0-3.8-10-12.4-10c-8.6,0-12.4,10-12.4,10"/>
                <path fill="none" stroke="#42303C" stroke-width="3.685" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="257,257" d="
                    M101,120.7c0,0,6.7,23.2,29,23.2c23.7,0,29-23.2,29-23.2"/>
                </svg>

            </div>
            <p >
                Export your line art from Illustrator as an .SVG <br/>
                <span>Ensure there are no fills,<br/>
                No closed paths - Line needs a start and end, <br/>
                Crop Artboard nice & tight!</span>
            </p>
          </div>

                  <div class="spacer">
                      <div class="how-to-arrow"></div>
                      <div class="content">
                           <div class="file"><p>.SVG</p></div>
                      </div>
                  </div>  

                  <div class="step-two fixedWidth">
                      <div class="how-to-img center">
                          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                               viewBox="0 0 266 207.7" enable-background="new 0 0 266 207.7" xml:space="preserve">
                              <g>
                                  <path fill="#845a5a" d="M249.9,207.2h-234c-8.8,0-15.9-7.1-15.9-15.9V39.7c0-8.8,7.1-15.9,15.9-15.9h234c8.8,0,15.9,7.1,15.9,15.9
                                      v151.5C265.8,200.1,258.7,207.2,249.9,207.2z"/>
                                  <path fill="#C75D5D" d="M242,160.5H22.5c-1.9,0-3.5-1.6-3.5-3.5V47.7c0-1.9,1.6-3.5,3.5-3.5H242c1.9,0,3.5,1.6,3.5,3.5V157
                                      C245.5,158.9,243.9,160.5,242,160.5z"/>
                                  <ellipse opacity="0.3" fill="#C75D5D" cx="132.2" cy="179.2" rx="7.5" ry="7.5"/>
                              </g>
                              <g class="drop">
                                  <path opacity="0.1" d="M157,39.9c0,4.6,0,10.2,0,10.2h26.7l-52,60.3l-52-60.3h27.7c0,0-0.1-4.8-0.1-10.8
                                      C107.2,8.7,157,9.8,157,39.9z"/>
                                  <path fill="#FFDBD7" d="M157,23.4c0,4.6,0,10.3,0,10.3h26.7l-52,60.3l-52-60.3h27.7c0,0-0.1-4.8-0.1-10.8
                                      C107.2-7.8,157-6.7,157,23.4z"/>
                                  <g>
                                      
                                          <path fill="none" stroke="#5B4646" stroke-width="1.4173" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="435,435" d="
                                          M114,19.4c0,0,1.1,2.6,3.7,2.6c2.5,0,3.7-2.6,3.7-2.6s1,2.6,3.7,2.6c2.7,0,3.7-2.6,3.7-2.6s1.2,2.6,3.7,2.6c2.5,0,3.7-2.6,3.7-2.6
                                          s1.1,2.6,3.7,2.6c2.6,0,3.7-2.6,3.7-2.6s1.5,2.6,3.7,2.6c2.2,0,3.7-2.6,3.7-2.6"/>
                                      
                                          <path fill="none" stroke="#5B4646" stroke-width="1.4173" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="117,117" d="
                                          M126.3,34c0,0-1-3.8-4.7-3.8c-3.1,0-4.7,3.8-4.7,3.8"/>
                                      
                                          <path fill="none" stroke="#5B4646" stroke-width="1.4173" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="109,109" d="
                                          M147.5,33.8c0,0-1.3-3.5-4.4-3.5c-3,0-4.4,3.5-4.4,3.5"/>
                                      
                                          <path fill="none" stroke="#5B4646" stroke-width="1.4173" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="257,257" d="
                                          M121.9,40c0,0,2.3,8.2,10.2,8.2c8.3,0,10.2-8.2,10.2-8.2"/>
                                  </g>
                              </g>
                          </svg>
                      </div>
                    <p>
                      Drop your .SVG file <br />
                      into the 'Lazy Line Composer'.<br /> 
                    <span>Each paths duration can be tweaked in the code!<br/>
                    </span>
                      </p> 
                  </div>


                  <div class="spacer">
                      <div class="how-to-arrow"></div>
                      <div class="content">
                          <div class="file"> <p>.ZIP</p></div>
                      </div>
                  </div> 

                  <div class="step-three fixedWidth">

                      <div class="how-to-img">  
                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                           viewBox="0 0 266 184" enable-background="new 0 0 266 184" xml:space="preserve">
                        <g>
                          <g>
                            <defs>
                              <path id="SVGID_1_" d="M0.7,7.6c0-3.9,3.2-7.1,7.1-7.1h250.5c3.9,0,7.1,3.2,7.1,7.1v168.7c-0.1,3.9-3.2,7.1-7.2,7.1H7.8
                                c-3.9,0-7.1-3.2-7.1-7.1"/>
                            </defs>
                            <clipPath id="SVGID_2_">
                              <use xlink:href="#SVGID_1_"  overflow="visible"/>
                            </clipPath>
                            <rect x="-13.1" y="-4" clip-path="url(#SVGID_2_)" fill="#545B66" width="301.7" height="195.3"/>
                          </g>
                          <g>
                            <defs>
                              <path id="SVGID_3_" d="M0.7,7.6c0-3.9,3.2-7.1,7.1-7.1h250.5c3.9,0,7.1,3.2,7.1,7.1v168.7c-0.1,3.9-3.2,7.1-7.2,7.1H7.8
                                c-3.9,0-7.1-3.2-7.1-7.1"/>
                            </defs>
                            <clipPath id="SVGID_4_">
                              <use xlink:href="#SVGID_3_"  overflow="visible"/>
                            </clipPath>
                            <rect x="-15.8" y="-4" clip-path="url(#SVGID_4_)" fill="#2D323A" width="288.2" height="33"/>
                          </g>
                        </g>
                        <g>
                          <g>
                            <path fill="#D292FF" d="M27.5,80.2v-8.1c0-0.1,0-0.2,0.1-0.3s0.2-0.1,0.3-0.1h0.6c0.1,0,0.2,0,0.3,0.1s0.1,0.2,0.1,0.3v8.1
                              c0,0.2,0,0.3,0,0.4S29,80.8,29,81c0.1,0.1,0.2,0.2,0.3,0.3c0.2,0.1,0.3,0.1,0.6,0.1h0.3c0.1,0,0.2,0,0.3,0.1s0.1,0.2,0.1,0.3v0.4
                              c0,0.1,0,0.2-0.1,0.3s-0.2,0.1-0.3,0.1h-0.3c-0.8,0-1.5-0.2-1.9-0.7C27.7,81.6,27.5,81,27.5,80.2z"/>
                            <path fill="#D292FF" d="M31.7,80.1v-2.8c0-0.8,0.2-1.5,0.7-2c0.5-0.5,1.1-0.8,2-0.8c0.7,0,1.3,0.2,1.8,0.6s0.7,1,0.7,1.6v1.2
                              c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.3,0.3-0.6,0.3L33,79.4V80c0,0.3,0.1,0.6,0.3,0.9c0.2,0.2,0.5,0.4,0.9,0.4s0.7-0.1,0.9-0.3
                              c0.2-0.2,0.3-0.5,0.3-0.8c0-0.1,0-0.2,0.1-0.3s0.2-0.1,0.2-0.1h0.1h0.5c0.3,0,0.5,0.2,0.5,0.5c0,0.7-0.2,1.3-0.7,1.8
                              s-1.1,0.7-1.9,0.7c-0.8,0-1.4-0.2-1.9-0.7C31.8,81.6,31.7,80.9,31.7,80.1z M33.1,78.2l2.4-0.4V77c0-0.3-0.1-0.5-0.3-0.7
                              c-0.2-0.2-0.5-0.2-0.8-0.2c-0.4,0-0.7,0.1-0.9,0.4c-0.2,0.2-0.3,0.6-0.3,0.9v0.8H33.1z"/>
                            <path fill="#D292FF" d="M38.6,75.8v-0.4l0,0v-0.1c0,0,0-0.1,0.1-0.1l0.1-0.1c0.1,0,0.1,0,0.2,0h0.3v-2.5c0-0.1,0-0.2,0.1-0.3
                              s0.1-0.1,0.2-0.1h0.1h0.5l0,0h0.1c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.1c0,0.1,0,0.1,0,0.2v2.5h1.2c0.1,0,0.2,0,0.3,0.1
                              s0.1,0.1,0.1,0.2v0.1v0.4l0,0V76c0,0,0,0.1-0.1,0.1s-0.1,0.1-0.1,0.1c-0.1,0-0.1,0-0.2,0h-1.2v3.9c0,0.4,0.1,0.7,0.3,0.9
                              c0.2,0.2,0.5,0.3,0.8,0.3h0.3c0.1,0,0.2,0,0.3,0.1s0.1,0.1,0.1,0.2v0.1v0.4l0,0v0.1c0,0,0,0.1-0.1,0.1l-0.1,0.1
                              c-0.1,0-0.1,0-0.2,0h-0.3c-0.8,0-1.5-0.2-1.9-0.6c-0.4-0.4-0.6-1-0.6-1.9V76h-0.3c-0.1,0-0.2,0-0.3-0.1s-0.1-0.1-0.1-0.2
                              L38.6,75.8z"/>
                            <path fill="#D4E4F4" d="M47.5,75.8v-0.5c0-0.1,0-0.2,0.1-0.3s0.2-0.1,0.3-0.1h0.3v-0.8c0-0.8,0.2-1.4,0.6-1.8
                              c0.4-0.4,1-0.7,1.9-0.7H51c0.1,0,0.2,0,0.3,0.1s0.1,0.2,0.1,0.3v0.4c0,0.1,0,0.2-0.1,0.3s-0.2,0.1-0.3,0.1h-0.3
                              c-0.2,0-0.4,0-0.6,0.1s-0.3,0.2-0.3,0.3c-0.1,0.1-0.1,0.2-0.1,0.4c0,0.1,0,0.3,0,0.4v0.8H51c0.1,0,0.2,0,0.3,0.1s0.1,0.2,0.1,0.3
                              v0.4c0,0.1,0,0.2-0.1,0.3S51.1,76,51,76h-1.3v5.9c0,0.1,0,0.2-0.1,0.3s-0.2,0.1-0.3,0.1h-0.6c-0.1,0-0.2,0-0.3-0.1
                              s-0.1-0.2-0.1-0.3V76H48c-0.1,0-0.2,0-0.3-0.1C47.5,76,47.5,75.9,47.5,75.8z"/>
                            <path fill="#D4E4F4" d="M52.1,80.4c0-0.6,0.1-1.1,0.4-1.5c0.3-0.3,0.8-0.6,1.5-0.8l1.8-0.5v-0.7c0-0.3-0.1-0.6-0.3-0.8
                              c-0.2-0.2-0.5-0.3-0.8-0.3s-0.6,0.1-0.8,0.2c-0.2,0.1-0.3,0.3-0.3,0.6c0,0.1,0,0.2-0.1,0.3S53.3,77,53.2,77h-0.6
                              c-0.1,0-0.2,0-0.3-0.1s-0.1-0.2-0.1-0.3c0-0.6,0.2-1.2,0.7-1.5c0.5-0.4,1.1-0.6,1.8-0.6c0.3,0,0.7,0.1,1,0.2s0.6,0.3,0.8,0.5
                              c0.2,0.2,0.4,0.5,0.6,0.9c0.1,0.4,0.2,0.8,0.2,1.2V82c0,0.1,0,0.2-0.1,0.3s-0.2,0.1-0.3,0.1h-0.5c-0.1,0-0.2,0-0.3-0.1
                              S56,82.1,56,82v-0.1c-0.1,0.2-0.4,0.4-0.6,0.5s-0.6,0.2-0.9,0.2c-0.6,0-1.2-0.2-1.6-0.7C52.4,81.6,52.1,81.1,52.1,80.4z
                               M53.6,80.3c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.4,0.3,0.7,0.3c0.4,0,0.7-0.2,1-0.5c0.3-0.3,0.4-0.7,0.4-1V79l-1.6,0.4
                              C53.8,79.6,53.6,79.9,53.6,80.3z"/>
                            <path fill="#D4E4F4" d="M59.2,79.9v-2.3c0-0.5,0.1-1,0.2-1.4c0.2-0.4,0.4-0.7,0.6-0.9c0.2-0.2,0.5-0.4,0.8-0.5s0.6-0.2,0.9-0.2
                              s0.5,0,0.8,0.1c0.2,0.1,0.5,0.2,0.7,0.3s0.4,0.3,0.6,0.6c0.2,0.3,0.3,0.5,0.4,0.9c0,0.1,0,0.1,0,0.2s0,0.1-0.1,0.2
                              C64,77,64,77,63.8,77l-0.5,0.1c-0.1,0-0.1,0-0.2,0c-0.2,0-0.3-0.1-0.4-0.4c-0.1-0.5-0.5-0.8-1.1-0.8c-0.4,0-0.7,0.1-0.9,0.4
                              c-0.2,0.3-0.3,0.7-0.3,1.2v2.3c0,1,0.4,1.5,1.2,1.5c0.4,0,0.7-0.1,0.9-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.1,0-0.2,0.1-0.3
                              s0.1-0.1,0.2-0.1h0.1h0.5c0.3,0,0.5,0.2,0.5,0.5c0,0.4-0.1,0.8-0.3,1.2c-0.2,0.3-0.4,0.6-0.7,0.8c-0.3,0.2-0.5,0.3-0.8,0.4
                              c-0.3,0.1-0.6,0.1-0.9,0.1c-0.9,0-1.5-0.2-2-0.7C59.5,81.5,59.2,80.8,59.2,79.9z"/>
                            <path fill="#D4E4F4" d="M66.4,80.1v-2.8c0-0.8,0.2-1.5,0.7-2s1.1-0.8,2-0.8c0.7,0,1.3,0.2,1.8,0.6s0.7,1,0.7,1.6v1.2
                              c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.3,0.3-0.6,0.3l-3.1,0.6V80c0,0.3,0.1,0.6,0.3,0.9c0.2,0.2,0.5,0.4,0.9,0.4s0.7-0.1,0.9-0.3
                              c0.2-0.2,0.3-0.5,0.3-0.8c0-0.1,0-0.2,0.1-0.3s0.2-0.1,0.2-0.1h0.1H71c0.3,0,0.5,0.2,0.5,0.5c0,0.7-0.2,1.3-0.7,1.8
                              s-1.1,0.7-1.9,0.7s-1.4-0.2-1.9-0.7S66.4,80.9,66.4,80.1z M67.8,78.2l2.4-0.4V77c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.5-0.2-0.8-0.2
                              c-0.4,0-0.7,0.1-0.9,0.4c-0.2,0.2-0.3,0.6-0.3,0.9L67.8,78.2L67.8,78.2z"/>
                            <path fill="#D4E4F4" d="M77,77.3v-0.5c0-0.1,0-0.2,0.1-0.3s0.2-0.1,0.3-0.1h5.2c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3v0.5
                              c0,0.1,0,0.2-0.1,0.3s-0.2,0.1-0.3,0.1h-5.2c-0.1,0-0.2,0-0.3-0.1C77,77.5,77,77.4,77,77.3z M77,79.9v-0.4c0-0.1,0-0.2,0.1-0.3
                              s0.2-0.1,0.3-0.1h5.2c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3v0.4c0,0.1,0,0.2-0.1,0.3s-0.2,0.1-0.3,0.1h-5.2
                              c-0.1,0-0.2,0-0.3-0.1C77,80.2,77,80.1,77,79.9z"/>
                            <path fill="#81C5FC" d="M88.2,82.1v-6.9c0-0.1,0-0.2,0.1-0.3s0.2-0.1,0.3-0.1h0.6c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3
                              v0.1c0.1-0.1,0.3-0.3,0.5-0.4c0.3-0.1,0.6-0.2,0.9-0.2c0.7,0,1.3,0.2,1.7,0.7s0.6,1.1,0.6,2v4.7c0,0.1,0,0.2-0.1,0.3
                              s-0.2,0.1-0.3,0.1h-0.6c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.3v-4.8c0-0.9-0.4-1.3-1.1-1.3c-0.4,0-0.7,0.1-0.9,0.4
                              s-0.3,0.6-0.3,0.9v4.8c0,0.1,0,0.2-0.1,0.3s-0.2,0.1-0.3,0.1h-0.6c-0.1,0-0.2,0-0.3-0.1C88.2,82.4,88.2,82.2,88.2,82.1z"/>
                            <path fill="#81C5FC" d="M95.5,80.1v-2.8c0-0.8,0.2-1.5,0.7-2s1.1-0.8,2-0.8c0.7,0,1.3,0.2,1.8,0.6s0.7,1,0.7,1.6v1.2
                              c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.3,0.3-0.6,0.3l-3.1,0.6V80c0,0.3,0.1,0.6,0.3,0.9c0.2,0.2,0.5,0.4,0.9,0.4s0.7-0.1,0.9-0.3
                              c0.2-0.2,0.3-0.5,0.3-0.8c0-0.1,0-0.2,0.1-0.3s0.2-0.1,0.2-0.1h0.1h0.5c0.3,0,0.5,0.2,0.5,0.5c0,0.7-0.2,1.3-0.7,1.8
                              s-1.1,0.7-1.9,0.7s-1.4-0.2-1.9-0.7S95.5,80.9,95.5,80.1z M97,78.2l2.4-0.4V77c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.5-0.2-0.8-0.2
                              c-0.4,0-0.7,0.1-0.9,0.4c-0.2,0.2-0.3,0.6-0.3,0.9L97,78.2L97,78.2z"/>
                            <path fill="#81C5FC" d="M102.4,75.2L102.4,75.2c0-0.2,0-0.2,0.1-0.3s0.2-0.1,0.3-0.1h0.7c0.3,0,0.4,0.1,0.5,0.4l1,5.6l1.3-5.6
                              c0.1-0.3,0.2-0.4,0.5-0.4h0.8c0.2,0,0.4,0.2,0.5,0.5l1.3,5.6l1-5.6c0.1-0.3,0.2-0.5,0.5-0.5h0.7c0.1,0,0.3,0,0.3,0.1
                              C112,75,112,75,112,75.1v0.1l-1.6,6.9c-0.1,0.3-0.2,0.4-0.5,0.4h-1c-0.2,0-0.4-0.2-0.4-0.5l-1.2-5.1l-1.2,5.1
                              c-0.1,0.3-0.2,0.5-0.4,0.5h-1c-0.2,0-0.4-0.1-0.5-0.4L102.4,75.2z"/>
                            <path fill="#D4E4F4" d="M116.5,82.6V72.1c0-0.1,0-0.2,0.1-0.3s0.1-0.1,0.2-0.1h0.1h0.6l0,0h0.1c0.1,0,0.1,0,0.1,0.1
                              s0.1,0.1,0.1,0.1c0,0.1,0,0.1,0,0.2v9h3.1c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.1,0.1,0.2v0.1v0.6l0,0v0.1c0,0,0,0.1-0.1,0.1
                              l-0.1,0.1c-0.1,0-0.1,0-0.2,0h-4.4V82.6z"/>
                            <path fill="#D4E4F4" d="M122.9,80.4c0-0.6,0.1-1.1,0.4-1.5c0.3-0.3,0.8-0.6,1.5-0.8l1.8-0.5v-0.7c0-0.3-0.1-0.6-0.3-0.8
                              c-0.2-0.2-0.5-0.3-0.8-0.3s-0.6,0.1-0.8,0.2c-0.2,0.1-0.3,0.3-0.3,0.6c0,0.1,0,0.2-0.1,0.3S124.1,77,124,77h-0.6
                              c-0.1,0-0.2,0-0.3-0.1s-0.1-0.2-0.1-0.3c0-0.6,0.2-1.2,0.7-1.5c0.5-0.4,1.1-0.6,1.8-0.6c0.3,0,0.7,0.1,1,0.2s0.6,0.3,0.8,0.5
                              c0.2,0.2,0.4,0.5,0.6,0.9c0.1,0.4,0.2,0.8,0.2,1.2V82c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0.1h-0.5c-0.1,0-0.2,0-0.3-0.1
                              s-0.1-0.2-0.1-0.3v-0.1c-0.1,0.2-0.4,0.4-0.6,0.5s-0.6,0.2-0.9,0.2c-0.6,0-1.2-0.2-1.6-0.7C123.1,81.6,122.9,81.1,122.9,80.4z
                               M124.3,80.3c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.4,0.3,0.7,0.3c0.4,0,0.7-0.2,1-0.5c0.3-0.3,0.4-0.7,0.4-1V79l-1.6,0.4
                              C124.6,79.6,124.3,79.9,124.3,80.3z"/>
                            <path fill="#D4E4F4" d="M129.9,82.1v-0.4c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.1s0-0.1,0.1-0.1v-0.1l3.4-5.2h-3
                              c-0.1,0-0.2,0-0.3-0.1s-0.1-0.2-0.1-0.3v-0.5c0-0.1,0-0.2,0.1-0.3s0.2-0.1,0.3-0.1h4.4c0.1,0,0.2,0,0.3,0.1s0.1,0.2,0.1,0.3v0.4
                              c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1-0.1,0.1c0,0,0,0.1-0.1,0.1c0,0,0,0.1-0.1,0.1l-3.4,5.2h3.4c0.1,0,0.2,0,0.3,0.1s0.1,0.2,0.1,0.3
                              v0.5c0,0.1,0,0.2-0.1,0.3s-0.2,0.1-0.3,0.1h-4.8c-0.1,0-0.2,0-0.3-0.1C130,82.4,129.9,82.3,129.9,82.1z"/>
                            <path fill="#D4E4F4" d="M136.5,75.2L136.5,75.2c0-0.2,0-0.2,0.1-0.3s0.2-0.1,0.3-0.1h0.7c0.3,0,0.4,0.2,0.5,0.5l1.4,5.3l0,0
                              l1.5-5.3c0.1-0.3,0.2-0.4,0.5-0.4h0.7c0.1,0,0.2,0,0.3,0.1c0,0,0.1,0.1,0.1,0.2v0.1l-2.6,8.8c-0.2,0.7-0.5,1.2-0.9,1.5
                              c-0.4,0.3-0.9,0.4-1.6,0.4c-0.1,0-0.2,0-0.3-0.1s-0.1-0.2-0.1-0.2v-0.1v-0.5l0,0V85c0-0.1,0-0.1,0.1-0.1s0.1-0.1,0.1-0.1
                              s0.1,0,0.2,0c0.6,0,1-0.2,1.1-0.6l0.3-1.2h-0.1c-0.1,0-0.2-0.1-0.3-0.2L136.5,75.2z"/>
                            <path fill="#D4E4F4" d="M144.1,82.6V72.1c0-0.1,0-0.2,0.1-0.3s0.1-0.1,0.2-0.1h0.1h0.6l0,0h0.1c0.1,0,0.1,0,0.1,0.1
                              s0.1,0.1,0.1,0.1c0,0.1,0,0.1,0,0.2v9h3.1c0.1,0,0.2,0,0.3,0.1s0.1,0.1,0.1,0.2v0.1v0.6l0,0v0.1c0,0,0,0.1-0.1,0.1l-0.1,0.1
                              c-0.1,0-0.1,0-0.2,0h-4.4V82.6z"/>
                            <path fill="#D4E4F4" d="M151,72.9v-0.8c0-0.1,0-0.2,0.1-0.3s0.2-0.1,0.3-0.1h0.7c0.1,0,0.2,0,0.3,0.1s0.1,0.2,0.1,0.3v0.8
                              c0,0.1,0,0.2-0.1,0.3s-0.2,0.1-0.3,0.1h-0.7c-0.1,0-0.2,0-0.3-0.1C151,73.2,151,73,151,72.9z M151,82.1v-6.9c0-0.1,0-0.2,0.1-0.3
                              s0.2-0.1,0.3-0.1h0.6c0.1,0,0.2,0,0.3,0.1s0.1,0.2,0.1,0.3v6.9c0,0.1,0,0.2-0.1,0.3s-0.2,0.1-0.3,0.1h-0.6c-0.1,0-0.2,0-0.3-0.1
                              C151.1,82.4,151,82.2,151,82.1z"/>
                            <path fill="#D4E4F4" d="M154.6,82.1v-6.9c0-0.1,0-0.2,0.1-0.3s0.2-0.1,0.3-0.1h0.6c0.1,0,0.2,0,0.3,0.1s0.1,0.2,0.1,0.3v0.1
                              c0.1-0.1,0.3-0.3,0.5-0.4c0.3-0.1,0.6-0.2,0.9-0.2c0.7,0,1.3,0.2,1.7,0.7s0.6,1.1,0.6,2v4.7c0,0.1,0,0.2-0.1,0.3s-0.2,0.1-0.3,0.1
                              h-0.6c-0.1,0-0.2,0-0.3-0.1s-0.1-0.2-0.1-0.3v-4.8c0-0.9-0.4-1.3-1.1-1.3c-0.4,0-0.7,0.1-0.9,0.4S156,77,156,77.3v4.8
                              c0,0.1,0,0.2-0.1,0.3s-0.2,0.1-0.3,0.1H155c-0.1,0-0.2,0-0.3-0.1C154.7,82.4,154.6,82.2,154.6,82.1z"/>
                            <path fill="#D4E4F4" d="M162,80.1v-2.8c0-0.8,0.2-1.5,0.7-2s1.1-0.8,2-0.8c0.7,0,1.3,0.2,1.8,0.6s0.7,1,0.7,1.6v1.2
                              c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.3,0.3-0.6,0.3l-3.1,0.6V80c0,0.3,0.1,0.6,0.3,0.9c0.2,0.2,0.5,0.4,0.9,0.4s0.7-0.1,0.9-0.3
                              c0.2-0.2,0.3-0.5,0.3-0.8c0-0.1,0-0.2,0.1-0.3s0.2-0.1,0.2-0.1h0.1h0.5c0.3,0,0.5,0.2,0.5,0.5c0,0.7-0.2,1.3-0.7,1.8
                              s-1.1,0.7-1.9,0.7c-0.8,0-1.4-0.2-1.9-0.7S162,80.9,162,80.1z M163.4,78.2l2.4-0.4V77c0-0.3-0.1-0.5-0.3-0.7
                              c-0.2-0.2-0.5-0.2-0.8-0.2c-0.4,0-0.7,0.1-0.9,0.4c-0.2,0.2-0.3,0.6-0.3,0.9L163.4,78.2L163.4,78.2z"/>
                            <path fill="#D4E4F4" d="M169.6,82.1V71.6h2.6c0.6,0,1.1,0.1,1.5,0.3c0.4,0.2,0.8,0.4,1,0.7c0.3,0.3,0.4,0.7,0.6,1.1
                              c0.1,0.4,0.2,0.9,0.2,1.4v0.4c0,0.5-0.1,1-0.2,1.4c-0.1,0.4-0.3,0.8-0.6,1.1c-0.3,0.3-0.6,0.5-1,0.7c-0.4,0.2-0.9,0.2-1.5,0.2
                              h-1.1v3.3c0,0.1,0,0.2-0.1,0.3s-0.1,0.1-0.2,0.1h-0.1h-0.6l0,0H170c-0.1,0-0.1,0-0.1-0.1s-0.1-0.1-0.1-0.1
                              C169.6,82.3,169.6,82.2,169.6,82.1z M171.1,77.3h1.1c1.2,0,1.8-0.6,1.8-1.9V75c0-1.3-0.6-1.9-1.8-1.9h-1.1V77.3z"/>
                            <path fill="#D4E4F4" d="M177.3,80.4c0-0.6,0.1-1.1,0.4-1.5c0.3-0.3,0.8-0.6,1.5-0.8l1.8-0.5v-0.7c0-0.3-0.1-0.6-0.3-0.8
                              c-0.2-0.2-0.5-0.3-0.8-0.3s-0.6,0.1-0.8,0.2c-0.2,0.1-0.3,0.3-0.3,0.6c0,0.1,0,0.2-0.1,0.3s-0.2,0.1-0.3,0.1h-0.6
                              c-0.1,0-0.2,0-0.3-0.1s-0.1-0.2-0.1-0.3c0-0.6,0.2-1.2,0.7-1.5c0.5-0.4,1.1-0.6,1.8-0.6c0.3,0,0.7,0.1,1,0.2s0.6,0.3,0.8,0.5
                              c0.2,0.2,0.4,0.5,0.6,0.9c0.1,0.4,0.2,0.8,0.2,1.2V82c0,0.1,0,0.2-0.1,0.3s-0.2,0.1-0.3,0.1h-0.5c-0.1,0-0.2,0-0.3-0.1
                              s-0.1-0.2-0.1-0.3v-0.1c-0.1,0.2-0.4,0.4-0.6,0.5s-0.6,0.2-0.9,0.2c-0.6,0-1.2-0.2-1.6-0.7C177.5,81.6,177.3,81.1,177.3,80.4z
                               M178.7,80.3c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.4,0.3,0.7,0.3c0.4,0,0.7-0.2,1-0.5c0.3-0.3,0.4-0.7,0.4-1V79l-1.6,0.4
                              C178.9,79.6,178.7,79.9,178.7,80.3z"/>
                            <path fill="#D4E4F4" d="M184.7,72.9v-0.8c0-0.1,0-0.2,0.1-0.3s0.2-0.1,0.3-0.1h0.7c0.1,0,0.2,0,0.3,0.1s0.1,0.2,0.1,0.3v0.8
                              c0,0.1,0,0.2-0.1,0.3s-0.2,0.1-0.3,0.1h-0.6c-0.1,0-0.2,0-0.3-0.1C184.8,73.2,184.7,73,184.7,72.9z M184.8,82.1v-6.9
                              c0-0.1,0-0.2,0.1-0.3s0.2-0.1,0.3-0.1h0.6c0.1,0,0.2,0,0.3,0.1s0.1,0.2,0.1,0.3v6.9c0,0.1,0,0.2-0.1,0.3s-0.2,0.1-0.3,0.1h-0.6
                              c-0.1,0-0.2,0-0.3-0.1C184.8,82.4,184.8,82.2,184.8,82.1z"/>
                            <path fill="#D4E4F4" d="M188.4,82.1v-6.9c0-0.1,0-0.2,0.1-0.3s0.2-0.1,0.3-0.1h0.6c0.1,0,0.2,0,0.3,0.1s0.1,0.2,0.1,0.3v0.1
                              c0.1-0.1,0.3-0.3,0.5-0.4c0.3-0.1,0.6-0.2,0.9-0.2c0.7,0,1.3,0.2,1.7,0.7s0.6,1.1,0.6,2v4.7c0,0.1,0,0.2-0.1,0.3s-0.2,0.1-0.3,0.1
                              h-0.6c-0.1,0-0.2,0-0.3-0.1s-0.1-0.2-0.1-0.3v-4.8c0-0.9-0.4-1.3-1.1-1.3c-0.4,0-0.7,0.1-0.9,0.4s-0.3,0.6-0.3,0.9v4.8
                              c0,0.1,0,0.2-0.1,0.3s-0.2,0.1-0.3,0.1h-0.6c-0.1,0-0.2,0-0.3-0.1C188.5,82.4,188.4,82.2,188.4,82.1z"/>
                            <path fill="#D4E4F4" d="M195.3,75.8v-0.4l0,0v-0.1c0,0,0-0.1,0.1-0.1l0.1-0.1c0.1,0,0.1,0,0.2,0h0.3v-2.5c0-0.1,0-0.2,0.1-0.3
                              s0.1-0.1,0.2-0.1h0.1h0.5l0,0h0.1c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.1c0,0.1,0,0.1,0,0.2v2.5h1.2c0.1,0,0.2,0,0.3,0.1
                              s0.1,0.1,0.1,0.2v0.1v0.4l0,0V76c0,0,0,0.1-0.1,0.1s-0.1,0.1-0.1,0.1c-0.1,0-0.1,0-0.2,0h-1.2v3.9c0,0.4,0.1,0.7,0.3,0.9
                              c0.2,0.2,0.5,0.3,0.8,0.3h0.3c0.1,0,0.2,0,0.3,0.1s0.1,0.1,0.1,0.2v0.1v0.4l0,0v0.1c0,0,0,0.1-0.1,0.1l-0.1,0.1
                              c-0.1,0-0.1,0-0.2,0h-0.3c-0.8,0-1.5-0.2-1.9-0.6c-0.4-0.4-0.6-1-0.6-1.9V76h-0.3C195.4,76,195.3,76,195.3,75.8
                              c-0.2,0-0.2,0-0.2-0.1L195.3,75.8z"/>
                            <path fill="#D4E4F4" d="M200.9,80.1v-2.8c0-0.8,0.2-1.5,0.7-2s1.1-0.8,2-0.8c0.7,0,1.3,0.2,1.8,0.6s0.7,1,0.7,1.6v1.2
                              c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.3,0.3-0.6,0.3l-3.1,0.6V80c0,0.3,0.1,0.6,0.3,0.9c0.2,0.2,0.5,0.4,0.9,0.4s0.7-0.1,0.9-0.3
                              c0.2-0.2,0.3-0.5,0.3-0.8c0-0.1,0-0.2,0.1-0.3s0.2-0.1,0.2-0.1h0.1h0.5c0.3,0,0.5,0.2,0.5,0.5c0,0.7-0.2,1.3-0.7,1.8
                              s-1.1,0.7-1.9,0.7c-0.8,0-1.4-0.2-1.9-0.7S200.9,80.9,200.9,80.1z M202.3,78.2l2.4-0.4V77c0-0.3-0.1-0.5-0.3-0.7
                              c-0.2-0.2-0.5-0.2-0.8-0.2c-0.4,0-0.7,0.1-0.9,0.4c-0.2,0.2-0.3,0.6-0.3,0.9L202.3,78.2L202.3,78.2z"/>
                            <path fill="#D4E4F4" d="M208.3,82.1v-6.9c0-0.1,0-0.2,0.1-0.3s0.2-0.1,0.3-0.1h0.6c0.1,0,0.2,0,0.3,0.1s0.1,0.2,0.1,0.3v0.1
                              c0.3-0.3,0.5-0.4,0.7-0.5s0.5-0.1,1-0.1c0.1,0,0.2,0,0.3,0.1s0.1,0.2,0.1,0.3v0.4c0,0.1,0,0.2-0.1,0.3s-0.2,0.1-0.3,0.1
                              c-0.3,0-0.6,0-0.8,0.1s-0.4,0.2-0.5,0.3s-0.2,0.3-0.2,0.4c0,0.1-0.1,0.3-0.1,0.5V82c0,0.1,0,0.2-0.1,0.3s-0.2,0.1-0.3,0.1h-0.6
                              c-0.1,0-0.2,0-0.3-0.1C208.3,82.4,208.3,82.2,208.3,82.1z"/>
                            <path fill="#D4E4F4" d="M212.4,78.1V76c0-0.8,0-1.5,0.1-2.1s0.2-1.2,0.3-1.7s0.3-0.9,0.5-1.3c0.2-0.4,0.4-0.7,0.6-1
                              c0.2-0.3,0.4-0.5,0.7-0.7c0.1-0.1,0.2-0.1,0.3-0.1s0.2,0.1,0.2,0.1l0.1,0.1l0.4,0.5c0,0,0,0.1,0.1,0.1c0,0,0,0.1,0,0.2
                              c0,0.1-0.1,0.2-0.2,0.3c-0.5,0.5-0.9,1.1-1.2,2c-0.3,0.8-0.4,2.1-0.4,3.7v2.1c0,1.6,0.1,2.8,0.4,3.7c0.3,0.8,0.7,1.5,1.2,2
                              c0.1,0.1,0.2,0.2,0.2,0.3s0,0.2,0,0.3l-0.1,0.1l-0.4,0.5l0,0c0,0,0,0-0.1,0.1l-0.1,0.1c0,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2-0.1
                              c-0.2-0.2-0.5-0.4-0.7-0.7c-0.2-0.3-0.4-0.6-0.6-1c-0.2-0.4-0.3-0.8-0.5-1.3c-0.1-0.5-0.2-1-0.3-1.7
                              C212.4,79.8,212.4,78.8,212.4,78.1z"/>
                            <path fill="#D4E4F4" d="M237,76.4c1.4,0,1.6-0.8,1.6-1.5c0-0.6-0.1-1.1-0.2-1.7c-0.1-0.6-0.2-1.1-0.2-1.6c0-1.8,1.2-2.6,2.9-2.6
                              h0.4v1.1h-0.3c-1.2,0-1.6,0.6-1.6,1.7c0,0.5,0.1,0.9,0.1,1.4c0.1,0.5,0.1,1,0.1,1.5c0,1.3-0.5,1.9-1.4,2.1l0,0
                              c0.9,0.2,1.4,0.9,1.4,2.2c0,0.5-0.1,1-0.1,1.5c-0.1,0.5-0.1,1-0.1,1.4c0,1.1,0.5,1.7,1.6,1.7h0.4v1.1h-0.4c-1.6,0-2.9-0.7-2.9-2.7
                              c0-0.5,0.1-1.1,0.2-1.6c0.1-0.5,0.2-1.1,0.2-1.6c0-0.6-0.2-1.5-1.6-1.5L237,76.4L237,76.4z"/>
                            <path fill="#D4E4F4" d="M82.2,90.6"/>
                            <path fill="#D4E4F4" d="M66.2,103.5c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.1-0.1,0.2-0.1h0.1H67c0.3,0,0.5,0.2,0.5,0.5
                              c0,0.8,0.4,1.2,1.1,1.2c0.3,0,0.5-0.1,0.7-0.3s0.3-0.4,0.3-0.6s0-0.4-0.1-0.6s-0.3-0.3-0.5-0.5l-1.3-0.7c-0.4-0.2-0.7-0.5-0.9-0.9
                              s-0.3-0.7-0.3-1c0-0.6,0.2-1.1,0.6-1.5s0.9-0.6,1.6-0.6c0.3,0,0.5,0,0.8,0.1s0.5,0.1,0.7,0.3c0.2,0.1,0.4,0.3,0.6,0.5
                              c0.1,0.2,0.2,0.5,0.3,0.9c0,0.1,0,0.2-0.1,0.3s-0.1,0.1-0.2,0.2h-0.1l-0.5-0.1c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.2-0.2-0.3
                              c-0.1-0.4-0.4-0.7-0.9-0.7c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.3,0.4-0.3,0.6c0,0.3,0.2,0.6,0.6,0.8l1.1,0.6c1,0.5,1.5,1.3,1.5,2.2
                              c0,0.6-0.2,1.1-0.6,1.5s-1,0.7-1.7,0.7c-0.4,0-0.7-0.1-1-0.2s-0.5-0.3-0.7-0.4c-0.2-0.2-0.3-0.4-0.4-0.6s-0.2-0.5-0.2-0.7
                              S66.2,103.7,66.2,103.5z"/>
                            <path fill="#D4E4F4" d="M72.5,99.1v-0.4l0,0v-0.1c0,0,0-0.1,0.1-0.1l0.1-0.1c0.1,0,0.1,0,0.2,0h0.3v-2.5c0-0.1,0-0.2,0.1-0.3
                              s0.1-0.1,0.2-0.1h0.1h0.6l0,0h0.1c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.1c0,0.1,0,0.1,0,0.2v2.5h1.2c0.1,0,0.2,0,0.3,0.1
                              c0.1,0.1,0.1,0.1,0.1,0.2v0.1v0.4l0,0v0.1c0,0,0,0.1-0.1,0.1s-0.1,0.1-0.1,0.1c-0.1,0-0.1,0-0.2,0h-1.2v3.9c0,0.4,0.1,0.7,0.3,0.9
                              s0.5,0.3,0.8,0.3h0.3c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.1,0.1,0.2v0.1v0.4l0,0v0.1c0,0,0,0.1-0.1,0.1l-0.1,0.1
                              c-0.1,0-0.1,0-0.2,0h-0.3c-0.8,0-1.5-0.2-1.9-0.6s-0.6-1-0.6-1.9v-3.9h-0.3C72.7,99.3,72.6,99.3,72.5,99.1c-0.1,0-0.1,0-0.1-0.1
                              L72.5,99.1z"/>
                            <path fill="#D4E4F4" d="M78.1,105.4v-6.9c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1h0.6c0.1,0,0.2,0,0.3,0.1
                              c0.1,0.1,0.1,0.2,0.1,0.3v0.1c0.3-0.3,0.5-0.4,0.7-0.5s0.5-0.1,1-0.1c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3v0.4
                              c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0.1c-0.3,0-0.6,0-0.8,0.1c-0.2,0.1-0.4,0.2-0.5,0.3c-0.1,0.1-0.2,0.3-0.2,0.4
                              s-0.1,0.3-0.1,0.5v4.8c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0.1h-0.6c-0.1,0-0.2,0-0.3-0.1C78.2,105.6,78.1,105.5,78.1,105.4
                              z"/>
                            <path fill="#D4E4F4" d="M82.3,103.2v-2.3c0-1,0.2-1.7,0.7-2.2s1.1-0.7,1.9-0.7s1.5,0.2,1.9,0.7c0.5,0.5,0.7,1.2,0.7,2.2v2.3
                              c0,0.9-0.2,1.6-0.7,2s-1.1,0.7-1.9,0.7l0,0c-0.8,0-1.4-0.2-1.9-0.7C82.5,104.7,82.3,104,82.3,103.2z M83.7,103.2
                              c0,1,0.4,1.5,1.2,1.5c0.8,0,1.2-0.5,1.2-1.5v-2.3c0-0.5-0.1-0.9-0.3-1.2s-0.5-0.4-0.9-0.4s-0.8,0.1-0.9,0.4s-0.3,0.7-0.3,1.2
                              L83.7,103.2L83.7,103.2z"/>
                            <path fill="#D4E4F4" d="M89.5,105.4v-10c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1h0.6c0.1,0,0.2,0,0.3,0.1
                              c0.1,0.1,0.1,0.2,0.1,0.3v5.6l2.2-2.6c0.1-0.1,0.2-0.2,0.2-0.2c0.1-0.1,0.1-0.1,0.2-0.1h0.1h0.8c0,0,0,0,0.1,0s0.1,0,0.1,0
                              c0.1,0,0.1,0.1,0.1,0.1s0,0.1,0,0.2s-0.1,0.2-0.2,0.3l-2,2.4l2.5,4.2c0.1,0.2,0.1,0.3,0.1,0.4l0,0c0,0.1-0.1,0.1-0.1,0.2h-0.1
                              h-0.7l0,0l0,0c0,0-0.1,0-0.2-0.1s-0.1-0.1-0.2-0.2l-2.1-3.5l-0.7,0.8v2.6c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0.1H90
                              c-0.1,0-0.2,0-0.3-0.1C89.5,105.6,89.5,105.5,89.5,105.4z"/>
                            <path fill="#D4E4F4" d="M96.3,103.4v-2.8c0-0.8,0.2-1.5,0.7-2s1.1-0.8,2-0.8c0.7,0,1.3,0.2,1.8,0.6s0.7,1,0.7,1.6v1.2
                              c0,0.2-0.1,0.4-0.2,0.6s-0.3,0.3-0.6,0.3l-3.1,0.6v0.6c0,0.3,0.1,0.6,0.3,0.9c0.2,0.2,0.5,0.4,0.9,0.4s0.7-0.1,0.9-0.3
                              c0.2-0.2,0.3-0.5,0.3-0.8c0-0.1,0-0.2,0.1-0.3s0.2-0.1,0.2-0.1h0.1h0.5c0.3,0,0.5,0.2,0.5,0.5c0,0.7-0.2,1.3-0.7,1.8
                              s-1.1,0.7-1.9,0.7s-1.4-0.2-1.9-0.7S96.3,104.2,96.3,103.4z M97.7,101.5l2.4-0.4v-0.8c0-0.3-0.1-0.5-0.3-0.7
                              c-0.2-0.2-0.5-0.2-0.8-0.2c-0.4,0-0.7,0.1-0.9,0.4c-0.2,0.2-0.3,0.6-0.3,0.9L97.7,101.5L97.7,101.5z"/>
                            <path fill="#D4E4F4" d="M103.4,95.4L103.4,95.4c0-0.2,0-0.3,0.1-0.3c0.1-0.1,0.1-0.1,0.2-0.1h0.1h0.6c0.1,0,0.2,0,0.2,0.1
                              s0.1,0.2,0.2,0.3l1.5,8.6l1.4-7.8c0-0.1,0-0.2,0.1-0.2c0-0.1,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0h0.1h0.7c0.3,0,0.5,0.1,0.5,0.4
                              l1.5,7.7l1.5-8.6c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.1,0.3-0.1h0.7c0,0,0,0,0.1,0s0.1,0,0.1,0c0.1,0,0.1,0,0.1,0.1
                              c0,0,0.1,0.1,0.1,0.2v0.1v0.1l-1.9,10c0,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.1-0.2,0.1h-0.1h-1.3l0,0h-0.1c-0.1,0-0.1,0-0.1-0.1
                              l-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2l-1.1-6.8l-1.1,6.8c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.1-0.1,0.1-0.2,0.1h-0.1h-1.3h-0.1
                              c0,0-0.1,0-0.2-0.1s-0.1-0.2-0.2-0.3L103.4,95.4z"/>
                            <path fill="#D4E4F4" d="M115.8,96.2v-0.8c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1h0.7c0.1,0,0.2,0,0.3,0.1
                              c0.1,0.1,0.1,0.2,0.1,0.3v0.8c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0.1h-0.7c-0.1,0-0.2,0-0.3-0.1
                              C115.9,96.4,115.8,96.3,115.8,96.2z M115.9,105.4v-6.9c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1h0.6c0.1,0,0.2,0,0.3,0.1
                              c0.1,0.1,0.1,0.2,0.1,0.3v6.9c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0.1h-0.6c-0.1,0-0.2,0-0.3-0.1
                              C115.9,105.6,115.9,105.5,115.9,105.4z"/>
                            <path fill="#D4E4F4" d="M119.2,103.3v-2.6c0-0.4,0-0.7,0.1-1.1c0.1-0.3,0.2-0.6,0.4-0.9c0.2-0.3,0.4-0.5,0.7-0.6s0.7-0.2,1.1-0.2
                              c0.2,0,0.5,0.1,0.8,0.2s0.5,0.2,0.6,0.4v-3.1c0-0.1,0-0.2,0.1-0.3s0.1-0.1,0.2-0.1h0.1h0.6c0.1,0,0.2,0,0.3,0.1s0.1,0.2,0.1,0.3
                              v10c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0.1h-0.5c-0.1,0-0.2,0-0.3-0.1s-0.1-0.2-0.1-0.3v-0.1c-0.2,0.3-0.4,0.4-0.7,0.5
                              s-0.5,0.2-0.8,0.2c-0.7,0-1.3-0.2-1.7-0.7C119.4,104.8,119.2,104.2,119.2,103.3z M120.6,103.4c0,0.4,0.1,0.8,0.3,0.9
                              s0.5,0.3,0.8,0.3c0.4,0,0.7-0.1,1-0.3c0.2-0.2,0.4-0.5,0.4-0.9v-2.9c0-0.5-0.1-0.8-0.4-1s-0.5-0.3-0.9-0.3c-0.8,0-1.1,0.4-1.1,1.3
                              v2.9H120.6z"/>
                            <path fill="#D4E4F4" d="M126.2,99.1v-0.4l0,0v-0.1c0,0,0-0.1,0.1-0.1l0.1-0.1c0.1,0,0.1,0,0.2,0h0.3v-2.5c0-0.1,0-0.2,0.1-0.3
                              s0.1-0.1,0.2-0.1h0.1h0.5l0,0h0.1c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.1c0,0.1,0,0.1,0,0.2v2.5h1.2c0.1,0,0.2,0,0.3,0.1
                              s0.1,0.1,0.1,0.2v0.1v0.4l0,0v0.1c0,0,0,0.1-0.1,0.1s-0.1,0.1-0.1,0.1c-0.1,0-0.1,0-0.2,0h-1.2v3.9c0,0.4,0.1,0.7,0.3,0.9
                              s0.5,0.3,0.8,0.3h0.3c0.1,0,0.2,0,0.3,0.1s0.1,0.1,0.1,0.2v0.1v0.4l0,0v0.1c0,0,0,0.1-0.1,0.1l-0.1,0.1c-0.1,0-0.1,0-0.2,0h-0.3
                              c-0.8,0-1.5-0.2-1.9-0.6s-0.6-1-0.6-1.9v-3.9h-0.3C126.3,99.3,126.2,99.3,126.2,99.1c-0.2,0-0.2,0-0.2-0.1L126.2,99.1z"/>
                            <path fill="#D4E4F4" d="M131.9,105.4v-10c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1h0.6c0.1,0,0.2,0,0.3,0.1s0.1,0.2,0.1,0.3
                              v3.2c0-0.1,0.2-0.3,0.4-0.4c0.3-0.2,0.6-0.2,1-0.2c0.7,0,1.3,0.2,1.7,0.7s0.6,1.1,0.6,2v4.7c0,0.1,0,0.2-0.1,0.3
                              c-0.1,0.1-0.2,0.1-0.3,0.1H136c-0.1,0-0.2,0-0.3-0.1s-0.1-0.2-0.1-0.3v-4.8c0-0.9-0.4-1.3-1.1-1.3c-0.4,0-0.7,0.1-0.9,0.4
                              s-0.3,0.6-0.3,0.9v4.8c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0.1h-0.6c-0.1,0-0.2,0-0.3-0.1
                              C131.9,105.6,131.9,105.5,131.9,105.4z"/>
                            <path fill="#D4E4F4" d="M142.3,101.1v-0.7c0-0.1,0-0.2,0.1-0.3s0.2-0.1,0.3-0.1h0.7c0.1,0,0.2,0,0.3,0.1s0.1,0.2,0.1,0.3v0.7
                              c0,0.1,0,0.2-0.1,0.3s-0.2,0.1-0.3,0.1h-0.7c-0.1,0-0.2,0-0.3-0.1S142.3,101.2,142.3,101.1z M142.3,105.4v-0.7
                              c0-0.1,0-0.2,0.1-0.3s0.2-0.1,0.3-0.1h0.7c0.1,0,0.2,0,0.3,0.1s0.1,0.2,0.1,0.3v0.7c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0.1
                              h-0.7c-0.1,0-0.2,0-0.3-0.1S142.3,105.5,142.3,105.4z"/>
                            <path fill="#FF5500" d="M148.4,105.4v-0.6l0,0v-0.1c0-0.1,0-0.1,0.1-0.1s0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.2,0h2.1v-7.9h-1.1
                              c-0.1,0-0.2,0-0.3-0.1s-0.1-0.1-0.1-0.2v-0.1v-0.6l0,0v-0.1c0,0,0-0.1,0.1-0.1s0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.2,0h2.2
                              c0.1,0,0.2,0,0.3,0.1s0.1,0.2,0.1,0.2v0.1v9h2c0.1,0,0.2,0,0.3,0.1s0.1,0.1,0.1,0.2v0.1v0.6l0,0v0.1c0,0,0,0.1-0.1,0.1l-0.1,0.1
                              c-0.1,0-0.1,0-0.2,0h-5.6c-0.1,0-0.2,0-0.3-0.1s-0.1-0.1-0.1-0.2V105.4z"/>
                            <path fill="#FF5500" d="M155.6,102.7v-4.6c0-1,0.2-1.8,0.7-2.4s1.2-0.9,2.1-0.9s1.6,0.3,2.1,0.9s0.7,1.4,0.7,2.4v4.6
                              c0,1-0.3,1.8-0.8,2.4s-1.2,0.9-2.1,0.9s-1.6-0.3-2.1-0.9C155.9,104.4,155.6,103.6,155.6,102.7z M157.1,102.4
                              c0,0.8,0.1,1.3,0.3,1.6c0.2,0.3,0.5,0.5,1,0.5s0.8-0.2,1-0.5s0.3-0.9,0.3-1.6v-4.1c0-0.8-0.1-1.3-0.3-1.6c-0.2-0.3-0.6-0.5-1-0.5
                              c-0.5,0-0.8,0.2-1,0.5s-0.3,0.8-0.3,1.6V102.4z"/>
                            <path fill="#D4E4F4" d="M31.3,119.1c-1.4,0-1.6,0.9-1.6,1.5c0,0.5,0.1,1.1,0.2,1.6c0.1,0.5,0.2,1.1,0.2,1.6c0,2-1.2,2.7-2.9,2.7
                              h-0.4v-1.1h0.3c1.1,0,1.6-0.6,1.6-1.7c0-0.5-0.1-0.9-0.1-1.4c-0.1-0.5-0.2-1-0.2-1.5c0-1.3,0.6-1.9,1.4-2.2l0,0
                              c-0.9-0.2-1.4-0.9-1.4-2.1c0-0.5,0.1-1,0.2-1.5c0.1-0.5,0.1-1,0.1-1.4c0-1.1-0.4-1.7-1.6-1.7h-0.4v-1.1h0.4c1.7,0,2.9,0.8,2.9,2.6
                              c0,0.6-0.1,1.1-0.2,1.6c-0.1,0.6-0.2,1.1-0.2,1.7c0,0.7,0.2,1.5,1.6,1.5v0.9H31.3z"/>
                            <path fill="#D4E4F4" d="M33.1,111.7c0-0.1,0-0.2,0-0.3l0.1-0.1l0.4-0.5l0,0c0,0,0,0,0.1-0.1s0.1-0.1,0.1-0.1s0.1,0,0.2,0
                              c0.1,0,0.1,0,0.2,0.1c0.2,0.2,0.5,0.4,0.7,0.7c0.2,0.3,0.4,0.6,0.6,1c0.2,0.4,0.3,0.8,0.5,1.3c0.1,0.5,0.2,1,0.3,1.7
                              s0.1,1.4,0.1,2.1v2.1c0,0.8,0,1.5-0.1,2.1c-0.1,0.6-0.2,1.2-0.3,1.7s-0.3,0.9-0.5,1.3c-0.2,0.4-0.4,0.7-0.6,1s-0.4,0.5-0.7,0.7
                              c-0.1,0.1-0.2,0.1-0.3,0.1s-0.2-0.1-0.2-0.1l-0.1-0.1l-0.4-0.5c0,0,0-0.1-0.1-0.1c0,0,0-0.1,0-0.2c0-0.1,0.1-0.2,0.2-0.3
                              c0.5-0.5,0.9-1.1,1.2-2c0.3-0.8,0.4-2.1,0.4-3.7v-2.1c0-1.6-0.1-2.8-0.4-3.7c-0.3-0.8-0.7-1.5-1.2-2
                              C33.2,111.9,33.1,111.8,33.1,111.7z"/>
                            <path fill="#D4E4F4" d="M27.4,147.3v-0.4c0-0.1,0-0.2,0.1-0.3s0.2-0.1,0.3-0.1h0.3v-0.8c0-0.8,0.2-1.4,0.6-1.8s1-0.7,1.9-0.7h0.3
                              c0.1,0,0.2,0,0.3,0.1s0.1,0.2,0.1,0.3v0.4c0,0.1,0,0.2-0.1,0.3s-0.2,0.1-0.3,0.1h-0.3c-0.2,0-0.4,0-0.6,0.1s-0.3,0.2-0.3,0.3
                              c-0.1,0.1-0.1,0.2-0.1,0.4c0,0.1,0,0.3,0,0.4v0.8h1.3c0.1,0,0.2,0,0.3,0.1s0.1,0.2,0.1,0.3v0.4c0,0.1,0,0.2-0.1,0.3
                              s-0.2,0.1-0.3,0.1h-1.3v5.9c0,0.1,0,0.2-0.1,0.3s-0.2,0.1-0.3,0.1h-0.6c-0.1,0-0.2,0-0.3-0.1s-0.1-0.2-0.1-0.3v-5.9h-0.3
                              c-0.1,0-0.2,0-0.3-0.1C27.4,147.5,27.4,147.4,27.4,147.3z"/>
                            <path fill="#D4E4F4" d="M32.1,151.9c0-0.6,0.1-1.1,0.4-1.5c0.3-0.3,0.8-0.6,1.5-0.8l1.8-0.5v-0.7c0-0.3-0.1-0.6-0.3-0.8
                              s-0.5-0.3-0.8-0.3s-0.6,0.1-0.8,0.2c-0.2,0.1-0.3,0.3-0.3,0.6c0,0.1,0,0.2-0.1,0.3s-0.2,0.1-0.3,0.1h-0.6c-0.1,0-0.2,0-0.3-0.1
                              s-0.1-0.2-0.1-0.3c0-0.6,0.2-1.2,0.7-1.5c0.5-0.4,1.1-0.6,1.8-0.6c0.3,0,0.7,0.1,1,0.2c0.3,0.1,0.6,0.3,0.8,0.5s0.4,0.5,0.6,0.9
                              c0.1,0.4,0.2,0.8,0.2,1.2v4.7c0,0.1,0,0.2-0.1,0.3s-0.2,0.1-0.3,0.1h-0.5c-0.1,0-0.2,0-0.3-0.1s-0.1-0.2-0.1-0.3v-0.1
                              c-0.1,0.2-0.4,0.4-0.6,0.5s-0.6,0.2-0.9,0.2c-0.6,0-1.2-0.2-1.6-0.7C32.3,153,32.1,152.5,32.1,151.9z M33.5,151.8
                              c0,0.3,0.1,0.5,0.3,0.7s0.4,0.3,0.7,0.3c0.4,0,0.7-0.2,1-0.5s0.4-0.7,0.4-1v-0.8l-1.6,0.4C33.7,151,33.5,151.3,33.5,151.8z"/>
                            <path fill="#D4E4F4" d="M39.2,151.3V149c0-0.5,0.1-1,0.2-1.4c0.2-0.4,0.4-0.7,0.6-0.9s0.5-0.4,0.8-0.5s0.6-0.2,0.9-0.2
                              c0.3,0,0.5,0,0.8,0.1c0.2,0.1,0.5,0.2,0.7,0.3s0.4,0.3,0.6,0.6c0.2,0.3,0.3,0.5,0.4,0.9c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1-0.1,0.2
                              c-0.1,0.1-0.1,0.1-0.3,0.1l-0.5,0.1c-0.1,0-0.1,0-0.2,0c-0.2,0-0.3-0.1-0.4-0.4c-0.1-0.5-0.5-0.8-1.1-0.8c-0.4,0-0.7,0.1-0.9,0.4
                              c-0.2,0.3-0.3,0.7-0.3,1.2v2.3c0,1,0.4,1.5,1.2,1.5c0.4,0,0.7-0.1,0.9-0.3s0.3-0.5,0.3-0.8c0-0.1,0-0.2,0.1-0.3s0.1-0.1,0.2-0.1
                              h0.1h0.5c0.3,0,0.5,0.2,0.5,0.5c0,0.4-0.1,0.8-0.3,1.2c-0.2,0.3-0.4,0.6-0.7,0.8s-0.5,0.3-0.8,0.4c-0.3,0.1-0.6,0.1-0.9,0.1
                              c-0.9,0-1.5-0.2-2-0.7C39.4,153,39.2,152.3,39.2,151.3z"/>
                            <path fill="#D4E4F4" d="M46.3,151.6v-2.8c0-0.8,0.2-1.5,0.7-2s1.1-0.8,2-0.8c0.7,0,1.3,0.2,1.8,0.6c0.5,0.4,0.7,1,0.7,1.6v1.2
                              c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.3,0.3-0.6,0.3l-3.1,0.6v0.6c0,0.3,0.1,0.6,0.3,0.9c0.2,0.2,0.5,0.4,0.9,0.4s0.7-0.1,0.9-0.3
                              s0.3-0.5,0.3-0.8c0-0.1,0-0.2,0.1-0.3s0.2-0.1,0.2-0.1h0.1h0.5c0.3,0,0.5,0.2,0.5,0.5c0,0.7-0.2,1.3-0.7,1.8s-1.1,0.7-1.9,0.7
                              c-0.8,0-1.4-0.2-1.9-0.7S46.3,152.3,46.3,151.6z M47.7,149.7l2.4-0.4v-0.8c0-0.3-0.1-0.5-0.3-0.7s-0.5-0.2-0.8-0.2
                              c-0.4,0-0.7,0.1-0.9,0.4c-0.2,0.2-0.3,0.6-0.3,0.9v0.8H47.7z"/>
                            <path fill="#D4E4F4" d="M54.1,153.6v-0.7c0-0.1,0-0.2,0.1-0.3s0.2-0.1,0.3-0.1h0.7c0.1,0,0.2,0,0.3,0.1s0.1,0.2,0.1,0.3v0.7
                              c0,0.1,0,0.2-0.1,0.3s-0.2,0.1-0.3,0.1h-0.7c-0.1,0-0.2,0-0.3-0.1S54.1,153.7,54.1,153.6z"/>
                            <path fill="#81C5FC" d="M57.4,156.9v-10.2c0-0.1,0-0.2,0.1-0.3s0.2-0.1,0.3-0.1h0.6c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3
                              v0.1c0.1-0.2,0.3-0.3,0.6-0.5c0.3-0.1,0.6-0.2,0.9-0.2c0.8,0,1.3,0.2,1.8,0.7c0.4,0.5,0.6,1.1,0.6,2v2.6c0,0.8-0.2,1.4-0.7,1.9
                              s-1,0.8-1.7,0.8c-0.3,0-0.6-0.1-0.9-0.2c-0.3-0.1-0.5-0.3-0.6-0.5v3.4c0,0.1,0,0.2-0.1,0.3s-0.2,0.1-0.3,0.1h-0.5
                              c-0.1,0-0.2,0-0.3-0.1C57.5,157.1,57.4,157,57.4,156.9z M58.8,151.6c0,0.4,0.1,0.7,0.4,0.9c0.2,0.2,0.6,0.4,0.9,0.4
                              s0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.9v-2.8c0-0.4-0.1-0.7-0.3-1c-0.2-0.2-0.5-0.4-0.9-0.4s-0.7,0.1-0.9,0.4s-0.3,0.6-0.3,0.9
                              V151.6z"/>
                            <path fill="#81C5FC" d="M64.5,151.9c0-0.6,0.1-1.1,0.4-1.5c0.3-0.3,0.8-0.6,1.5-0.8l1.8-0.5v-0.7c0-0.3-0.1-0.6-0.3-0.8
                              s-0.5-0.3-0.8-0.3s-0.6,0.1-0.8,0.2c-0.2,0.1-0.3,0.3-0.3,0.6c0,0.1,0,0.2-0.1,0.3s-0.2,0.1-0.3,0.1H65c-0.1,0-0.2,0-0.3-0.1
                              c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.6,0.2-1.2,0.7-1.5c0.5-0.4,1.1-0.6,1.8-0.6c0.3,0,0.7,0.1,1,0.2c0.3,0.1,0.6,0.3,0.8,0.5
                              c0.2,0.2,0.4,0.5,0.6,0.9c0.1,0.4,0.2,0.8,0.2,1.2v4.7c0,0.1,0,0.2-0.1,0.3s-0.2,0.1-0.3,0.1h-0.5c-0.1,0-0.2,0-0.3-0.1
                              c-0.1-0.1-0.1-0.2-0.1-0.3v-0.1c-0.1,0.2-0.4,0.4-0.6,0.5s-0.6,0.2-0.9,0.2c-0.6,0-1.2-0.2-1.6-0.7
                              C64.7,153,64.5,152.5,64.5,151.9z M65.9,151.8c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.4,0.3,0.7,0.3c0.4,0,0.7-0.2,1-0.5s0.4-0.7,0.4-1
                              v-0.8l-1.6,0.4C66.2,151,65.9,151.3,65.9,151.8z"/>
                            <path fill="#81C5FC" d="M72,144.4v-0.8c0-0.1,0-0.2,0.1-0.3s0.2-0.1,0.3-0.1h0.7c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3v0.8
                              c0,0.1,0,0.2-0.1,0.3s-0.2,0.1-0.3,0.1h-0.7c-0.1,0-0.2,0-0.3-0.1C72,144.6,72,144.5,72,144.4z M72.1,153.6v-6.9
                              c0-0.1,0-0.2,0.1-0.3s0.2-0.1,0.3-0.1h0.6c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3v6.9c0,0.1,0,0.2-0.1,0.3s-0.2,0.1-0.3,0.1
                              h-0.6c-0.1,0-0.2,0-0.3-0.1C72.1,153.8,72.1,153.7,72.1,153.6z"/>
                            <path fill="#81C5FC" d="M75.7,153.6v-6.9c0-0.1,0-0.2,0.1-0.3s0.2-0.1,0.3-0.1h0.6c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3
                              v0.1c0.1-0.1,0.3-0.3,0.5-0.4c0.3-0.1,0.6-0.2,0.9-0.2c0.7,0,1.3,0.2,1.7,0.7s0.6,1.1,0.6,2v4.7c0,0.1,0,0.2-0.1,0.3
                              s-0.2,0.1-0.3,0.1h-0.6c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.3v-4.8c0-0.9-0.4-1.3-1.1-1.3c-0.4,0-0.7,0.1-0.9,0.4
                              s-0.3,0.6-0.3,0.9v4.8c0,0.1,0,0.2-0.1,0.3s-0.2,0.1-0.3,0.1h-0.6c-0.1,0-0.2,0-0.3-0.1C75.7,153.8,75.7,153.7,75.7,153.6z"/>
                            <path fill="#81C5FC" d="M82.5,147.2v-0.4l0,0v-0.1c0,0,0-0.1,0.1-0.1l0.1-0.1c0.1,0,0.1,0,0.2,0h0.3V144c0-0.1,0-0.2,0.1-0.3
                              s0.1-0.1,0.2-0.1h0.1h0.5l0,0h0.1c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.1c0,0.1,0,0.1,0,0.2v2.5h1.2c0.1,0,0.2,0,0.3,0.1
                              c0.1,0.1,0.1,0.1,0.1,0.2v0.1v0.4l0,0v0.1c0,0,0,0.1-0.1,0.1s-0.1,0.1-0.1,0.1c-0.1,0-0.1,0-0.2,0h-1.2v3.9c0,0.4,0.1,0.7,0.3,0.9
                              s0.5,0.3,0.8,0.3h0.3c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.1,0.1,0.2v0.1v0.4l0,0v0.1c0,0,0,0.1-0.1,0.1l-0.1,0.1
                              c-0.1,0-0.1,0-0.2,0h-0.3c-0.8,0-1.5-0.2-1.9-0.6s-0.6-1-0.6-1.9v-3.9h-0.3c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.1-0.1-0.1-0.2
                              L82.5,147.2z"/>
                            <path fill="#81C5FC" d="M87.9,149.6v-2.1c0-0.8,0-1.5,0.1-2.1s0.2-1.2,0.3-1.7s0.3-0.9,0.5-1.3c0.2-0.4,0.4-0.7,0.6-1
                              s0.4-0.5,0.7-0.7c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2,0.1,0.2,0.1l0.1,0.1l0.4,0.5c0,0,0,0.1,0.1,0.1c0,0,0,0.1,0,0.2
                              s-0.1,0.2-0.2,0.3c-0.5,0.5-0.9,1.1-1.2,2c-0.3,0.8-0.4,2.1-0.4,3.7v2.1c0,1.6,0.1,2.8,0.4,3.7c0.3,0.8,0.7,1.5,1.2,2
                              c0.1,0.1,0.2,0.2,0.2,0.3s0,0.2,0,0.3l-0.1,0.1l-0.4,0.5l0,0c0,0,0,0-0.1,0.1l-0.1,0.1c0,0-0.1,0-0.2,0s-0.1,0-0.2-0.1
                              c-0.2-0.2-0.5-0.4-0.7-0.7s-0.4-0.6-0.6-1s-0.3-0.8-0.5-1.3c-0.1-0.5-0.2-1-0.3-1.7S87.9,150.3,87.9,149.6z"/>
                            <path fill="#81C5FC" d="M93.1,141.5c0-0.1,0-0.2,0-0.3l0.1-0.1l0.4-0.5l0,0c0,0,0,0,0.1-0.1s0.1-0.1,0.1-0.1s0.1,0,0.2,0
                              s0.1,0,0.2,0.1c0.2,0.2,0.5,0.4,0.7,0.7s0.4,0.6,0.6,1s0.3,0.8,0.5,1.3c0.1,0.5,0.2,1,0.3,1.7s0.1,1.4,0.1,2.1v2.1
                              c0,0.8,0,1.5-0.1,2.1s-0.2,1.2-0.3,1.7c-0.1,0.5-0.3,0.9-0.5,1.3c-0.2,0.4-0.4,0.7-0.6,1s-0.4,0.5-0.7,0.7
                              c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.2-0.1-0.2-0.1l-0.1-0.1l-0.4-0.5c0,0,0-0.1-0.1-0.1c0,0,0-0.1,0-0.2c0-0.1,0.1-0.2,0.2-0.3
                              c0.5-0.5,0.9-1.1,1.2-2c0.3-0.8,0.4-2.1,0.4-3.7v-2.1c0-1.6-0.1-2.8-0.4-3.7c-0.3-0.8-0.7-1.5-1.2-2
                              C93.2,141.7,93.2,141.6,93.1,141.5z"/>
                          </g>
                          <g>
                            <path fill="#D292FF" d="M27.2,58.1v-8.1c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1h0.6c0.1,0,0.2,0,0.3,0.1
                              c0.1,0.1,0.1,0.2,0.1,0.3v8.1c0,0.2,0,0.3,0,0.4c0,0.1,0.1,0.2,0.1,0.4c0.1,0.1,0.2,0.2,0.3,0.3s0.3,0.1,0.6,0.1H30
                              c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3v0.4c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0.1h-0.3c-0.8,0-1.5-0.2-1.9-0.7
                              C27.4,59.5,27.2,58.9,27.2,58.1z"/>
                            <path fill="#D292FF" d="M31.4,58.1v-2.8c0-0.8,0.2-1.5,0.7-2c0.5-0.5,1.1-0.8,2-0.8c0.7,0,1.3,0.2,1.8,0.6s0.7,1,0.7,1.6v1.2
                              c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.3,0.3-0.6,0.3l-3.1,0.6v0.6c0,0.3,0.1,0.6,0.3,0.9c0.2,0.2,0.5,0.4,0.9,0.4
                              c0.4,0,0.7-0.1,0.9-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.2-0.1l0.1,0h0.5c0.3,0,0.5,0.2,0.5,0.5
                              c0,0.7-0.2,1.3-0.7,1.8c-0.5,0.5-1.1,0.7-1.9,0.7c-0.8,0-1.4-0.2-1.9-0.7C31.7,59.4,31.4,58.8,31.4,58.1z M32.9,56.2l2.4-0.4v-0.8
                              c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.5-0.2-0.8-0.2c-0.4,0-0.7,0.1-0.9,0.4c-0.2,0.2-0.3,0.6-0.3,0.9V56.2z"/>
                            <path fill="#D292FF" d="M38.3,53.7v-0.4c0,0,0,0,0,0s0-0.1,0-0.1c0-0.1,0-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.2,0h0.3
                              v-2.5c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.1-0.1,0.2-0.1l0.1,0h0.5c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.1,0.1
                              c0,0,0.1,0.1,0.1,0.1s0,0.1,0,0.2v2.5h1.2c0.1,0,0.2,0,0.3,0.1s0.1,0.1,0.1,0.2l0,0.1v0.4c0,0,0,0,0,0c0,0,0,0.1,0,0.1
                              c0,0.1,0,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.1,0-0.2,0h-1.2V58c0,0.4,0.1,0.7,0.3,0.9c0.2,0.2,0.5,0.3,0.8,0.3h0.3
                              c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.1,0.1,0.2l0,0.1v0.4c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0.1,0,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1
                              c-0.1,0-0.1,0-0.2,0h-0.3c-0.8,0-1.5-0.2-1.9-0.6c-0.4-0.4-0.6-1-0.6-1.9v-3.9h-0.3c-0.1,0-0.2,0-0.3-0.1
                              c-0.1-0.1-0.1-0.1-0.1-0.2L38.3,53.7z"/>
                            <path fill="#D4E4F4" d="M47.2,58.1v-2.8c0-0.8,0.2-1.5,0.7-2s1.1-0.8,2-0.8c0.7,0,1.3,0.2,1.8,0.6s0.7,1,0.7,1.6v1.2
                              c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.3,0.3-0.6,0.3l-3.1,0.6v0.6c0,0.3,0.1,0.6,0.3,0.9c0.2,0.2,0.5,0.4,0.9,0.4
                              c0.4,0,0.7-0.1,0.9-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.2-0.1l0.1,0h0.5c0.3,0,0.5,0.2,0.5,0.5
                              c0,0.7-0.2,1.3-0.7,1.8c-0.5,0.5-1.1,0.7-1.9,0.7c-0.8,0-1.4-0.2-1.9-0.7C47.4,59.4,47.2,58.8,47.2,58.1z M48.6,56.2l2.4-0.4v-0.8
                              c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.5-0.2-0.8-0.2c-0.4,0-0.7,0.1-0.9,0.4c-0.2,0.2-0.3,0.6-0.3,0.9V56.2z"/>
                            <path fill="#D4E4F4" d="M54.6,58.1v-8.1c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1h0.6c0.1,0,0.2,0,0.3,0.1
                              c0.1,0.1,0.1,0.2,0.1,0.3v8.1c0,0.2,0,0.3,0,0.4c0,0.1,0.1,0.2,0.1,0.4c0.1,0.1,0.2,0.2,0.3,0.3s0.3,0.1,0.6,0.1h0.3
                              c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3v0.4c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0.1h-0.3c-0.8,0-1.5-0.2-1.9-0.7
                              C54.8,59.5,54.6,58.9,54.6,58.1z"/>
                            <path fill="#D4E4F4" d="M62.1,55.3v-0.5c0-0.1,0-0.2,0.1-0.3s0.2-0.1,0.3-0.1h5.2c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3
                              v0.5c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0.1h-5.2c-0.1,0-0.2,0-0.3-0.1C62.2,55.5,62.1,55.4,62.1,55.3z M62.1,57.9v-0.4
                              c0-0.1,0-0.2,0.1-0.3s0.2-0.1,0.3-0.1h5.2c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3v0.4c0,0.1,0,0.2-0.1,0.3
                              c-0.1,0.1-0.2,0.1-0.3,0.1h-5.2c-0.1,0-0.2,0-0.3-0.1C62.2,58.1,62.1,58,62.1,57.9z"/>
                            <path fill="#D4E4F4" d="M73.3,58.1v-2.8c0-0.8,0.2-1.5,0.7-2s1.1-0.8,2-0.8c0.7,0,1.3,0.2,1.8,0.6s0.7,1,0.7,1.6v1.2
                              c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.3,0.3-0.6,0.3l-3.1,0.6v0.6c0,0.3,0.1,0.6,0.3,0.9c0.2,0.2,0.5,0.4,0.9,0.4
                              c0.4,0,0.7-0.1,0.9-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.2-0.1l0.1,0H78c0.3,0,0.5,0.2,0.5,0.5
                              c0,0.7-0.2,1.3-0.7,1.8c-0.5,0.5-1.1,0.7-1.9,0.7c-0.8,0-1.4-0.2-1.9-0.7C73.5,59.4,73.3,58.8,73.3,58.1z M74.7,56.2l2.4-0.4v-0.8
                              c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.5-0.2-0.8-0.2c-0.4,0-0.7,0.1-0.9,0.4c-0.2,0.2-0.3,0.6-0.3,0.9V56.2z"/>
                            <path fill="#D4E4F4" d="M80.7,58.1v-8.1c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1h0.6c0.1,0,0.2,0,0.3,0.1
                              c0.1,0.1,0.1,0.2,0.1,0.3v8.1c0,0.2,0,0.3,0,0.4c0,0.1,0.1,0.2,0.1,0.4c0.1,0.1,0.2,0.2,0.3,0.3s0.3,0.1,0.6,0.1h0.3
                              c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3v0.4c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0.1h-0.3c-0.8,0-1.5-0.2-1.9-0.7
                              C81,59.5,80.7,58.9,80.7,58.1z"/>
                            <path fill="#D4E4F4" d="M84.7,60.1v-0.7c0-0.1,0-0.2,0.1-0.3C84.9,59,85,59,85.1,59h0.7c0.1,0,0.2,0,0.3,0.1
                              c0.1,0.1,0.1,0.2,0.1,0.3v0.7c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0.1h-0.7c-0.1,0-0.2,0-0.3-0.1S84.7,60.2,84.7,60.1z"/>
                            <path fill="#D4E4F4" d="M87.7,58v-2.6c0-0.4,0-0.7,0.1-1.1s0.2-0.6,0.4-0.9s0.4-0.5,0.7-0.6c0.3-0.1,0.7-0.2,1.1-0.2
                              c0.2,0,0.5,0.1,0.8,0.2c0.3,0.1,0.5,0.3,0.6,0.5v-0.2c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.1-0.1,0.2-0.1l0.1,0h0.6c0.1,0,0.2,0,0.3,0.1
                              s0.1,0.2,0.1,0.3v10.3c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0.1H92c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.3v-3.4
                              c-0.1,0.2-0.4,0.4-0.6,0.5c-0.3,0.1-0.6,0.2-0.9,0.2c-0.3,0-0.6-0.1-0.8-0.2c-0.3-0.1-0.5-0.3-0.7-0.5c-0.2-0.2-0.4-0.5-0.5-0.8
                              C87.8,58.8,87.7,58.4,87.7,58z M89.1,58c0,0.4,0.1,0.8,0.3,1c0.2,0.2,0.4,0.3,0.7,0.3c0.4,0,0.8-0.2,1-0.5c0.2-0.3,0.4-0.6,0.4-1
                              v-2.6c0-0.5-0.1-0.8-0.4-1c-0.2-0.2-0.5-0.3-0.9-0.3c-0.8,0-1.1,0.4-1.1,1.3V58z"/>
                            <path fill="#D4E4F4" d="M95.1,57.9v-4.7c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1h0.6c0.1,0,0.2,0,0.3,0.1
                              c0.1,0.1,0.1,0.2,0.1,0.3V58c0,0.4,0.1,0.8,0.3,1c0.2,0.2,0.5,0.3,0.9,0.3c0.4,0,0.7-0.1,0.9-0.3c0.2-0.2,0.4-0.6,0.4-1v-4.8
                              c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1h0.6c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3v6.9c0,0.1,0,0.2-0.1,0.3
                              c-0.1,0.1-0.2,0.1-0.3,0.1h-0.5c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.3v-0.1c-0.2,0.2-0.4,0.4-0.6,0.5
                              c-0.3,0.1-0.5,0.2-0.8,0.2c-0.7,0-1.3-0.2-1.8-0.7S95.1,58.7,95.1,57.9z"/>
                            <path fill="#D4E4F4" d="M102.5,58.1v-2.8c0-0.8,0.2-1.5,0.7-2s1.1-0.8,2-0.8c0.7,0,1.3,0.2,1.8,0.6s0.7,1,0.7,1.6v1.2
                              c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.3,0.3-0.6,0.3l-3.1,0.6v0.6c0,0.3,0.1,0.6,0.3,0.9c0.2,0.2,0.5,0.4,0.9,0.4
                              c0.4,0,0.7-0.1,0.9-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.2-0.1l0.1,0h0.5c0.3,0,0.5,0.2,0.5,0.5
                              c0,0.7-0.2,1.3-0.7,1.8c-0.5,0.5-1.1,0.7-1.9,0.7c-0.8,0-1.4-0.2-1.9-0.7C102.7,59.4,102.5,58.8,102.5,58.1z M103.9,56.2l2.4-0.4
                              v-0.8c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.5-0.2-0.8-0.2c-0.4,0-0.7,0.1-0.9,0.4c-0.2,0.2-0.3,0.6-0.3,0.9V56.2z"/>
                            <path fill="#D4E4F4" d="M109.9,60.1v-6.9c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1h0.6c0.1,0,0.2,0,0.3,0.1
                              c0.1,0.1,0.1,0.2,0.1,0.3v0.1c0.3-0.3,0.5-0.4,0.7-0.5s0.5-0.1,1-0.1c0.1,0,0.2,0,0.3,0.1s0.1,0.2,0.1,0.3v0.4
                              c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0.1c-0.3,0-0.6,0-0.8,0.1c-0.2,0.1-0.4,0.2-0.5,0.3c-0.1,0.1-0.2,0.3-0.2,0.4
                              c0,0.1-0.1,0.3-0.1,0.5v4.8c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0.1h-0.6c-0.1,0-0.2,0-0.3-0.1
                              C109.9,60.3,109.9,60.2,109.9,60.1z"/>
                            <path fill="#D4E4F4" d="M113.5,53.2c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0.1-0.2c0.1-0.1,0.2-0.1,0.3-0.1h0.7c0.3,0,0.4,0.2,0.5,0.5
                              l1.4,5.3l0,0l1.5-5.3c0.1-0.3,0.2-0.4,0.5-0.4h0.7c0.1,0,0.2,0,0.3,0.1c0,0,0.1,0.1,0.1,0.2c0,0,0,0.1,0,0.1l-2.6,8.8
                              c-0.2,0.7-0.5,1.2-0.9,1.5s-0.9,0.4-1.6,0.4c-0.1,0-0.2,0-0.3-0.1s-0.1-0.2-0.1-0.2l0-0.1v-0.5c0,0,0,0,0,0c0,0,0-0.1,0-0.1
                              c0-0.1,0-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.2,0c0.6,0,1-0.2,1.1-0.6l0.3-1.2l-0.1,0c-0.1,0-0.2-0.1-0.3-0.2
                              L113.5,53.2z"/>
                            <path fill="#D4E4F4" d="M120.5,57.4c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.2-0.1l0.1,0h0.7c0,0,0,0,0,0c0,0,0.1,0,0.1,0
                              c0.1,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0.1,0,0.1,0,0.2c0,0.3,0,0.6,0.1,0.9s0.2,0.4,0.4,0.5s0.3,0.2,0.5,0.3
                              c0.2,0.1,0.4,0.1,0.6,0.1c0.6,0,1-0.2,1.3-0.5s0.4-0.7,0.4-1.1c0-0.6-0.3-1-1-1.4l-2.2-1.2c-0.3-0.2-0.6-0.4-0.9-0.6
                              c-0.2-0.2-0.4-0.5-0.5-0.7s-0.2-0.5-0.2-0.7s-0.1-0.4-0.1-0.7c0-0.6,0.1-1.1,0.4-1.5s0.7-0.8,1.1-1s1-0.3,1.5-0.3
                              c0.9,0,1.6,0.2,2.2,0.7c0.5,0.5,0.8,1.1,1,1.9c0,0,0,0.1,0,0.1c0,0.2-0.1,0.3-0.3,0.4l-0.5,0.1c-0.2,0-0.3,0-0.4,0
                              c-0.1-0.1-0.2-0.2-0.2-0.3c-0.1-0.5-0.3-0.8-0.6-1c-0.3-0.2-0.6-0.3-1.1-0.3c-0.5,0-0.9,0.1-1.2,0.4c-0.3,0.3-0.4,0.6-0.4,0.9
                              c0,0.6,0.2,1,0.7,1.2l2.7,1.4c0.5,0.2,0.8,0.6,1.1,1.1c0.3,0.5,0.4,1,0.4,1.5c0,0.9-0.3,1.6-0.9,2.2s-1.4,0.9-2.4,0.9
                              c-0.3,0-0.5,0-0.8-0.1c-0.3-0.1-0.6-0.2-0.8-0.3c-0.3-0.2-0.5-0.4-0.8-0.6s-0.4-0.5-0.5-0.9S120.5,57.9,120.5,57.4z"/>
                            <path fill="#D4E4F4" d="M128.7,58.1v-2.8c0-0.8,0.2-1.5,0.7-2s1.1-0.8,2-0.8c0.7,0,1.3,0.2,1.8,0.6s0.7,1,0.7,1.6v1.2
                              c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.3,0.3-0.6,0.3l-3.1,0.6v0.6c0,0.3,0.1,0.6,0.3,0.9c0.2,0.2,0.5,0.4,0.9,0.4
                              c0.4,0,0.7-0.1,0.9-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.2-0.1l0.1,0h0.5c0.3,0,0.5,0.2,0.5,0.5
                              c0,0.7-0.2,1.3-0.7,1.8c-0.5,0.5-1.1,0.7-1.9,0.7c-0.8,0-1.4-0.2-1.9-0.7C128.9,59.4,128.7,58.8,128.7,58.1z M130.1,56.2l2.4-0.4
                              v-0.8c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.5-0.2-0.8-0.2c-0.4,0-0.7,0.1-0.9,0.4c-0.2,0.2-0.3,0.6-0.3,0.9V56.2z"/>
                            <path fill="#D4E4F4" d="M136.1,58.1v-8.1c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1h0.6c0.1,0,0.2,0,0.3,0.1
                              c0.1,0.1,0.1,0.2,0.1,0.3v8.1c0,0.2,0,0.3,0,0.4c0,0.1,0.1,0.2,0.1,0.4c0.1,0.1,0.2,0.2,0.3,0.3s0.3,0.1,0.6,0.1h0.3
                              c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3v0.4c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0.1h-0.3c-0.8,0-1.5-0.2-1.9-0.7
                              C136.4,59.5,136.1,58.9,136.1,58.1z"/>
                            <path fill="#D4E4F4" d="M140.4,58.1v-2.8c0-0.8,0.2-1.5,0.7-2s1.1-0.8,2-0.8c0.7,0,1.3,0.2,1.8,0.6s0.7,1,0.7,1.6v1.2
                              c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.3,0.3-0.6,0.3l-3.1,0.6v0.6c0,0.3,0.1,0.6,0.3,0.9c0.2,0.2,0.5,0.4,0.9,0.4
                              c0.4,0,0.7-0.1,0.9-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.2-0.1l0.1,0h0.5c0.3,0,0.5,0.2,0.5,0.5
                              c0,0.7-0.2,1.3-0.7,1.8c-0.5,0.5-1.1,0.7-1.9,0.7c-0.8,0-1.4-0.2-1.9-0.7C140.6,59.4,140.4,58.8,140.4,58.1z M141.8,56.2l2.4-0.4
                              v-0.8c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.5-0.2-0.8-0.2c-0.4,0-0.7,0.1-0.9,0.4c-0.2,0.2-0.3,0.6-0.3,0.9V56.2z"/>
                            <path fill="#D4E4F4" d="M147.5,57.8v-2.3c0-0.5,0.1-1,0.2-1.4c0.2-0.4,0.4-0.7,0.6-0.9s0.5-0.4,0.8-0.5c0.3-0.1,0.6-0.2,0.9-0.2
                              c0.3,0,0.5,0,0.8,0.1c0.2,0.1,0.5,0.2,0.7,0.3c0.2,0.1,0.4,0.3,0.6,0.6c0.2,0.3,0.3,0.5,0.4,0.9c0,0.1,0,0.1,0,0.2
                              c0,0.1,0,0.1-0.1,0.2c-0.1,0.1-0.1,0.1-0.3,0.1l-0.5,0.1c-0.1,0-0.1,0-0.2,0c-0.2,0-0.3-0.1-0.4-0.4c-0.1-0.5-0.5-0.8-1.1-0.8
                              c-0.4,0-0.7,0.1-0.9,0.4c-0.2,0.3-0.3,0.7-0.3,1.2v2.3c0,1,0.4,1.5,1.2,1.5c0.4,0,0.7-0.1,0.9-0.3c0.2-0.2,0.3-0.5,0.3-0.8
                              c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.1-0.1,0.2-0.1l0.1,0h0.5c0.3,0,0.5,0.2,0.5,0.5c0,0.4-0.1,0.8-0.3,1.2c-0.2,0.3-0.4,0.6-0.7,0.8
                              c-0.3,0.2-0.5,0.3-0.8,0.4c-0.3,0.1-0.6,0.1-0.9,0.1c-0.9,0-1.5-0.2-2-0.7S147.5,58.8,147.5,57.8z"/>
                            <path fill="#D4E4F4" d="M154.2,53.7v-0.4c0,0,0,0,0,0s0-0.1,0-0.1c0-0.1,0-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.2,0h0.3
                              v-2.5c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.1-0.1,0.2-0.1l0.1,0h0.5c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.1,0.1
                              c0,0,0.1,0.1,0.1,0.1s0,0.1,0,0.2v2.5h1.2c0.1,0,0.2,0,0.3,0.1s0.1,0.1,0.1,0.2l0,0.1v0.4c0,0,0,0,0,0c0,0,0,0.1,0,0.1
                              c0,0.1,0,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.1,0-0.2,0h-1.2V58c0,0.4,0.1,0.7,0.3,0.9c0.2,0.2,0.5,0.3,0.8,0.3h0.3
                              c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.1,0.1,0.2l0,0.1v0.4c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0.1,0,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1
                              c-0.1,0-0.1,0-0.2,0h-0.3c-0.8,0-1.5-0.2-1.9-0.6c-0.4-0.4-0.6-1-0.6-1.9v-3.9h-0.3c-0.1,0-0.2,0-0.3-0.1
                              c-0.1-0.1-0.1-0.1-0.1-0.2L154.2,53.7z"/>
                            <path fill="#D4E4F4" d="M159.5,57.8v-2.3c0-1,0.2-1.7,0.7-2.2s1.1-0.7,1.9-0.7c0.8,0,1.5,0.2,1.9,0.7c0.5,0.5,0.7,1.2,0.7,2.2v2.3
                              c0,0.9-0.2,1.6-0.7,2s-1.1,0.7-1.9,0.7h0c-0.8,0-1.4-0.2-1.9-0.7C159.7,59.4,159.5,58.7,159.5,57.8z M160.9,57.8
                              c0,1,0.4,1.5,1.2,1.5c0.8,0,1.2-0.5,1.2-1.5v-2.3c0-0.5-0.1-0.9-0.3-1.2s-0.5-0.4-0.9-0.4c-0.4,0-0.8,0.1-0.9,0.4
                              c-0.2,0.3-0.3,0.7-0.3,1.2V57.8z"/>
                            <path fill="#D4E4F4" d="M166.7,60.1v-6.9c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1h0.6c0.1,0,0.2,0,0.3,0.1
                              c0.1,0.1,0.1,0.2,0.1,0.3v0.1c0.3-0.3,0.5-0.4,0.7-0.5s0.5-0.1,1-0.1c0.1,0,0.2,0,0.3,0.1s0.1,0.2,0.1,0.3v0.4
                              c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0.1c-0.3,0-0.6,0-0.8,0.1c-0.2,0.1-0.4,0.2-0.5,0.3c-0.1,0.1-0.2,0.3-0.2,0.4
                              c0,0.1-0.1,0.3-0.1,0.5v4.8c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0.1h-0.6c-0.1,0-0.2,0-0.3-0.1
                              C166.7,60.3,166.7,60.2,166.7,60.1z"/>
                            <path fill="#D4E4F4" d="M170.8,56v-2.1c0-0.8,0-1.5,0.1-2.1c0.1-0.7,0.2-1.2,0.3-1.7s0.3-0.9,0.5-1.3c0.2-0.4,0.4-0.7,0.6-1
                              c0.2-0.3,0.4-0.5,0.7-0.7c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2,0.1,0.2,0.1l0.1,0.1l0.4,0.5c0,0,0,0.1,0.1,0.1s0,0.1,0,0.2
                              c0,0.1-0.1,0.2-0.2,0.3c-0.5,0.5-0.9,1.1-1.2,2s-0.4,2.1-0.4,3.7V56c0,1.6,0.1,2.8,0.4,3.7s0.7,1.5,1.2,2c0.1,0.1,0.2,0.2,0.2,0.3
                              c0,0.1,0,0.2,0,0.3l-0.1,0.1l-0.4,0.5c0,0,0,0,0,0c0,0,0,0-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2-0.1
                              c-0.2-0.2-0.5-0.4-0.7-0.7s-0.4-0.6-0.6-1c-0.2-0.4-0.3-0.8-0.5-1.3s-0.2-1-0.3-1.7C170.9,57.5,170.8,56.8,170.8,56z"/>
                            <path fill="#66FFB9" d="M177,49.8l-0.3,3.7h-0.8l-0.2-3.7H177z"/>
                            <path fill="#66FFB9" d="M178.3,58.4l0-0.1l0.1-0.5c0,0,0-0.1,0-0.1s0.1-0.1,0.1-0.2c0.1-0.1,0.2-0.1,0.3-0.1h1.5l0.5-2h-1.8
                              c-0.2,0-0.3,0-0.4-0.1c-0.1-0.1-0.1-0.1-0.1-0.2l0-0.1l0.1-0.5c0,0,0-0.1,0-0.1s0.1-0.1,0.1-0.2c0.1-0.1,0.2-0.1,0.3-0.1h1.9
                              l0.5-1.9c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.1,0.2-0.1h0.1h0.5c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.1,0.1
                              c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2,0,0.3l-0.4,1.8h2l0.5-1.9c0-0.1,0.1-0.2,0.2-0.3s0.2-0.1,0.2-0.1h0.1h0.5c0,0,0,0,0,0
                              c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.1,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2,0,0.3l-0.4,1.8h1.6c0.2,0,0.3,0,0.4,0.1
                              c0.1,0.1,0.1,0.1,0.1,0.2l0,0.1l-0.1,0.5c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.1,0-0.2,0
                              h-1.8l-0.5,2h2c0.2,0,0.3,0,0.4,0.1c0.1,0.1,0.1,0.1,0.1,0.2l0,0.1l-0.1,0.5c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0-0.1,0.1-0.1,0.1
                              s-0.1,0.1-0.1,0.1c-0.1,0-0.1,0-0.2,0h-2.2l-0.5,1.9c0,0.1-0.1,0.2-0.2,0.3C184.1,61,184,61,184,61h-0.1h-0.5c0,0,0,0,0,0
                              c0,0-0.1,0-0.1,0s-0.1,0-0.1-0.1c0,0,0-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3l0.4-1.7h-2l-0.5,1.9c0,0.1-0.1,0.2-0.2,0.3
                              c-0.1,0.1-0.2,0.1-0.2,0.1h-0.1h-0.5c0,0,0,0,0,0c0,0-0.1,0-0.1,0s-0.1,0-0.1-0.1c0,0,0-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3l0.4-1.7
                              h-1.4c-0.2,0-0.3,0-0.4-0.1C178.3,58.6,178.3,58.5,178.3,58.4z M181.8,57.4h2l0.5-2h-2L181.8,57.4z"/>
                            <path fill="#66FFB9" d="M189.2,53.7v-0.4c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1h0.3v-0.8c0-0.8,0.2-1.4,0.6-1.8
                              s1-0.7,1.9-0.7h0.3c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3v0.4c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0.1h-0.3
                              c-0.2,0-0.4,0-0.6,0.1s-0.3,0.2-0.3,0.3c-0.1,0.1-0.1,0.2-0.1,0.4c0,0.1,0,0.3,0,0.4v0.8h1.3c0.1,0,0.2,0,0.3,0.1
                              c0.1,0.1,0.1,0.2,0.1,0.3v0.4c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0.1h-1.3v5.9c0,0.1,0,0.2-0.1,0.3
                              c-0.1,0.1-0.2,0.1-0.3,0.1h-0.6c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.3v-5.9h-0.3c-0.1,0-0.2,0-0.3-0.1
                              S189.2,53.9,189.2,53.7z"/>
                            <path fill="#66FFB9" d="M193.9,58.4c0-0.6,0.1-1.1,0.4-1.5c0.3-0.3,0.8-0.6,1.5-0.8l1.8-0.5V55c0-0.3-0.1-0.6-0.3-0.8
                              c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3,0-0.6,0.1-0.8,0.2c-0.2,0.2-0.3,0.3-0.3,0.6c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0.1h-0.6
                              c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.6,0.2-1.2,0.7-1.5s1.1-0.6,1.8-0.6c0.3,0,0.7,0.1,1,0.2s0.6,0.3,0.8,0.5
                              c0.2,0.2,0.4,0.5,0.6,0.9c0.1,0.4,0.2,0.8,0.2,1.2v4.7c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0.1h-0.5c-0.1,0-0.2,0-0.3-0.1
                              c-0.1-0.1-0.1-0.2-0.1-0.3v-0.1c-0.1,0.2-0.4,0.4-0.6,0.5c-0.3,0.1-0.6,0.2-0.9,0.2c-0.6,0-1.2-0.2-1.6-0.7
                              C194.1,59.5,193.9,59,193.9,58.4z M195.3,58.3c0,0.3,0.1,0.5,0.3,0.7s0.4,0.3,0.7,0.3c0.4,0,0.7-0.2,1-0.5c0.3-0.3,0.4-0.7,0.4-1
                              V57l-1.6,0.4C195.5,57.5,195.3,57.8,195.3,58.3z"/>
                            <path fill="#66FFB9" d="M201,57.8v-2.3c0-0.5,0.1-1,0.2-1.4c0.2-0.4,0.4-0.7,0.6-0.9s0.5-0.4,0.8-0.5c0.3-0.1,0.6-0.2,0.9-0.2
                              c0.3,0,0.5,0,0.8,0.1c0.2,0.1,0.5,0.2,0.7,0.3c0.2,0.1,0.4,0.3,0.6,0.6c0.2,0.3,0.3,0.5,0.4,0.9c0,0.1,0,0.1,0,0.2
                              c0,0.1,0,0.1-0.1,0.2c-0.1,0.1-0.1,0.1-0.3,0.1l-0.5,0.1c-0.1,0-0.1,0-0.2,0c-0.2,0-0.3-0.1-0.4-0.4c-0.1-0.5-0.5-0.8-1.1-0.8
                              c-0.4,0-0.7,0.1-0.9,0.4c-0.2,0.3-0.3,0.7-0.3,1.2v2.3c0,1,0.4,1.5,1.2,1.5c0.4,0,0.7-0.1,0.9-0.3c0.2-0.2,0.3-0.5,0.3-0.8
                              c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.1-0.1,0.2-0.1l0.1,0h0.5c0.3,0,0.5,0.2,0.5,0.5c0,0.4-0.1,0.8-0.3,1.2c-0.2,0.3-0.4,0.6-0.7,0.8
                              c-0.3,0.2-0.5,0.3-0.8,0.4c-0.3,0.1-0.6,0.1-0.9,0.1c-0.9,0-1.5-0.2-2-0.7S201,58.8,201,57.8z"/>
                            <path fill="#66FFB9" d="M208.1,58.1v-2.8c0-0.8,0.2-1.5,0.7-2s1.1-0.8,2-0.8c0.7,0,1.3,0.2,1.8,0.6s0.7,1,0.7,1.6v1.2
                              c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.3,0.3-0.6,0.3l-3.1,0.6v0.6c0,0.3,0.1,0.6,0.3,0.9c0.2,0.2,0.5,0.4,0.9,0.4
                              c0.4,0,0.7-0.1,0.9-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.2-0.1l0.1,0h0.5c0.3,0,0.5,0.2,0.5,0.5
                              c0,0.7-0.2,1.3-0.7,1.8c-0.5,0.5-1.1,0.7-1.9,0.7c-0.8,0-1.4-0.2-1.9-0.7C208.3,59.4,208.1,58.8,208.1,58.1z M209.5,56.2l2.4-0.4
                              v-0.8c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.5-0.2-0.8-0.2c-0.4,0-0.7,0.1-0.9,0.4c-0.2,0.2-0.3,0.6-0.3,0.9V56.2z"/>
                            <path fill="#66FFB9" d="M216.4,49.8l-0.3,3.7h-0.8l-0.2-3.7H216.4z"/>
                            <path fill="#D4E4F4" d="M218.5,48c0-0.1,0-0.2,0-0.3l0.1-0.1l0.4-0.5c0,0,0,0,0,0c0,0,0,0,0.1-0.1c0,0,0.1-0.1,0.1-0.1
                              c0,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0.1c0.2,0.2,0.5,0.4,0.7,0.7c0.2,0.3,0.4,0.6,0.6,1c0.2,0.4,0.3,0.8,0.5,1.3s0.2,1,0.3,1.7
                              c0.1,0.7,0.1,1.4,0.1,2.1V56c0,0.8,0,1.5-0.1,2.1c-0.1,0.7-0.2,1.2-0.3,1.7s-0.3,0.9-0.5,1.3c-0.2,0.4-0.4,0.7-0.6,1
                              s-0.4,0.5-0.7,0.7c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.2-0.1-0.2-0.1l-0.1-0.1l-0.4-0.5c0,0,0-0.1-0.1-0.1c0,0,0-0.1,0-0.2
                              c0-0.1,0.1-0.2,0.2-0.3c0.5-0.5,0.9-1.1,1.2-2c0.3-0.8,0.4-2.1,0.4-3.7v-2.1c0-1.6-0.1-2.8-0.4-3.7c-0.3-0.8-0.7-1.5-1.2-2
                              C218.5,48.2,218.5,48.1,218.5,48z"/>
                          </g>
                          <g>
                            <path fill="#D4E4F4" d="M218.7,79.9v-2.8c0-0.8,0.2-1.5,0.7-2s1.1-0.8,2-0.8c0.7,0,1.3,0.2,1.8,0.6s0.7,1,0.7,1.6v1.2
                              c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.3,0.3-0.6,0.3l-3.1,0.6v0.6c0,0.3,0.1,0.6,0.3,0.9c0.2,0.2,0.5,0.4,0.9,0.4
                              c0.4,0,0.7-0.1,0.9-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.2-0.1l0.1,0h0.5c0.3,0,0.5,0.2,0.5,0.5
                              c0,0.7-0.2,1.3-0.7,1.8c-0.5,0.5-1.1,0.7-1.9,0.7c-0.8,0-1.4-0.2-1.9-0.7C218.9,81.3,218.7,80.7,218.7,79.9z M220.1,78l2.4-0.4
                              v-0.8c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.5-0.2-0.8-0.2c-0.4,0-0.7,0.1-0.9,0.4c-0.2,0.2-0.3,0.6-0.3,0.9V78z"/>
                            <path fill="#D4E4F4" d="M226.1,80v-8.1c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1h0.6c0.1,0,0.2,0,0.3,0.1
                              c0.1,0.1,0.1,0.2,0.1,0.3V80c0,0.2,0,0.3,0,0.4c0,0.1,0.1,0.2,0.1,0.4c0.1,0.1,0.2,0.2,0.3,0.3s0.3,0.1,0.6,0.1h0.3
                              c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3V82c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0.1h-0.3c-0.8,0-1.5-0.2-1.9-0.7
                              C226.3,81.4,226.1,80.8,226.1,80z"/>
                          </g>
                          <g>
                            <path fill="#D4E4F4" d="M232.3,82c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1h0.7c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0,0.4
                              l-0.6,2.5c0,0.2-0.1,0.3-0.1,0.4c-0.1,0.1-0.2,0.1-0.3,0.1h-0.1c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.3V82z"/>
                          </g>
                        </g>
                        </svg>

                      </div>
                    <p>
                Download the zip, containing your animation code.
                <span>Html and JS included <br/> Boom!</span>
              </p>
            </div> 
        </div>
    </div> 
  </section> 

</template>

<script>

import Raf from '@/helpers/Raf'
import { mapState } from 'vuex';
import ResizeService from '@/services/ResizeService';  


export default {

    name: 'getting-started',

    data () {
      return { }
    },

    computed: {},

    components: {},

    mounted () { 
    },

    methods:{

      setup() { 
      },

      enter(){ 
      },
      
      leave(){},

      onClick() {}
    
    },

    watch: {} 
} 
</script>


<style scoped lang="sass">

  #how-it-works
    background: #f7f3ed;
    padding: 14rem 0 14rem;

    .intro
      width: 60rem;
      margin: 0 auto;
      width: 70rem;
      line-height: 1.4;

    header
      margin-bottom: 10px;
      max-width: 600px;
      margin: 0 auto 3rem;

    h1
      font-size: 13rem;
      margin: 0px;
      color: #cc2d8a;
      line-height: 0.8;

      @media only screen and (max-width : 600px)
        font-size: 10rem;

    .sub-title
      font-size: 2.8em;
      margin: 0px;
      color: #febfbf;

    .steps
      width: 100%; 
      padding: 7rem 5rem 0;
      @media only screen and (max-width : 1000px)
        padding: 4rem 2rem 0;

      .inner
        display: flex; 
        flex-direction: row;
        position: relative;
        max-width: 1300px;
        width: 100%; 
        margin: 0 auto;
        @media only screen and (max-width : 600px)
          flex-direction: column;
          align-items: center;


        .spacer
          width: 100%;
          position: relative;
          @media only screen and (max-width : 1000px)
            width: 20%;

          .how-to-arrow
            background: url(~assets/img/arrow.svg) right no-repeat;
            background-size: cover;
            width: 100%;
            height: 70px;
            top: 47px;
            position: absolute;
            display: block;
            @media only screen and (max-width : 1000px)
              display: none;

          .file
            background: url(~assets/img/paper.svg) left no-repeat; 
            width: 84px; 
            height: 180px; 
            left: 50%; 
            position: absolute; 
            margin-left: -50px;
            display: flex;
            align-items: center;
            justify-content: center;
            @media only screen and (max-width : 1000px) 
              display: none;

            p
              color: #a97f7f; 
              margin-left: -7px;
            
              .content
                width: 100%;
                position: relative;

        .step-two
          width: 100%;

          .fixedWidth
            background: url(~assets/img/how-to_converter.png) no-repeat;

          .drop
            animation: DROP 3s ease-in-out infinite;
            @keyframes DROP
              0%   { transform: translate3d(0, 0, 0); }
              50%   { transform: translate3d(0, 20px, 0); }
              100% { transform: translate3d(0, 0, 0); }


        .step-three
          background: url(~assets/img/code.svg) no-repeat;


        .fixedWidth
          max-width: 262px; 
          position: relative; 
          width: 100%;
          @media only screen and (max-width : 600px)
            max-width: 300px;

          p
            margin-top: 217px; 
            background: #e9e1d9; 
            border-radius: 20px; 
            padding: 1.3rem 1rem 2rem; 
            color: #3d3d3d;
            line-height: 1.6;
            @media only screen and (max-width : 600px)
              margin: 0 0 6rem;

            span
              color: #783030;
              font-size: 1.6rem;
              margin-top: 2rem;
              position: relative;
              display: block;

        .center
          margin-top: -23px;

        .how-to-img
          position: absolute;
          width: 100%;
          @media only screen and (max-width : 600px) 
            position: relative;

        // p
        //   color: #fff2f2;
        //   font-family: 'melbournereg'; 
        //   text-transform: none; 
        //   font-size: 1.2rem; 
        //   letter-spacing: 1px;

 
    p
      color: #3d3d3d;
      font-family: 'melbournereg';
      text-transform: none;
      font-size: 20px;
      letter-spacing: 1px
 

</style> 