import * as mutations from './mutations'
import * as actions from './actions'
import * as getters from './getters'
import 'url-search-params-polyfill'
import hasWebgl from '@/helpers/hasWebgl'

let urlParams = new window.URLSearchParams(window.location.search)
let utmSource = urlParams.get('utm_source') || null
let AudioContext = (window.AudioContext || window.webkitAudioContext)

const state = {
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
    isDesktop: null,
    isMobile: /(iPhone|iPad|iPod|Android)/i.test(navigator.userAgent),
    isIOS: /(iOS|iPod|iPad|iPhone)/i.test(navigator.userAgent),
    isAndroid: /Android/.test(navigator.userAgent),
    isSafari: /Safari/.test(navigator.userAgent),
    isWechat: /micromessenger/i.test(navigator.userAgent),
    isWeibo: /weibo/.test(navigator.userAgent),
    isInstagram: /instagram/.test(navigator.userAgent.toLowerCase()),
    isFacebook: /(fban|fbav)/i.test(navigator.userAgent.toLowerCase()),
    isUCBrowser: /UCBrowser/.test(navigator.userAgent),
    country: null,
    utmSource: utmSource,
    hasWebgl: hasWebgl,
    hasWebAudio: typeof AudioContext === 'function',
    isLowSpec: false, // set in main.js
    locale: 'en-us'
}

state.isDesktop = !state.isMobile;

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}