<template>

  <div id="timeline-scale">
    <ul>
      <li>
        0
        <span class="line"></span>
      </li>
      <li>
        {{getDuration(1)}}
        <span class="line"></span>
      </li>
      <li>
        {{getDuration(2)}}
        <span class="line"></span>
      </li>
      <li>
        {{getDuration(3)}}
        <span class="line"></span>
      </li>
      <li>
        {{getDuration(4)}}
        <span class="line"></span>
      </li>
    </ul>
  </div>

</template>

<script>

import Raf from '@/helpers/Raf'
import { mapState } from 'vuex';
import ResizeService from '@/services/ResizeService';

export default {

    name: 'timescale',

    data () {
      return { }
    },

    computed: {

      ...mapState('composer', [
        'timeline'
      ]) 
    },

    components: {},

    mounted () { 
    },

    methods:{

      getDuration(num){
        return +(((this.timeline.duration / 4) * num) * 0.001).toFixed(1);
      },

      setup() { 
      },

      enter(){ 
      },
      
      leave(){},

      onClick() {}
    
    },

    watch: {} 
} 
</script>


<style scoped lang="sass">

  #timeline-scale
    width: 100%
    height: 3rem
    position: relative

    ul
      display: flex
      justify-content: space-between

      li
        width: 20px
        display: flex
        justify-content: center

        &:first-child
          margin-left: -9px

        &:last-child
          margin-right: -10px

        .line
          position: absolute
          width: 1px
          height: 1rem
          background-color: #e8e1da
          bottom: 0


</style> 