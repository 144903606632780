<template>

  <div id="timeline-bar">
    <!-- <span class="bar-id">{{id}}</span> -->
    <div ref="activeArea" class="active-area"></div>
  </div>

</template>

<script>

import Raf from '@/helpers/Raf'
import Easing from '@/helpers/Easing'
import { mapState } from 'vuex';
import ResizeService from '@/services/ResizeService';
import 'mutationobserver-shim';

export default {

    name: 'timeline-bar',

    data () {
      return {
        delay: 0,
        duration: 0
      }
    },

    props: {
      id: String,
      height:  {
        type: [
           String,
           Number
        ]
      },
      path:  {
        type: [
          SVGPathElement, 
          SVGPolygonElement,
          SVGCircleElement,
          SVGRectElement,
          SVGPolylineElement,
          SVGLineElement,
          SVGEllipseElement
        ]
      }
    },

    computed: {
      ...mapState('composer', [
        'timeline',
        'animation'
      ])
    },

    components: {},

    mounted () {
      this.updatePosition();
      this.$el.style.height = this.height;
    },

    methods:{

      setup() { },

      enter(){ },
      
      leave(){},

      onClick() {},
 
      updatePosition(){ 
        if(this.$refs.activeArea){ 
          let path = this.timeline.timings[this.path.dataset.llpId];
          let d = Math.abs((path.start - path.complete) / this.timeline.duration); 
          let width = this.timeline.width * d;
          let left = this.timeline.width * (path.start / this.timeline.duration);
          this.$refs.activeArea.style.width = width + 'px';
          this.$refs.activeArea.style.left = left + 'px'; 
        }
     },
 
      // getDurationRatio(){ 

      //   let ease = this.getEase()
      //   let ratio = (this.duration / this.timeline.duration) 
      //   let startRatio = ((this.delay + this.animation.delay.current) / this.timeline.duration)
      //   let easedStartRatio = ease(startRatio)
      //   let endRatio = startRatio + ratio;
      //   let easedEndRatio = ease(endRatio)

      //   return (easedEndRatio -  easedStartRatio)
      // },

      // getDelayRatio(){ 
      //   let ratio = ((this.delay + this.animation.delay.current) / this.timeline.duration)
      //   let easedRatio = Easing[this.timeline.ease](ratio)
      //   return easedRatio
      // },


      // getEase(){
      //   return Easing[this.timeline.ease]
      // }
    
    },

    watch: {

      'timeline.width'(){
        this.updatePosition();
      },

      'timeline.timings'(val){
        this.updatePosition(val);
      }

    } 
} 
</script>


<style scoped lang="sass">

  #timeline-bar
    max-height: 1.2rem
    height: 1.5rem
    width: 100%
    background-color: #e8e1da
    margin-bottom: 2px
    cursor: pointer
    transition: background-color 0.3 ease-out
    position: relative

    .bar-id
      position: absolute    

    // &:hover
    //   background-color: #dcd5cd

    &:last-child
      margin-bottom: 0

    .active-area
      height: 100%
      width: 50%
      background: #6f6f6f
      position: absolute
      transition: width 1s ease-in-out, left 1s ease-in-out

</style> 