import * as mutations from './mutations'
import * as actions from './actions'
import * as getters from './getters'
import 'url-search-params-polyfill'

const COMPOSER_STATES = {
  IDLE: 'COMPOSER_STATE:IDLE',
  LOADING: 'COMPOSER_STATE:LOADING',
  EDIT: 'COMPOSER_STATE:EDIT'
}

const state = {

  COMPOSER_STATES: COMPOSER_STATES, 
  composer_state: null,

  download_countdown: true,
  countdown_duration: 15000,

  scale: 1,

  timeline: {

    duration: 0, 
    ease: 'easeLinear', 
    progress: 0,
    width: 0,
    complete: false,
    timings: {},

    paths: null
  },

  animation: {

    progress: 0,

    reverse: false,

    repeat: {
      default: 0,
      current: 0
    },

    draw_seq: true,

    delay: {
      step: 10.0,
      min: 0,
      max: 5000,
      current: 0
    },

    duration: {
      step: 10.0,
      min: 0,
      max: 5000,
      current: 500
    },

    ease: {
      current: 0,
      default: 0,
      options: [
        {
          label : 'Linear',
          index: 0 
        },
        {
          label : 'InQuad',
          index: 1
        },
        {
          label : 'OutQuad',
          index: 2 
        },
        {
          label : 'InOutQuad',
          index: 3 
        },
        {
          label : 'InCubic',
          index: 4 
        },
        {
          label : 'OutCubic',
          index: 5 
        },
        {
          label : 'InOutCubic',
          index: 6 
        },
        {
          label : 'InQuart',
          index: 7 
        },
        {
          label : 'OutQuart',
          index: 8 
        },
        {
          label : 'InOutQuart',
          index: 9 
        },
        {
          label : 'InQuint',
          index: 10
        },
        {
          label : 'OutQuint',
          index: 11
        },
        {
          label : 'InOutQuint',
          index: 12
        },
        {
          label : 'InSine',
          index: 13
        },
        {
          label : 'OutSine',
          index: 14
        },
        {
          label : 'InOutSine',
          index: 15
        },
        {
          label : 'InExpo',
          index: 16
        },
        {
          label : 'OutExpo',
          index: 17
        },
        {
          label : 'InOutExpo',
          index: 18
        },
        {
          label : 'InCirc',
          index: 19 
        },
        {
          label : 'OutCirc',
          index: 20
        },
        {
          label : 'InOutCirc',
          index: 21
        },
        { 
          label : 'InBounce',
          index: 22
        },
        {
          label : 'OutBounce',
          index: 23
        },
        {
          label : 'InOutBounce',
          index: 24
        }
      ]
    }
  },
  
  style: { 

    fill: true,

    stroke: {
 
      width: {
        step: 0.1,
        min: 1,
        max: 20,
        current: 1
      },

      color: '#222F3D',

      opacity: {
        step: 0.001,
        min: 0,
        max: 1,
        current: 1
      },

      cap: {
        current: 2,
        default: 2,
        options: [
          {
            label : 'default',
            index: 0 
          },
          {
            label : 'butt',
            index: 1 
          },
          {
            label : 'square',
            index: 2
          },
          {
            label : 'round',
            index: 3 
          }
        ]
      },

      join: {
        current: 0,
        default: 0,
        options: [
          {
            label : 'default',
            index: 0 
          },
          {
            label : 'miter',
            index: 1
          },
          {
            label : 'arcs',
            index: 2 
          },
          {
            label : 'bevel',
            index: 3 
          },
          {
            label : 'miter-clip',
            index: 4 
          },
          {
            label : 'round',
            index: 5 
          }
        ]
      },

      dash: {
        current: 0,
        default: 0,
        options: [
          {
            label : 'default',
            index: 0 
          },
          {
            label : '1, 1',
            index: 1
          },
          {
            label : '2, 2',
            index: 2 
          },
          {
            label : '5, 5',
            index: 3 
          }
        ]
      },
    } 
  }
}

state.isDesktop = !state.isMobile;

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}