let makeTemplate = function(data){

  let template = ` 
    <!DOCTYPE html>
      <!--[if lt IE 7]>      <html class="no-js lt-ie9 lt-ie8 lt-ie7"> <![endif]-->
      <!--[if IE 7]>         <html class="no-js lt-ie9 lt-ie8"> <![endif]-->
      <!--[if IE 8]>         <html class="no-js lt-ie9"> <![endif]-->
      <!--[if gt IE 8]><!--> <html class="no-js"> <!--<![endif]-->
      <head>
        <meta charset="utf-8">
        <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1">
        <title>Lazy Line Painter</title>
        <meta name="description" content="A modern JS library for SVG path animation">

        <!-- css  -->
        <style>

          body, html {
            position: absolute;
            width: 100%;
            height: 100%;
            top:0;
            left:0;
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: center;
          }

            #${data.id} {
              width: 70vw;
              height: 70vh;
              position: relative;
              overflow: visible;
            }
        </style>
     
        <!-- Include lazylinepainter -->
        <script src="lazy-line-painter-${data.version}.min.js"></script>

        <script type="text/javascript">

          (function(){ 

            document.onreadystatechange = () => {

              if (document.readyState === 'complete') {
                        
                /**
                 * Setup your Lazy Line element.
                 * see README file for more settings
                 */

                let el = document.querySelector('#${data.id}');
                let myAnimation = new LazyLinePainter(el, ${data.config}); 
                myAnimation.paint(); 
              }
            }

          })();

        </script>
      </head>

      <body>
        ${data.svghtml}
      </body>
    </html>`;

  return template;
}
export default makeTemplate
