<template>

  <section id="footer">

    <p class="intro">
        Product of <a href="http://merriment.info/" >Merriment</a> 2018 &copy;
    </p>
  
  </section> 

</template>

<script>

import Raf from '@/helpers/Raf'
import { mapState } from 'vuex';
import ResizeService from '@/services/ResizeService';  


export default {

    name: 'footer-bar',

    data () {
      return { }
    },

    computed: {},

    components: {},

    mounted () { 
    },

    methods:{

      setup() { 
      },

      enter(){ 
      },
      
      leave(){},

      onClick() {}
    
    },

    watch: {} 
} 
</script>


<style scoped lang="sass">

  #footer
    padding: 2rem; 
    background: #b5287b;
    font-size: 2rem;
    width: 100%;
    color: #601b43;
    text-transform: initial;
    letter-spacing: 0.1rem;

    a
      color: #f7f3ed;


</style> 