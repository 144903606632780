<template>

  <section id="getting-started">

    <div class="title-bar">
      <div class="title">
        Quick start
      </div>
      <div class="btns">
        <div class="close-btn btn"></div>
        <div class="min-btn btn"></div>
        <div class="max-btn btn"></div>
      </div>
    </div>
 
    <div class="terminal copy">
      <p ref="typewriter1" class="line line-1">
        npm install lazy-line-painter
      </p>
    </div>
    <div class="sublime copy">
      <div class="sublime-inner">
        <p ref="typewriter2" class="line line-2">
          <span class="purple">import</span> LazyLinePainter <span class="purple">from</span> <span class="green"> 'lazy-line-painter'</span>
        </p>
        <p ref="typewriter3" class="line line-3">
          <span class="purple">let</span> el = document.querySelector(<span class="green">'#my-svg'</span>)
        </p>
        <p ref="typewriter4" class="line line-4">
          <span class="purple">let</span> config = { strokeWidth : <span class="red">10</span> }
        </p>
        <p ref="typewriter5" class="line line-5">
          <span class="purple">let</span> myAnimation = <span class="blue">new</span> LazyLinePainter(el, config)
        </p>
        <p ref="typewriter6" class="line line-6">
          myAnimation.<span class="blue">paint()</span>
            <span class="grey">
              <a class="github-link" href="https://github.com/camoconnell/lazy-line-painter"> // For Documentation please visit Github </a> 🎉
            </span>
        </p>
      </div>
    </div>
  </section> 

</template>

<script>

import Raf from '@/helpers/Raf'
import { mapState } from 'vuex';
import ResizeService from '@/services/ResizeService';  



export default {

    name: 'getting-started',

    data () {
      return {
        shown: false
      }
    },

    computed: {
      ...mapState('browser', [
        'isMobile'
      ])
    },

    components: {},

    mounted () { 
      this.$el.style.transform = 'translate3d(0, 0%, 0)';
      if(this.isMobile){
        setTimeout(this.show, 200);
        this.shown = true;
      } else {
        window.addEventListener('scroll', this. onScroll, this);
      } 
    },

    methods:{
 
      onScroll() {
        if(document.body.scrollTop > window.innerHeight * 0.15 || document.documentElement.scrollTop > window.innerHeight * 0.15){
          if(!this.shown){
            setTimeout(this.show, 200);
            this.$el.style.transform = 'translate3d(0, 0%, 0)';
            this.shown = true;
          }
        }
      },

      show(){
         
        setTimeout(() => {
          this.$refs.typewriter1.classList.add('anim-typewriter-1'); 
        }, 200)

        setTimeout(() => {
          this.$refs.typewriter1.classList.add('complete');
          this.$refs.typewriter1.classList.remove('anim-typewriter-1');
          this.$refs.typewriter2.classList.add('anim-typewriter-2');
        }, 2000)

        setTimeout(() => {
          
          this.$refs.typewriter2.classList.add('complete');
          this.$refs.typewriter2.classList.remove('anim-typewriter-2');
          this.$refs.typewriter3.classList.add('anim-typewriter-3');

        }, 5000)
 
        setTimeout(() => {

          this.$refs.typewriter3.classList.add('complete');
          this.$refs.typewriter3.classList.remove('anim-typewriter-3');
          this.$refs.typewriter4.classList.add('anim-typewriter-4');

        }, 7000)
    
        setTimeout(() => {

          this.$refs.typewriter4.classList.add('complete');
          this.$refs.typewriter4.classList.remove('anim-typewriter-4');
          this.$refs.typewriter5.classList.add('anim-typewriter-5');

        }, 11000)
 
        setTimeout(() => {
          this.$refs.typewriter5.classList.add('complete');
          this.$refs.typewriter5.classList.remove('anim-typewriter-5');
          this.$refs.typewriter6.classList.add('anim-typewriter-6');
        }, 14000)

        setTimeout(() => {
          this.$refs.typewriter6.classList.add('complete');
          this.$refs.typewriter6.classList.remove('anim-typewriter-6');
        }, 17000)
        

      },

      onClick() {

      }
    
    },

    watch: {

    } 
} 
</script>


<style scoped lang="sass">

  #getting-started 
    margin: 0 auto;
    padding: 0 0 10rem;
    background: #2D323A;
    height: 50rem;
    max-width: 100rem;
    overflow: hidden;
    margin-bottom: -40rem;
    z-index: 10;
    border-radius: 10px;
    transition: transform 0.8s ease-out;
    transform: translate3d(0, 400px, 0);

    @media only screen and (max-width : 600px)
      height: 40rem;

    .github-link
      color: #727579;
      transition: color 0.5s ease-out;

      &:hover
        color: #c75d5d;

      @media only screen and (max-width : 600px)
        display: none;

    @media only screen and (max-width : 1000px)
      border-radius: 0;

    .title-bar
      width: 100%;
      height: 4rem;
      background: #545B66;
      position: relative;

      .title
        width: 100%;
        height: 100%;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2rem;
        color: #2D323A;
        letter-spacing: 0.1rem;


      .btns
        position: absolute;
        left: 1.5rem;
        display: flex;
        align-items: center;
        height: 100%;

        .btn
          margin-left: 1rem;
          width: 1.5rem;
          height: 1.5rem;
          border-radius: 50%;

        .close-btn
          background: #de6565;

        .min-btn
          background: #efe253;

        .max-btn
          background: #53ef66;


    .terminal
      height: 30%; 
      display: flex;
      align-items: center;
      justify-content: center; 
      color: #66FFB9;
      flex-direction: column;

      @media only screen and (max-width : 600px)
        align-items: end;

        p
          margin: 0 !important;

    .sublime
      display: flex;
      align-items: center;
      justify-content: center;
      background: #373c44;
      border-radius: 0;
      height: 90%;
      color: #f7f3ed;

      .sublime-inner
        width: 52rem;
        display: flex;
        justify-content: center;
        flex-direction: column;

        @media only screen and (max-width : 600px) 
          width: 100%;


    .purple
      color: rgb(210, 146, 255);

    .green
      color: #66FFB9;

    .blue
      color: rgb(129, 197, 252);

    .red
      color: rgb(199, 93, 93);

    .grey
      color: #727579;

    .copy
      user-select: text;
      font-family: 'melbournereg';
      text-transform: capitalize;
      line-height: 2;
      font-size: 2.2rem;
      text-transform: initial;
      letter-spacing: 0.1rem;
      border-radius: 5px;
      width: 100%;
      display: flex;

      @media only screen and (max-width : 600px)
        padding: 3rem;
        font-size: 2.2rem;


      .line
        position: relative;
        min-width: auto;
        border-right: 1.4rem solid transparent;
        line-height: 1.4;
        text-align: left;
        white-space: nowrap;
        overflow: hidden; 
        display: inline;
        width: 0;

      .line-1
        margin: 1rem auto;
        color: rgba(102,255,185,.75);

        &.complete
          width: 29rem;


      .line-2
        margin: 0;

        &.complete
          width: 44.5rem;

      .line-3
        margin: 2rem 0 0;

        &.complete
          width: 43.5rem;

      .line-4
        margin: 0.25rem 0;

        &.complete
          width: 32rem;

      .line-5
        margin: 0.25rem 0;

        &.complete
          width: 50rem;

      .line-6
        margin: 0.25rem 0;

        &.complete
          width: 60rem;


      /* Animation */
      .anim-typewriter-1
        animation: typewriter1 2s steps(33) 0.1s 1 normal both, blinkTextCursor 500ms steps(44) infinite normal;

      .anim-typewriter-2
        animation: typewriter2 2s steps(44) 0.1s 1 normal both, blinkTextCursor 500ms steps(44) infinite normal;

      .anim-typewriter-3
        animation: typewriter3 2s steps(44) 0.1s 1 normal both, blinkTextCursor 500ms steps(44) infinite normal;

      .anim-typewriter-4
        animation: typewriter4 2s steps(44) 0.1s 1 normal both, blinkTextCursor 500ms steps(44) infinite normal;

      .anim-typewriter-5
        animation: typewriter5 2s steps(44) 0.1s 1 normal both, blinkTextCursor 500ms steps(44) infinite normal;

      .anim-typewriter-6
        animation: typewriter6 2s steps(44) 0.1s 1 normal both, blinkTextCursor 500ms steps(44) infinite normal;


      @keyframes typewriter1
        from{width: 0;}
        to{width: 29rem;}

      @keyframes typewriter2
        from{width: 0;}
        to{width: 44.5rem;}

      @keyframes typewriter3
        from{width: 0;}
        to{width: 43.5rem;}

      @keyframes typewriter4
        from{width: 0;}
        to{width: 32rem;}

      @keyframes typewriter5
        from{width: 0;}
        to{width: 50rem;}

      @keyframes typewriter6
        from{width: 0;}
        to{width: 60rem ;}

      @keyframes blinkTextCursor
        from{border-right-color: transparent;}
        to{border-right-color: currentColor;}





</style>
