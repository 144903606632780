// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import router from './router'
import store from './store'
import '@/services/AnalyticsService'

Vue.config.productionTip = false
 


// ********************************************************
//
// DOCUMENT
//
// ********************************************************
const loadDocument = function() {
    return new Promise(resolve => {

        const onReady = function() {
            resolve()
            console.log('MAIN')
        } 

        if (document.readyState === 'complete') {
            onReady()
        } else {
            document.onreadystatechange = () => {
                if (document.readyState === 'complete') {
                    onReady()
                }
            }
        }
    })
}

// ********************************************************
//
// APP
//
// ********************************************************
function loadApp() {
    return new Promise((resolve) => {
        function init(module) {

            let App = module.default
            new Vue({
                el: '#app',
                router,
                components: {
                    App
                },
                store,
                template: '<App/>'
            })

            resolve()
        }

        // determine if low spec
        const browser = store.state.browser
        const forceLowSpec = false;
        const isLowSpec = !browser.hasWebgl || !browser.hasWebAudio || forceLowSpec
        store.commit('browser/setLowSpec', isLowSpec)
        router.replace('/');
 
        import ('./App').then(init)
    })
}



// ********************************************************
//
// INIT
//
// ********************************************************
;
(async function() {
    await loadDocument()
    await loadApp()
})()