import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

// ROUTES
import Loading from '@/views/Loading' 
import Home from '@/views/Home'

// BASE
Vue.use(Router)

// INIT
const router = new Router({
    // MODES:
    // 'hash' = hash tag routing
    // 'history' = history API routing
    // 'abstract' = internal routing, no URL changes
    mode: 'abstract',
    routes: [{
        path: '/',
        name: 'home',
        component: Home
    },
   //  , {
   //      path: '/home',
   //      name: 'home',
   //      component: Home
   // }, 
   //{
    //     path: '/create',
    //     name: 'create',
    //     component: Create
    // }, {
    //     path: '/about',
    //     name: 'about',
    //     component: About
    // }, {
    //     path: '/upgrade',
    //     name: 'upgrade',
    //     component: Upgrade
    // }, 
    {
        path: '*',
        redirect: '/'
    }]
})
export default router

// ROUTE GUARD
// router.beforeEach((to, from, next) => {
//     if (to.name !== 'loading' && !store.state.app.loading.completed) {
//         // save initial route for later if set
//         store.commit('app/setInitialRoute', to.name)
//         return next({
//             name: 'loading'
//         })
//     }
//     next()
// })