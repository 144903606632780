<template>

  <div id="ui-slider"
    v-on:mouseenter="onMouseEnter"
    v-on:mouseleave="onMouseLeave"
    >

    <p class="label">
      <span class="ui-slider-title">
        <slot></slot>
      </span>
      <span ref="value" class="ui-slider-value">
        {{value}}
      </span>
    </p>

    <div class="slider">
        <input 
          class="input-range"
          ref="input" 
          v-on:mousedown="onMouseDown"
          v-on:mouseup="onMouseUp"
          type="range"  
          :min="min"
          :max="max"
          :step="step"
          />
    </div>
  </div>
</template>

<script>
 
import { mapState } from 'vuex'; 

export default {

    name: 'ui-slider',

    props: {
      value: Number,
      min: Number,
      max: Number,
      step: Number
    },

    data () {
      return {}
    },

    computed: {},

    components: {},

    mounted () {
      this.$refs.input.value = this.value;
    },

    methods: {

      onMouseDown() {
        Raf.add(this.onTick);
      },

      onMouseUp() {
        Raf.remove(this.onTick);
      },

      onMouseEnter() {
        this.$refs.value.style.opacity = 1;
        this.$refs.value.style.transform = 'translate3d(0, 0, 0)';
      },

      onMouseLeave() {
        this.$refs.value.style.opacity = 0;
        this.$refs.value.style.transform = 'translate3d(0, 3px, 0)';
      },

      onTick() {
        let value = this.$refs.input.value;
        this.$emit('change', Number(value)); 
      }
      
    },

    watch: {} 
} 
</script>


<style lang="sass" scoped>

  #ui-slider

    .label
      display: flex
      width: 100%
      justify-content: space-between
      
      .ui-slider-title
        display: block
        position: relative

        .small
          font-size: 1.2rem


      .ui-slider-value
        padding: 0 1rem
        display: block
        position: relative
        font-size: 1.4rem
        background: #b5287b
        color: #f6f3ee
        border-radius: 5px
        height: 3rem
        display: flex
        min-width: 13%
        align-items: center
        justify-content: center
        opacity: 0
        transform: translate3d(0, 3px, 0)
        transition: opacity 0.3s ease-out, transform 0.3s ease-out

    .slider
      height: 4rem
      display: flex
      align-items: center


    /* slider */
    .input-range
        appearance: none
        width: 100%
        height: 3px
        border-radius: 4px
        background: rgb(181, 40, 123)
        outline: none
        margin: 0

    .input-range::-webkit-slider-thumb
        -webkit-appearance: none
        width: 2.4rem
        height: 2.4rem
        border-radius: 50%
        background: #f7f2ec
        border: 3px solid #cc2e8b
        cursor: pointer

    .input-range::-moz-range-thumb
        width: 2.2rem
        height: 2.2rem
        border: 0
        border-radius: 50%
        background: #353535
        cursor: pointer

    .input-range:active::-moz-range-thumb
        background: #e06161

    ::-moz-range-track
        background: #ccc
        border: 0

    input::-moz-focus-inner
        border: 0

</style>