<template>
  <svg viewBox="0 0 349 277" xmlns="http://www.w3.org/2000/svg" id="face" class="lazy-line-painter" data-llp-composed="true">

    <path d="M7.603,5.5 c0,0,10.298,24.07,33.517,24.07c23.221,0,33.519-24.07,33.519-24.07s9.142,24.07,33.513,24.07c24.372,0,33.515-24.07,33.515-24.07 s10.858,24.07,33.518,24.07S208.703,5.5,208.703,5.5s9.717,24.07,33.521,24.07c23.801,0,33.518-24.07,33.518-24.07 s13.828,24.07,33.524,24.07c19.698,0,33.524-24.07,33.524-24.07" stroke="#f7f3ed" 
      fill="none" 
      stroke-width="2" 
      stroke-linecap="round" 
      stroke-linejoin="round" 
      data-llp-id="face-0" 
      data-llp-duration="2000" 
      data-llp-delay="0"
      data-llp-ease="easeInOutQuad"
      ></path>

    <path d="M120,139.129 c0,0-8.708-34.233-42.483-34.236c-28.724-0.001-42.493,34.23-42.493,34.23" 
      fill="none"
      stroke-width="2" 
      stroke-linecap="round" 
      stroke-linejoin="round" 
      data-llp-id="face-1" 
      data-llp-duration="1000" 
      data-llp-delay="1000"
      data-llp-ease="easeInOutQuad"
      ></path>

    <path d="M313.5,137.138 c0,0-12.209-32.137-39.746-32.138C246.216,104.999,234,137.132,234,137.132" 
      fill="none" 
      stroke-opacity="1" 
      stroke-width="2" 
      stroke-linecap="round" 
      stroke-linejoin="round"
      data-llp-id="face-2" 
      data-llp-duration="1000" 
      data-llp-delay="1500"
      data-llp-ease="easeInOutQuad"
      ></path>

    <path d="M80.246,193.604 c0,0,21.399,74.387,93.074,74.387c76.026,0,93.095-74.387,93.095-74.387"
      fill="none"
      stroke-width="2" 
      stroke-linecap="round"
      stroke-linejoin="round"
      data-llp-id="face-3"
      data-llp-duration="1500"
      data-llp-delay="2500"
      ></path>

  </svg>
</template>

<script> 

import { mapState } from 'vuex';
import ResizeService from '@/services/ResizeService'; 
import LazyLinePainter from 'lazy-line-painter';

export default {

    name: 'svg-logo',

    data () {
      return {
        lazyline: null
      }
    },

    computed: {},

    components: {},

    mounted () {
      this.setup();
    },

    beforeDestroy () {
      this.lazyline.off('complete', this.onComplete); 
      this.lazyline = null;
    },

    methods:{

      setup() {

        this.lazyline = new LazyLinePainter(this.$el, {  
          strokeWidth: 7, 
          strokeCap :'round',
          strokeColor: '#f7f3ed',
          ease: "easeOutBounce" 
        });

        this.lazyline.on('complete', this.onComplete); 
        this.lazyline.paint();
      },

      onComplete() { 
        this.$el.classList.add('active')
        this.lazyline.off('complete', this.onComplete);
      }
    
    },


    watch: {} 
} 
</script>


<style  lang="sass">
  #face
    padding: 4rem 0;
    transform: translateY(0)
    transition: transform 1s ease-in-out;
    &.active
      transform: translateY(-20px)

 
</style>


