<template>

  <section id="docs">
   <div class="col">
                <h1 class='mask'>Lazy line API Reference</h1>
                <p class="api-desc"> 
                    For further documentation and examples, <br> 
                    <a href="https://github.com/camoconnell/lazy-line-painter">visit github.</a>
                </p>
                <div class="api-calls">
                    <div class="api-call">
                        <p>
                            <b><span class="title">Paint</span></b> <br> 
                            <span class="description">Start animation, <br> draws line through path</span> 
                            <br>
                            <span class="code">paint()</span>
                        </p>
                    </div>

                    <div class="api-call">
                        <p>
                            <b><span class="title">Erase</span></b>
                            <br> <span class="description">Clear path - paint can still be <br> called on the element after it has been erased</span>
                            <br>
                            <span class="code">erase()</span>
                        </p>
                    </div>

                    <div class="api-call">
                        <p>
                            <b><span class="title">Pause</span></b>
                            <br> <span class="description">Pause path animation</span> 
                            <br>
                            <span class="code">pause()</span>
                        </p>
                    </div>

                    <div class="api-call">
                        <p>
                            <b><span class="title">Resume</span></b>
                            <br> <span class="description">Resume path animation</span>
                            <br>
                            <span class="code">resume()</span>
                        </p>
                    </div>
       
                    <div class="api-call">
                        <p>
                            <b><span class="title">Set</span></b>
                            <br> <span class="description">Sets path position, <br> second param accepts a number between 0 - 1;</span>
                            <br>
                            <span class="code">set(num)</span>
                        </p>
                    </div>
                     
                    <div class="api-call">
                        <p>
                            <b><span class="title">Get</span></b>
                            <br> <span class="description">Returns all lazylinepainter data;</span>
                            <br>
                            <span class="code">get()</span>
                        </p>
                    </div>

                    <div class="api-call destroy">
                        <p>
                            <b><span class="title">Destroy</span></b>
                            <br> <span class="description"> Remove path - removes lazyline data and emptys element from DOM. </span>
                            <br>
                            <span class="code">destroy()</span>
                        </p>
                    </div>
                </div>
  
            </div>
  </section> 

</template>

<script>

import Raf from '@/helpers/Raf'
import { mapState } from 'vuex';
import ResizeService from '@/services/ResizeService';  


export default {

    name: 'docs',

    data () {
      return { }
    },

    computed: {},

    components: {},

    mounted () { 
    },

    methods:{

      setup() { 
      },

      enter(){ 
      },
      
      leave(){},

      onClick() {}
    
    },

    watch: {} 
} 
</script>


<style scoped lang="sass">

  #docs
    padding: 2rem;
    font-size: 2rem;
    width: 100%;
    color: #601b43;
    text-transform: initial;
    letter-spacing: 0.1rem;  
    background: #f7f3ed;

  .api-desc
    color: #cc2d8a;
    margin-bottom: 70px;

  .col
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    position: relative;

  h1
    color: #cc2d8a;
    padding-top: 80px;
    width: 600px;
    margin: 0 auto;
  
  p
    font-family: 'melbournereg';
    text-transform: none;
    font-size: 20px;
    letter-spacing: 1px;
    margin-top: 0px;

  .title
    color: #cc2d8a;
    font-weight: 900;
    font-size: 1.6rem;

  .description
    font-size: 16px;

  .code
    background: #cc2d8a;
    padding: 10px 20px;
    margin: 13px 0 0;
    position: relative;
    display: inline-block;
    border-radius: 5px;
    font-size: 16px;
    color: white;

  .api-calls
    display: flex;
    position: relative;
    flex-direction: column; 
    flex-flow: wrap;
    justify-content: space-between;

  .api-call
    width: 48%;
    margin-bottom: 30px;

    .destroy
      width: 300px;
      margin: 0 auto;
 


</style> 