import { render, staticRenderFns } from "./feedback-btn.vue?vue&type=template&id=298b2cd0&scoped=true&"
import script from "./feedback-btn.vue?vue&type=script&lang=js&"
export * from "./feedback-btn.vue?vue&type=script&lang=js&"
import style0 from "./feedback-btn.vue?vue&type=style&index=0&id=298b2cd0&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "298b2cd0",
  null
  
)

component.options.__file = "feedback-btn.vue"
export default component.exports